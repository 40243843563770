export function FirstCaret({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      viewBox='0 0 32 32'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <g stroke={stroke} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none'>
        <path d='M15.7325 23.222L8 15.4895 15.4895 8' />
        <path d='M22.7325 23.222L15 15.4895 22.4895 8' />
      </g>
    </svg>
  )
}

FirstCaret.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
