import styled from 'styled-components'

const StyledSVG = styled.svg<{ fillColor: string; strokeColor: string }>`
  fill: ${({ fillColor }) => fillColor};
  stroke: ${({ strokeColor }) => strokeColor};
`

export function CaretRight({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <StyledSVG
      viewBox='0 0 32 32'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fillColor={fill}
      strokeColor={stroke}
      strokeWidth='2'
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 8l7.7325 7.7325-7.4894 7.4895'
        fill='none'
      />
    </StyledSVG>
  )
}

CaretRight.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
