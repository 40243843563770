import { light as uiLight } from '@tiltify/ui/themes'

const colors = {
  darkBlue: '#162550',
  headingBlue: '#00358E',
  lightBlue: '#009ED0',
  yellow: '#FFCD00',
  red: '#D83C2D',
  green: '#84BD00',
  grey: '#BEC3C6',
}

const breakpoints = ['40em', '52em', '64em']

const theme = {
  ...uiLight,
  ...colors,
  breakpoints: breakpoints,
  whiteText: '#f5f6f6',
  modalOverlay: 'rgba(255, 255, 255, 0.97)',
}

export default theme
