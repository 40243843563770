export function FacebookSimple({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'
  return (
    <svg
      viewBox='14 10 9 16'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d='M19.3932538,18.7021832 L21.8418451,18.7021832 L22.2092059,15.8572506 L19.3932538,15.8572506 L19.3932538,14.041176 C19.3932538,13.2177596 19.6209722,12.6566112 20.8030863,12.6566112 L22.3083171,12.6559924 L22.3083171,10.111384 C22.0480086,10.0775563 21.1544615,10 20.1144651,10 C17.9427868,10 16.4560169,11.3255726 16.4560169,13.7594157 L16.4560169,15.8572506 L14,15.8572506 L14,18.7021832 L16.4560169,18.7021832 L16.4560169,26 L19.3932538,26 L19.3932538,18.7021832 Z'
        stroke='none'
      />
    </svg>
  )
}

FacebookSimple.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#3B5998',
}
