import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

import { FullPageModal, Grid } from '@tiltify/ui'
import { Card } from './Card'
import celebration from 'assets/CampaignTypes/celebration.png'
import community from 'assets/CampaignTypes/community.png'
import creative from 'assets/CampaignTypes/creative.png'
import emergency from 'assets/CampaignTypes/emergency.png'
import memorial from 'assets/CampaignTypes/memorial.png'
import run from 'assets/CampaignTypes/run.png'

const modalStyles = css`
  font-family: Freight Sans pro;

  && #modal-content > div {
    width: auto;
  }

  && #modal-content > div > span {
    padding-top: 3rem;
    font-size: ${rem(40)};
    color: ${({ theme }) => theme.darkBlue};

    @media (max-width: 600px) {
      font-size: ${rem(34)};
    }

    @media (max-width: 460px) {
      font-size: ${rem(24)};
    }
  }
`

const StyledGrid = styled(Grid)`
  column-gap: 2.875rem;

  @media (max-width: calc(${({ theme }) => theme.contentMaxWidth} - 32px)) {
    grid-template-columns: repeat(2, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: repeat(auto-fit, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }
`

const AccentBar = styled.div`
  display: flex;
  margin: 0 auto;
  background: ${({ theme }) => theme.lightGrey};
  height: 6px;
  max-width: 50px;
  margin-bottom: ${rem(50)};

  @media (max-width: 554px) {
    width: 16%;
  }
`

export const CampaignTypes = ({
  setIsVisible,
}: {
  setIsVisible: (value: boolean) => void
}): JSX.Element => {
  return (
    <FullPageModal
      setIsVisible={setIsVisible}
      modalStyles={modalStyles}
      title='Choose your campaign type'
      skipOutsideClick
    >
      <AccentBar />
      <StyledGrid itemWidth='265px'>
        <Card
          icon={celebration}
          title='Celebration'
          description='Instead of gifts this year, start a birthday, graduation, wedding or other special occasion fundraiser and ask your friends and family to support you.'
          startLink='?theme=myHumane&campaignType=celebration'
        />
        <Card
          icon={memorial}
          title='Memorial'
          description='The loss of a beloved person or pet can be a difficult and painful experience. What better way to honor a loved one than by helping create a more compassionate future for those who need our help?'
          startLink='?theme=myHumane&campaignType=memorial'
        />
        <Card
          icon={community}
          title='School, Community or Workplace'
          description="Team up with your classmates, community or colleagues and start a fundraising campaign! However you choose to raise money for animals, you'll make a huge difference together!"
          startLink='?theme=myHumane&campaignType=community'
        />
        <Card
          icon={run}
          title='Run, Walk, or Ride'
          description="Whether you take part in a marathon, 5K or bike race, your athletic fundraising campaign will help the HSUS continue to help animals. So you'll be achieving a victory not only for yourself, but for animals everywhere!"
          startLink='?theme=myHumane&campaignType=athletic'
        />
        <Card
          icon={creative}
          title='Creative'
          description="Organize a dog walk or make your cat an internet star. Plan a scavenger hunt or a bingo night. The sky is the limit, and your fundraiser for any and all animals can raise money where it's needed most!"
          startLink='?theme=myHumane&campaignType=creative'
        />
        <Card
          icon={emergency}
          title='Emergency Response'
          description='We never know where disasters will strike or when animals may be in need of urgent rescue, but we know we must be ready. Your support makes this lifesaving work possible.'
          startLink='?theme=myHumane&campaignType=emergency'
        />
      </StyledGrid>
    </FullPageModal>
  )
}
