import { css } from 'styled-components'

export const styledScrollbar = css`
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #e3e3e5;

    &:hover {
      box-shadow: inset 8px 0 1px -8px rgba(0, 0, 0, 0.4);
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`
