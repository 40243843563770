import { useEffect } from 'react'

export const useOnOutsideClick = (ref: any, handler: any, skip?: any) => {
  if (!ref) {
    throw new Error('useOnOutsideClick requires a React ref object to be passed as first parameter')
  }

  if (!handler || typeof handler !== 'function') {
    throw new Error('useOnOutsideClick requires a function as second parameter')
  }
  useEffect(() => {
    if (skip) return
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  })
}
