import { Component } from 'react'

import ReactDOM from 'react-dom'
import { v4 as uuidv4 } from 'uuid'

/**
 * A wrapper around react-portal to allow rendering of Portals while the child
 * component is mounted. This handles the opening of closing of the portal
 * directly with the React lifecycle. Portals created using this wrapper are
 * always considered opened.
 */

export class Portal extends Component<any> {
  constructor(props: any) {
    super(props)
    this.portalRoot = props.default ? document.getElementById('modal-root') : null
    if (!this.portalRoot) {
      const d = document.createElement('div')
      const id = `${uuidv4()}`
      d.setAttribute('id', id)
      document.body.appendChild(d)
      this.portalRoot = document.getElementById(id)
    }
  }

  static defaultProps = {
    default: true,
    scrollLock: false,
  }

  portalRoot: any

  componentDidMount() {
    if (this.props.scrollLock) document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset'
    if (this.portalRoot.id !== 'modal-root') {
      document.body.removeChild(this.portalRoot)
      this.portalRoot = null
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.portalRoot)
  }
}
