import { useState } from 'react'
import styled from 'styled-components/macro'

import { Hero } from 'components/Hero'
import { Impact } from 'components/Impact'
import { Incentives } from 'components/Incentives'
import { Leaderboard } from 'components/Leaderboard'
import { TrendingCampaigns } from 'components/TrendingCampaigns/'
import { VideoBox } from 'components/VideoBox'
import { Social } from 'components/Social'
import { PoweredBy } from 'components/PoweredBy'
import { CampaignTypes } from 'components/CampaignTypes'
import { Helmet } from 'components/Helmet'
import { FullWindowLoading, GATrackers } from '@tiltify/ui'
import { useRouteContext } from 'contexts/RouteContext'

import FreightSansProBook from 'assets/fonts/FreightSansPro-Book.otf'
import FreightSansProMedium from 'assets/fonts/FreightSansPro-Medium.otf'
import FreightSansProSemibold from 'assets/fonts/FreightSansPro-Semibold.otf'
import FreightSansProBold from 'assets/fonts/FreightSansPro-Bold.otf'
import { ToolKit } from 'components/ToolKit/index'
import { SuccesStories } from 'components/SuccessStories/index'

const Wrapper = styled.div`
  @font-face {
    font-family: 'Freight Sans pro';
    font-weight: 300;
    src: url('${FreightSansProMedium}') format('opentype');
  }

  @font-face {
    font-family: 'Freight Sans pro';
    font-weight: 400;
    src: url('${FreightSansProBook}') format('opentype');
  }

  @font-face {
    font-family: 'Freight Sans pro';
    font-weight: 500;
    src: url('${FreightSansProSemibold}') format('opentype');
  }

  @font-face {
    font-family: 'Freight Sans pro';
    font-weight: 800;
    src: url('${FreightSansProBold}') format('opentype');
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Freight Sans pro', 'Open Sans', Helvetica, sans-serif;
  font-family: Freight Sans pro;
`

const App = (): JSX.Element => {
  const { cause } = useRouteContext()
  const [isVisible, setIsVisible] = useState(false)

  if (!cause) return <FullWindowLoading />

  return (
    <Wrapper>
      <GATrackers
        cause={cause}
        tracker={{
          trackingId: cause?.trackers[0],
          gaOptions: { name: 'cause', userId: cause?.id },
        }}
      />
      <Helmet
        image={cause.avatar.src}
        description="The Humane Society of the United States is the nation's most effective animal protection organization. Join the HSUS in fighting for all animals!"
        title='The Humane Society of the United States'
      />
      <Hero setIsVisible={setIsVisible} />
      <Impact />
      <SuccesStories />
      <ToolKit />
      <TrendingCampaigns />
      <Leaderboard />
      <Incentives setIsVisible={setIsVisible} />
      <VideoBox />
      <Social setIsVisible={setIsVisible} />
      <PoweredBy />
      {isVisible && <CampaignTypes setIsVisible={setIsVisible} />}
    </Wrapper>
  )
}

export default App
