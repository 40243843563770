import styled from 'styled-components/macro'
import { rem } from 'polished'

import { getSubdomainUrl, baseTiltifyUrl } from '@tiltify/ui/utils'
import { useRouteContext } from 'contexts/RouteContext'
import { LightBlueButton, RedButton } from 'components/styles'
import Logo from 'assets/Logo.png'
import HeaderImage from 'assets/header-image.jpg'

const HeroContent = styled.div`
  max-width: 900px;
  margin: auto;
  margin-top: 24rem;
  margin-left: ${rem(120)};
  padding: 1rem;
  padding-bottom: 0;

  @media (max-width: 775px) {
    margin-left: 0.5rem;
  }

  @media (max-width: 320px) {
    margin: 0 auto;
  }
`

const StyledHeading = styled.h1`
  color: ${({ theme }) => theme.whiteText};
  text-shadow: 0px 2px 6px #293941;
  font-size: ${rem(80)};
  line-height: 74px;
  font-weight: bold;
  margin-bottom: ${rem(33)};
  margin-top: 0;

  @media (max-width: 1050px) {
    font-size: ${rem(55)};
  }

  @media (max-width: 700px) {
    font-size: ${rem(32)};
    line-height: 50px;
    margin-bottom: ${rem(24)};
  }

  @media (max-width: 552px) {
    margin-bottom: 1rem;
  }
`

const StyledSubHeading = styled.div`
  font-weight: normal;
  text-transform: uppercase;
  font-size: ${rem(20)};
  margin-top: 4rem;
  margin-bottom: 0;
  line-height: 26px;
  color: ${({ theme }) => theme.whiteText};
`

const ButtonContainer = styled.div`
  margin-top: ${rem(37)};
  margin-bottom: ${rem(80)};
  display: flex;

  @media (max-width: 552px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: ${rem(40)};
    margin-top: 0;
  }
`

const Background = styled.div`
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%),
    url(${HeaderImage});
  background-size: cover;
  background-position: center;
  height: 825px;

  @media (max-width: 320px) {
    height: auto;
  }

  @media (max-width: 800px) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 100%),
      url(${HeaderImage});
    background-size: cover;
    background-position: center;
  }
`

const LogoWrapper = styled.a`
  padding-top: 2rem;
  padding-left: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 306px;
  @media (max-width: 450px) {
    width: 240px;
  }
`

const Image = styled.img`
  width: 306px;
  @media (max-width: 450px) {
    width: 240px;
  }
`

export const Hero = ({ setIsVisible }: { setIsVisible: (value: boolean) => void }): JSX.Element => {
  const { cause } = useRouteContext()

  return (
    <Background>
      <LogoWrapper href='https://www.humanesociety.org/' target='_blank' rel='noopener noreferrer'>
        <Image src={Logo} />
      </LogoWrapper>
      <HeroContent>
        <StyledSubHeading>Save Animals — Your way</StyledSubHeading>
        <StyledHeading>Fundraise for all animals</StyledHeading>

        <ButtonContainer>
          <LightBlueButton outline={false} onClick={() => setIsVisible(true)}>
            Start Fundraising
          </LightBlueButton>
          <RedButton
            as='a'
            forwardedAs='a'
            href={getSubdomainUrl(`${baseTiltifyUrl()}/${cause?.causeFactPublicId}`, 'donate')}
            target='_blank'
            rel='noopenner noreferrer'
          >
            Donate
          </RedButton>
        </ButtonContainer>
      </HeroContent>
    </Background>
  )
}
