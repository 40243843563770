import { gql } from '@apollo/client'

export const get_cause_by_slug = gql`
  query get_cause_by_slug($slug: String!) {
    cause(slug: $slug) {
      id
      causeFactPublicId
      name
      slug
      trackers
      avatar {
        alt
        height
        width
        src
      }
      paymentOptions {
        currency
        monthlyGiving
      }
      paymentMethods {
        type
        currency
      }
      social {
        facebook
        twitter
        youtube
        instagram
      }
      publishedCampaigns(limit: 4) {
        edges {
          cursor
          node {
            ... on Campaign {
              publicId
              name
              slug
              live
              user {
                id
                username
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
            ... on TeamEvent {
              publicId
              name
              slug
              currentSlug
              live
              team {
                id
                name
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
          }
        }
      }
    }
  }
`

export const get_campaigns_by_cause_slug = gql`
  query get_campaigns_by_cause_slug(
    $causeId: Int!
    $causeSlug: String!
    $limit: Int!
    $query: String
    $offset: Int
  ) {
    cause(id: $causeId, slug: $causeSlug) {
      publishedCampaigns(limit: $limit, offset: $offset, query: $query) {
        pagination {
          total
          limit
          offset
          hasNextPage
        }
        edges {
          node {
            ... on Campaign {
              publicId
              name
              slug
              live
              user {
                id
                username
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
            ... on TeamEvent {
              publicId
              name
              slug
              currentSlug
              live
              team {
                id
                name
                slug
                avatar {
                  src
                  alt
                }
              }
              avatar {
                src
                alt
              }
              totalAmountRaised {
                value
                currency
              }
              goal {
                value
                currency
              }
              cardImage {
                src
                alt
              }
            }
          }
        }
      }
    }
  }
`
