import styled from 'styled-components/macro'
import { rem } from 'polished'

import { MaxWidthWrapper, StyledHeading, AccentBar } from 'components/styles'
import { SingleImpact } from './SingleImpact'
import chimp from 'assets/efforts/chimp.jpg'
import art from 'assets/efforts/art.jpg'
import leo from 'assets/efforts/leo.jpg'
import mask from 'assets/efforts/mask.jpg'

const ImpactWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  margin-top: ${rem(42)};
  max-width: 1141px;

  @media (max-width: 1150px) {
    flex-direction: column;
    margin-top: ${rem(50)};
    margin-bottom: ${rem(25)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-bottom: 0;
    margin-top: 0;
  }
`

const HalfWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;

  @media (max-width: 1150px) {
    justify-content: center;
  }

  @media (max-width: 710px) {
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
`

const StyledAccentBar = styled(AccentBar)`
  margin-bottom: 0;
`

export const Impact = (): JSX.Element => {
  return (
    <>
      <StyledHeading>How your efforts help</StyledHeading>
      <StyledAccentBar />

      <ImpactWrapper>
        <HalfWrapper>
          <SingleImpact
            amount={5}
            description='could provide one protective face mask for an animal rescuer.'
            image={mask}
          />
          <SingleImpact
            amount={15}
            description='could provide vaccinations for one dog or cat rescued from a cruelty case.'
            image={art}
          />
          <SingleImpact
            amount={400}
            description='could feed 20 chimpanzees rescued from laboratories for a week.'
            image={chimp}
          />
          <SingleImpact
            amount={1000}
            description='could train up to 30 police officers to fight animal cruelty.'
            image={leo}
          />
        </HalfWrapper>
      </ImpactWrapper>
    </>
  )
}
