import styled from 'styled-components'

const StyledGrid = styled.div<{ itemWidth: number | string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @supports (display: grid) {
    display: grid;
    column-gap: 1.875rem;
    row-gap: 1.875rem;
    grid-template-columns: repeat(auto-fit, minmax(${({ itemWidth }) => itemWidth}, 1fr));

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
      grid-template-columns: repeat(auto-fit, 100%);
      column-gap: 1rem;
      row-gap: 1rem;
      justify-items: center;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      justify-items: center;
    }

    flex: 1;
    margin: auto;
  }
`

export const Grid = ({ children, itemWidth, ...rest }: any) => {
  return (
    <StyledGrid itemWidth={itemWidth} {...rest}>
      {children}
    </StyledGrid>
  )
}

Grid.defaultProps = {
  itemWidth: '100px',
}
