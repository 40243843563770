import { Routes as RouterRoutes, Route } from 'react-router-dom'

import App from './App'
import { FullLeaderboard } from 'components/Leaderboard/FullLeaderboard'
import { FullCampaignList } from 'components/TrendingCampaigns/FullCampaignList/index'

export const Routes = (): JSX.Element => {
  return (
    <RouterRoutes>
      <Route path='/leaderboard' element={<FullLeaderboard />} />
      <Route path='/campaigns' element={<FullCampaignList />} />
      <Route index element={<App />} />
    </RouterRoutes>
  )
}
