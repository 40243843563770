import { readableColor, rem } from 'polished'
import styled from 'styled-components'

import { Currency } from '../../Currency'
import { TiltifyLink } from '../../TiltifyLink'
import { SquareList, Triangle, RoundList, CenteredSquareList } from './TopThreeLayouts'

const Spacer = styled.div``

const StyledFlex = styled.div<{ boardStyle: string }>`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  padding: 0 1rem;

  ${({ boardStyle }) => boardStyle};
`

const StyledHeader = styled.h2<{ headingColor: string; subHeadingStyles: string }>`
  align-self: center;
  font-size: ${rem(24)};
  font-weight: bold;
  color: ${({ theme, headingColor }) => headingColor || readableColor(theme.backgroundColor)};
  ${({ subHeadingStyles }) => subHeadingStyles}
`

const Row = styled.div<{ rowStyle: string }>`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(10)} 0;

  ${({ rowStyle }) => rowStyle}
`
const Left = styled.div`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.4rem;
  white-space: nowrap;
`

const Number = styled.div<{ headingColor: string }>`
  float: left;
  width: 15px;
  padding-right: 1.5rem;
  font-size: ${rem(16)};
  font-weight: bold;
  color: ${({ theme, headingColor }) => headingColor || readableColor(theme.backgroundColor)};
`

const Right = styled.div<{ textColor: string }>`
  text-align: right;
  font-size: ${rem(16)};
  color: ${({ theme, textColor }) => textColor || readableColor(theme.backgroundColor)};
`

const StyledLink = styled(({ ...rest }) => <TiltifyLink {...rest} />)`
  color: ${({ theme }) => theme.darkBlue};
  text-decoration: none;
  font-size: ${rem(16)};
  color: ${({ theme, nameTextColor }) => nameTextColor || readableColor(theme.backgroundColor)};
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ rowStyle }) => rowStyle}
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Board = ({
  currency,
  campaigns,
  rowStyle,
  boardStyle,
  header,
  numberOfBoards,
  maxAmountOfDonators,
  headingColor,
  subHeadingStyles,
  textColor,
  localLinks,
  nameTextColor,
  topThreeStyle,
  fontFamily,
}: any) => {
  const renderTopThree = () => {
    const content = []

    for (let x = 0; x < 3; x += 3) {
      content.push(campaigns.slice(x, x + 3))
    }

    if (!content) return null

    if (topThreeStyle === 'triangle') {
      return content.map((c, col) => {
        return (
          <Triangle
            key={col}
            c={c}
            textColor={textColor}
            localLinks={localLinks}
            currency={currency}
            nameTextColor={nameTextColor}
            fontFamily={fontFamily}
          />
        )
      })
    }
    if (topThreeStyle === 'squareList') {
      return content.map((c, col) => {
        return (
          <SquareList
            key={col}
            c={c}
            textColor={textColor}
            localLinks={localLinks}
            currency={currency}
            nameTextColor={nameTextColor}
            fontFamily={fontFamily}
          />
        )
      })
    }
    if (topThreeStyle === 'roundList') {
      return content.map((c, col) => {
        return (
          <RoundList
            key={col}
            c={c}
            textColor={textColor}
            localLinks={localLinks}
            currency={currency}
            nameTextColor={nameTextColor}
            fontFamily={fontFamily}
          />
        )
      })
    }
    if (topThreeStyle === 'centeredSquareList') {
      return content.map((c, col) => {
        return (
          <CenteredSquareList
            key={col}
            c={c}
            textColor={textColor}
            localLinks={localLinks}
            currency={currency}
            nameTextColor={nameTextColor}
            fontFamily={fontFamily}
          />
        )
      })
    }
  }

  const renderCampaigns = () => {
    if (!campaigns) return null
    return campaigns.slice(3, maxAmountOfDonators).map((campaign: any, col: any) => {
      const num = col + 4
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Row key={col} rowStyle={rowStyle} num={num} fontFamily={fontFamily}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Left width={[1 / 2]}>
            <Number headingColor={headingColor}>{num}.</Number>
            <StyledLink
              nameTextColor={nameTextColor}
              url={campaign.url}
              localLink={localLinks}
              rowStyle={rowStyle}
            >
              {campaign.name}
            </StyledLink>
          </Left>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Right textColor={textColor} width={[1 / 2]}>
            <Currency currency={currency || 'USD'} value={campaign.amountRaised} />
          </Right>
        </Row>
      )
    })
  }
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <StyledFlex boardStyle={boardStyle} flexWrap='wrap' numberOfBoards={numberOfBoards}>
      <StyledHeader subHeadingStyles={subHeadingStyles} headingColor={headingColor}>
        {header}
      </StyledHeader>
      <TopWrapper>{renderTopThree()}</TopWrapper>
      <Spacer>{renderCampaigns()}</Spacer>
    </StyledFlex>
  )
}

Board.defaultProps = {
  localLinks: false,
  topThreeStyle: 'triangle',
}
