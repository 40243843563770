import styled from 'styled-components/macro'
import { rem } from 'polished'

import { Currency } from '@tiltify/ui'

const Wrapper = styled.div`
  width: 264px;
  position: relative;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-direction: column;
`

const Amount = styled.div`
  font-weight: bold;
  font-size: ${rem(52)};
  text-align: center;
  color: ${({ theme }) => theme.headingBlue};
`

const Description = styled.p`
  line-height: 28px;
  text-align: center;
  font-size: ${rem(20)};
  color: #293941;
  min-height: 84px;
`

const Image = styled.div<{ image: string }>`
  border-radius: 50%;
  background: ${({ image }) => `url(${image})`};
  background-size: contain;
  height: 160px;
  width: 160px;
`

export const SingleImpact = ({
  amount,
  image,
  description,
}: {
  amount: number
  image: string
  description: string
}): JSX.Element => {
  return (
    <Wrapper>
      <Image image={image} />
      <TextWrapper>
        <Amount>
          <Currency
            value={amount}
            currency='USD'
            maximumFractionDigits={0}
            minimumFractionDigits={0}
          />
        </Amount>
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  )
}
