import styled from 'styled-components'

const StyledSVG = styled.svg<{ fillColor: string; strokeColor: string }>`
  fill: ${({ fillColor }) => fillColor};
  stroke: ${({ strokeColor }) => strokeColor};
`

export function CloseCircle({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <StyledSVG
      viewBox='0 0 23 23'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fillColor={fill}
      strokeColor={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <g
        fillRule='evenodd'
        transform='translate(15, 15) rotate(-45) translate(-15, -15) translate(5)'
      >
        <circle cx='10' cy='10' r='10' fill='none' strokeWidth='2' />
        <path
          d='M11,9 L11,5.99539757 C11,5.4556644 10.5522847,5 10,5 C9.44386482,5 9,5.44565467 9,5.99539757 L9,9 L5.99539757,9 C5.4556644,9 5,9.44771525 5,10 C5,10.5561352 5.44565467,11 5.99539757,11 L9,11 L9,14.0046024 C9,14.5443356 9.44771525,15 10,15 C10.5561352,15 11,14.5543453 11,14.0046024 L11,11 L14.0046024,11 C14.5443356,11 15,10.5522847 15,10 C15,9.44386482 14.5543453,9 14.0046024,9 L11,9 Z'
          stroke='none'
        />
      </g>
    </StyledSVG>
  )
}

CloseCircle.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#000',
}
