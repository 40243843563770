export const Instagram = (): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5665 1.33662H11.5665L11.5665 1.33662C11.3034 1.3365 11.0596 1.33639 10.8329 1.33675V1.3335C8.40789 1.33621 7.94272 1.35247 6.7424 1.40669C5.47377 1.46497 4.78497 1.67641 4.32631 1.85532C3.71911 2.09169 3.28539 2.37361 2.82998 2.82902C2.37457 3.28442 2.09211 3.71814 1.85628 4.32535C1.67818 4.78401 1.4662 5.47254 1.40819 6.74117C1.34584 8.11281 1.33337 8.52268 1.33337 11.9973C1.33337 15.472 1.34584 15.884 1.40819 17.2556C1.46593 18.5243 1.67818 19.2128 1.85628 19.6709C2.09266 20.2784 2.37457 20.711 2.82998 21.1664C3.28539 21.6218 3.71911 21.9038 4.32631 22.1396C4.78524 22.3177 5.47377 22.5297 6.7424 22.5882C8.11404 22.6506 8.52581 22.6641 12.0002 22.6641C15.4743 22.6641 15.8863 22.6506 17.2579 22.5882C18.5266 22.5302 19.2157 22.3188 19.6738 22.1399C20.2812 21.904 20.7136 21.6221 21.169 21.1667C21.6244 20.7116 21.9069 20.2792 22.1427 19.672C22.3208 19.2139 22.5328 18.5253 22.5908 17.2567C22.6532 15.8851 22.6667 15.473 22.6667 12.0006C22.6667 8.5281 22.6532 8.11606 22.5908 6.74442C22.5331 5.47579 22.3208 4.78726 22.1427 4.32915C21.9063 3.72194 21.6244 3.28822 21.169 2.83281C20.7139 2.37741 20.281 2.09549 19.6738 1.85965C19.2151 1.68156 18.5266 1.46958 17.2579 1.41157C15.886 1.34922 15.4743 1.33675 12.0002 1.33675L11.5665 1.33662ZM17.3842 5.05806C17.3842 4.19577 18.0836 3.49721 18.9456 3.49721V3.49667C19.8077 3.49667 20.507 4.19605 20.507 5.05806C20.507 5.92008 19.8077 6.61946 18.9456 6.61946C18.0836 6.61946 17.3842 5.92008 17.3842 5.05806ZM12 5.32155C8.30994 5.32169 5.31823 8.31349 5.31823 12.0035C5.31823 15.6937 8.31009 18.6842 12.0002 18.6842C15.6904 18.6842 18.6811 15.6937 18.6811 12.0035C18.6811 8.3134 15.6901 5.32155 12 5.32155ZM16.3373 12.0035C16.3373 9.60806 14.3954 7.66635 12.0001 7.66635C9.60464 7.66635 7.66293 9.60806 7.66293 12.0035C7.66293 14.3988 9.60464 16.3407 12.0001 16.3407C14.3954 16.3407 16.3373 14.3988 16.3373 12.0035Z'
        fill='#00358E'
      />
      <mask id='mask0' maskUnits='userSpaceOnUse' x='1' y='1' width='22' height='22'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.5665 1.33662H11.5665L11.5665 1.33662C11.3034 1.3365 11.0596 1.33639 10.8329 1.33675V1.3335C8.40789 1.33621 7.94272 1.35247 6.7424 1.40669C5.47377 1.46497 4.78497 1.67641 4.32631 1.85532C3.71911 2.09169 3.28539 2.37361 2.82998 2.82902C2.37457 3.28442 2.09211 3.71814 1.85628 4.32535C1.67818 4.78401 1.4662 5.47254 1.40819 6.74117C1.34584 8.11281 1.33337 8.52268 1.33337 11.9973C1.33337 15.472 1.34584 15.884 1.40819 17.2556C1.46593 18.5243 1.67818 19.2128 1.85628 19.6709C2.09266 20.2784 2.37457 20.711 2.82998 21.1664C3.28539 21.6218 3.71911 21.9038 4.32631 22.1396C4.78524 22.3177 5.47377 22.5297 6.7424 22.5882C8.11404 22.6506 8.52581 22.6641 12.0002 22.6641C15.4743 22.6641 15.8863 22.6506 17.2579 22.5882C18.5266 22.5302 19.2157 22.3188 19.6738 22.1399C20.2812 21.904 20.7136 21.6221 21.169 21.1667C21.6244 20.7116 21.9069 20.2792 22.1427 19.672C22.3208 19.2139 22.5328 18.5253 22.5908 17.2567C22.6532 15.8851 22.6667 15.473 22.6667 12.0006C22.6667 8.5281 22.6532 8.11606 22.5908 6.74442C22.5331 5.47579 22.3208 4.78726 22.1427 4.32915C21.9063 3.72194 21.6244 3.28822 21.169 2.83281C20.7139 2.37741 20.281 2.09549 19.6738 1.85965C19.2151 1.68156 18.5266 1.46958 17.2579 1.41157C15.886 1.34922 15.4743 1.33675 12.0002 1.33675L11.5665 1.33662ZM17.3842 5.05806C17.3842 4.19577 18.0836 3.49721 18.9456 3.49721V3.49667C19.8077 3.49667 20.507 4.19605 20.507 5.05806C20.507 5.92008 19.8077 6.61946 18.9456 6.61946C18.0836 6.61946 17.3842 5.92008 17.3842 5.05806ZM12 5.32155C8.30994 5.32169 5.31823 8.31349 5.31823 12.0035C5.31823 15.6937 8.31009 18.6842 12.0002 18.6842C15.6904 18.6842 18.6811 15.6937 18.6811 12.0035C18.6811 8.3134 15.6901 5.32155 12 5.32155ZM16.3373 12.0035C16.3373 9.60806 14.3954 7.66635 12.0001 7.66635C9.60464 7.66635 7.66293 9.60806 7.66293 12.0035C7.66293 14.3988 9.60464 16.3407 12.0001 16.3407C14.3954 16.3407 16.3373 14.3988 16.3373 12.0035Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0)'>
        <rect width='24' height='24' rx='4' fill='#00358E' />
      </g>
    </svg>
  )
}
