import styled from 'styled-components/macro'

import img from 'assets/hsus.jpg'

import { MaxWidthWrapper } from '../styles'

const ImgWrapper = styled.a`
  display: flex;

  img {
    width: 100%;
  }
`

export const SuccesStories = (): JSX.Element => {
  return (
    <MaxWidthWrapper>
      <ImgWrapper
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.humanesociety.org/blog'
      >
        <img
          src={img}
          alt='Check out our blog written by Kitty Block on how your support helps animals.'
        />
      </ImgWrapper>
    </MaxWidthWrapper>
  )
}
