export function Twitter({
  ariaHidden,
  stroke,
  fill,
  focusable,
  height,
  title,
  width,
  isDark,
  ...rest
}: any) {
  const h = height || '1rem'
  const w = width || '1rem'

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={w}
      height={h}
      aria-hidden={ariaHidden}
      focusable={focusable}
      fill={isDark ? '#fff' : fill}
      stroke={stroke}
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        stroke='none'
        d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.61 1.798-1.574 2.165-2.724-.95.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.18 0-5.515 2.966-4.797 6.045C7.727 8.088 4.1 6.128 1.67 3.15.38 5.36 1.003 8.256 3.195 9.722c-.806-.026-1.566-.247-2.23-.616-.053 2.28 1.582 4.415 3.95 4.89-.693.188-1.452.232-2.224.084.626 1.957 2.444 3.38 4.6 3.42-2.07 1.623-4.678 2.348-7.29 2.04 2.18 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.72 13.995-14.646.962-.695 1.797-1.562 2.457-2.55z'
      />
    </svg>
  )
}

Twitter.defaultProps = {
  ariaHidden: false,
  focusable: false,
  stroke: '#000',
  fill: '#1DA1F2',
}
