import React, { useEffect, useReducer } from 'react'
import { ApolloError, useQuery } from '@apollo/client'

import { formatQueryData } from './utils'
import { ACTION_TYPES, routeReducer } from './routeReducer'
import { get_cause_by_slug } from 'gql/cause'

interface IRouteContext {
  cause?: TiltifyPublicCause
  loading: boolean
  error?: ApolloError | string
}
const RouteContext = React.createContext<IRouteContext>({ loading: true })

const RouteContextProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const variables =
    process.env.REACT_APP_TILTIFY_ENV === 'production' ? { slug: 'hsus' } : { slug: 't4c' }
  const { data, loading, error } = useQuery(get_cause_by_slug, {
    variables,
    errorPolicy: 'all',
  })
  return (
    <RouteContextContainer queryData={formatQueryData(data?.cause, loading, error)}>
      {children}
    </RouteContextContainer>
  )
}

const RouteContextContainer = ({
  queryData,
  children,
}: {
  queryData: {
    cause?: TiltifyPublicCause
    loading: boolean
    error?: ApolloError | string
  }
  children: JSX.Element
}): JSX.Element => {
  const [state, dispatch] = useReducer(routeReducer, queryData)

  useEffect(() => {
    if (
      !queryData.loading &&
      (queryData.loading !== state.loading || queryData.error !== state.error)
    ) {
      if (process.env.REACT_APP_TILTIFY_ENV === 'production') {
        if (!queryData.cause) {
          window.location.href = 'https://tiltify.com'
        }
      }
      dispatch({ type: ACTION_TYPES.INIT_CONTEXT, item: queryData })
    }
    // eslint-disable-next-line
  }, [queryData])

  return <RouteContext.Provider value={state}>{children}</RouteContext.Provider>
}

const useRouteContext = (): IRouteContext => {
  const context = React.useContext(RouteContext)
  if (context === undefined) {
    throw new Error('useRouteContext must be used within a RouteContextProvider')
  }
  return context
}

export { RouteContextProvider, useRouteContext }
