import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { get } from 'lodash'

export const useOffsetPagination = (
  query: any,
  filter: any,
  key: any,
  limit: any,
  staticVariables: any
) => {
  const [variables, setVariables] = useState({
    limit: limit,
    offset: 0,
    query: filter,
    ...staticVariables,
  })

  const [searchQuery, { data, loading }] = useLazyQuery(query, {
    variables,
  })

  useEffect(() => {
    if (filter?.length >= 3 || filter?.length === 0) {
      setVariables({ ...variables, query: filter })
      searchQuery({
        variables: { ...variables, query: filter },
      })
    }
  }, [filter])

  useEffect(() => {
    searchQuery({ variables: { ...variables } })
  }, [])

  useEffect(() => {
    setVariables((prevVariables: any) => ({
      ...prevVariables,
      ...staticVariables,
    }))
  }, [staticVariables])

  const canGoNext = data ? get(data, key)?.pagination?.hasNextPage : false
  const canGoPrev = data ? get(data, key)?.pagination?.offset !== 0 : false

  const goToFirstPage = () =>
    searchQuery({
      variables: {
        ...variables,
        offset: 0,
      },
    })

  const goToNext = () => {
    searchQuery({
      variables: {
        ...variables,
        offset: get(data, key).pagination.offset - get(data, key).pagination.limit,
      },
    })
  }

  const goToPrev = () => {
    searchQuery({
      variables: {
        ...variables,
        offset: get(data, key).pagination.offset + get(data, key).pagination.limit,
      },
    })
  }

  return { data, loading, canGoNext, canGoPrev, goToFirstPage, goToNext, goToPrev }
}
