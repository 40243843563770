import styled from 'styled-components/macro'
import { rem } from 'polished'
import { MaxWidthWrapper, StyledHeading } from 'components/styles'

// import videoBoxBackground from 'assets/video-box-background.jpg'
import videoBoxBackground from 'assets/section-about.jpg'

const Wrapper = styled.div`
  background: linear-gradient(to bottom, rgba(32, 39, 53, 0.2), rgba(32, 39, 53, 0.7)),
    url(${videoBoxBackground});
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.whiteText};
  height: 500px;

  @media (max-width: 1150px) {
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-top: ${rem(40)};
  }
`

const HumaneHeading = styled(StyledHeading)`
  color: ${({ theme }) => theme.whiteText};
  margin-top: 0;
  margin-bottom: 1.5rem;
  text-align: left;
  font-size: ${rem(30)};
  font-weight: normal;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
  }
`

const StyledMaxWidthWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: ${rem(64)};

  @media (max-width: 1150px) {
    flex-direction: column;
    margin-bottom: ${rem(70)};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-bottom: ${rem(40)};
  }
`

const TextContainer = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    line-height: 1.5;
    font-size: ${rem(20)};
    margin-bottom: 0;
  }
  @media (max-width: 1150px) {
    max-width: 100%;
    p {
      margin-bottom: 2rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
    align-items: center;
    #learn {
      display: none;
    }
  }
`

const StyledMediaWrapper = styled.div`
  padding-top: 25.25%;
  position: relative;
  width: 45%;
  align-self: center;

  @media (max-width: 950px) {
    width: 100%;
    padding-top: 56.25%;
  }
`

const Youtube = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @media (min-width: 1150px) {
    max-height: 331px;
  }
`

export const VideoBox = (): JSX.Element => {
  return (
    <Wrapper>
      <StyledMaxWidthWrapper>
        <TextContainer>
          <HumaneHeading>The Humane Society of the United States</HumaneHeading>
          <p>
            We take on the biggest fights wherever we find them by confronting industries that
            profit from animal cruelty and helping change laws to criminalize abuse.
          </p>
          <p>
            Our rescues and direct care programs respond to today's cruelties; our education,
            legislative and policy work prevents tomorrow's.
          </p>
          <p>
            Among other fights, we're stopping puppy mills, ending cosmetics animal testing, banning
            trophy hunting, protecting animals used for meat and preventing horse slaughter—and we
            can’t do it without you.{' '}
          </p>
        </TextContainer>
        <StyledMediaWrapper>
          <Youtube
            allowFullScreen
            frameBorder='0'
            scrolling='no'
            src='https://www.youtube.com/embed/koguvz7Fk7I'
          />
        </StyledMediaWrapper>
      </StyledMaxWidthWrapper>
    </Wrapper>
  )
}
