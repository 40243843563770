import { Helmet as ReactHelmet, HelmetProvider } from 'react-helmet-async'

import { tiltifyUrl } from '@tiltify/ui/utils'

const DOMAIN = 'https://tiltify.com'

interface IHelmet {
  title: string
  description: string
  image: string
}

export const Helmet = ({ title, description, image }: IHelmet): JSX.Element => {
  const url = tiltifyUrl(`${DOMAIN}${window.location.pathname}`)
  const twitterUrl = image || 'https://assets.tiltify.com/assets/tiltify-mark.png'
  const facebookUrl = image || 'https://assets.tiltify.com/assets/tiltify-logo.png'
  return (
    <HelmetProvider>
      <ReactHelmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={url} />
        <meta
          name='keywords'
          content='Charity,Livestreaming,Tiltify,fundraising,twitch,video games'
        />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={url} />
        <meta property='og:site_name' content='Tiltify' />
        <meta property='og:local' content='en_US' />
        <meta property='og:image' content={facebookUrl} />

        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
        <meta property='twitter:card' content='summary' />
        <meta property='twitter:site' content='@Tiltify' />
        <meta property='twitter:site:id' content='396852588' />
        <meta property='twitter:domain' content={DOMAIN} />
        <meta property='twitter:image' content={twitterUrl} />

        <link
          rel='shortcut icon'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          type='image/x-icon'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='57x57'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='114x114'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='72x72'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='144x144'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='60x60'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='120x120'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='76x76'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='152x152'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          sizes='120x120'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />
        <link
          rel='apple-touch-icon-precomposed'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
        />

        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='256x256'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='128x128'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='96x96'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='64x64'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='48x48'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='32x32'
        />
        <link
          rel='icon'
          type='image/png'
          href='https://assets.tiltify.com/assets/myhumane/favicon.ico'
          sizes='16x16'
        />

        <link rel='manifest' href='https://assets.tiltify.com/site.webmanifest' />
        <link
          rel='mask-icon'
          href='https://assets.tiltify.com/safari-pinned-tab.svg'
          color='#5bbad5'
        />

        <meta name='msapplication-config' content='/iconx/browserconfig.xml' />
      </ReactHelmet>
    </HelmetProvider>
  )
}
