import { useEffect, useState } from 'react'
import { readableColor, rgba } from 'polished'

import styled from 'styled-components'

import { StyledInput } from '../styles'

const StyledLength = styled.span<{ visibleLength: boolean | null }>`
  font-size: 0.875rem;
  pointer-events: none;
  position: absolute;
  text-align: right;
  bottom: 0.875rem;
  right: 0.875rem;
  padding: 0 0.5rem;
  color: ${({ theme }) => rgba(readableColor(theme.backgroundColor), 0.75)};
  transition: all 0.6s ease-in-out;
  background-color: ${({ theme }) => rgba(theme.backgroundColor, 0.7)};
  border-radius: 3px;
  ${({ visibleLength }) => (visibleLength ? 'opacity: 1;' : 'opacity: 0;')}
`

export const TextInput = ({
  field,
  form: { touched, errors },
  required,
  disabled,
  maxLength,
  type,
  isTextArea,
  ...props
}: any) => {
  const displayError = errors[field.name] && touched[field.name]
  const [visibleMaxLength, setVisibleMaxLength] = useState(false)

  useEffect(() => {
    if (maxLength !== 255) {
      if (!visibleMaxLength) setVisibleMaxLength(true)
      const timeout = setTimeout(() => setVisibleMaxLength(false), 500)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [field.value, maxLength])

  return (
    <>
      <StyledInput
        {...field}
        {...props}
        disabled={disabled}
        displayError={displayError}
        aria-required={required}
        aria-invalid={displayError}
        maxLength={maxLength}
        type={type}
        as={isTextArea && 'textarea'}
      />
      {maxLength ? (
        <StyledLength visibleLength={visibleMaxLength ? true : null}>
          {field.value ? field.value.length : 0} / {Math.floor(maxLength)}
        </StyledLength>
      ) : null}
    </>
  )
}

TextInput.defaultProps = {
  isTextArea: false,
  maxLength: 255,
  required: false,
  size: 'default',
  type: 'text',
}
