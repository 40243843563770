import styled from 'styled-components'
import { rem } from 'polished'

import { Currency } from '@tiltify/ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 385px;

  @media (max-width: 1200px) {
    margin: 0.5rem;
  }
  @media (max-width: 400px) {
    width: 98%;
  }
`

const Text = styled.p`
  font-size: ${rem(18)};
  line-height: 1.3;
  max-width: 90%;
`
const Image = styled.img<{ imageWidth?: number }>`
  max-width: ${({ imageWidth }) => (imageWidth ? `${imageWidth}px` : '200px')};
  height: 200px;
  object-fit: cover;
`

const Amount = styled.div`
  color: ${({ theme }) => theme.headingBlue};
  padding: 10px 24px;
  font-size: ${rem(52)};
  line-height: 61px;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(34)};
  }
`

const Title = styled.p`
  color: ${({ theme }) => theme.darkBlue};
  font-size: ${rem(20)};
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: ${rem(10)};
  margin-top: 0;
`

interface ISingleIncentive {
  amount: number
  img: string
  alt?: string
  title: string
  desc?: string
  imageWidth?: number
}
export const SingleIncentive = ({
  amount,
  img,
  alt,
  title,
  desc,
  imageWidth,
}: ISingleIncentive): JSX.Element => {
  return (
    <Wrapper>
      <Image imageWidth={imageWidth} src={img} alt={alt} />
      <Amount>
        Raise{' '}
        <Currency
          value={amount}
          currency='USD'
          maximumFractionDigits={0}
          minimumFractionDigits={0}
        />
      </Amount>
      <Title>{title}</Title>
      {desc && <Text>{desc}</Text>}
    </Wrapper>
  )
}
