import styled from 'styled-components/macro'
import { rem } from 'polished'

import { baseTiltifyUrl } from '@tiltify/ui/utils'
import { useRouteContext } from 'contexts/RouteContext'
import { LightBlueButton } from 'components/styles'

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 592px;
  height: 475px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconWrapper = styled.div`
  margin-top: ${rem(36)};
  height: 96px;
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  text-align: center;
`

const Title = styled.h3`
  padding: 0 ${rem(13)};
  text-transform: uppercase;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.darkBlue};
`

const Description = styled.p`
  padding: 0 ${rem(13)};
  font-size: ${rem(20)};
  color: #293941;
`

const StyledLightBlueButton = styled(LightBlueButton)`
  margin-right: 0;
  margin-bottom: ${rem(38)};
  max-width: 150px;
  justify-self: center;
`

const Icon = styled.img`
  height: 100px;
  width: 100px;
`

interface ICard {
  title: string
  description: string
  icon: string
  startLink: string
}
export const Card = ({ title, description, icon, startLink }: ICard): JSX.Element => {
  const { cause } = useRouteContext()

  return (
    <Wrapper>
      <InnerWrapper>
        <IconWrapper>
          <Icon src={icon} alt='' />
        </IconWrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ContentWrapper>
      </InnerWrapper>
      <StyledLightBlueButton
        forwardedAs='a'
        href={`${baseTiltifyUrl()}/${cause?.slug}/start${startLink}`}
        outline={false}
      >
        Select
      </StyledLightBlueButton>
    </Wrapper>
  )
}
