import styled from 'styled-components'

import { Field, ErrorMessage } from 'formik'
import { TextInput } from './TextInput'
import { StyledLabel, StyledError, ErrorWrapper } from './styles'

const RequiredSpan = styled.span`
  color: ${({ theme }) => theme.danger};
`

const HelperLabel = styled.span`
  margin-left: auto;
`

const LabelSpan = styled.span<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

export const Input = ({ name, label, helperLabel, component, disabled, ...rest }: any) => {
  return (
    <>
      <StyledLabel>
        {label && (
          <LabelSpan disabled={disabled}>
            {label}&nbsp;{rest.required && <RequiredSpan>*</RequiredSpan>}
          </LabelSpan>
        )}
        {helperLabel && <HelperLabel>{helperLabel}</HelperLabel>}
        <Field component={component} name={name} disabled={disabled} {...rest} />
      </StyledLabel>
      <ErrorWrapper id='error-wrapper'>
        <ErrorMessage name={name}>{(error) => <StyledError>{error}</StyledError>}</ErrorMessage>
      </ErrorWrapper>
    </>
  )
}

Input.defaultProps = {
  component: TextInput,
}
