import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { rem } from 'polished'
import { useNavigate } from 'react-router-dom'

import { useRouteContext } from 'contexts/RouteContext'
import { Button, Form, Input } from '@tiltify/ui'
import { CauseCampaigns } from './CauseCampaigns'
import { AccentBar, StyledHeading } from 'components/styles'

const Wrapper = styled.div`
  height: 100%;
  min-height: 95vh;
`

const HeadingWrapper = styled.div``

const FilterWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Heading = styled(StyledHeading)`
  color: ${({ theme }) => theme.headingBlue};
  text-align: center;
  font-size: ${rem(40)};
  font-weight: 400;
  margin-bottom: ${rem(5)};
`

const StyledButton = styled(Button)`
  max-width: ${rem(200)};
  display: flex;
  justify-content: center;
  padding: ${rem(12)} ${rem(25)};
  background-color: ${({ theme }) => theme.lightBlue} !important;
  color: #fff !important;
  font-weight: bold;
  font-size: ${rem(14)};
  border-radius: 4px;
  text-decoration: none;
  margin: ${rem(48)} auto ${rem(64)};
`

export const FullCampaignList = (): JSX.Element | null => {
  const navigate = useNavigate()
  const { cause } = useRouteContext()
  const [value, setValue] = useState('')

  const filterCampaigns = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setValue(value)
  }

  if (!cause) return null

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading>Current Fundraisers</Heading>
        <AccentBar />
        <FilterWrapper>
          <Form
            initialValues={{
              filterCampaigns: '',
            }}
            onSubmit={() => {}}
          >
            <Input
              placeholder=''
              name='filterCampaigns'
              label=''
              required={false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterCampaigns(e)}
              value={value || ''}
            />
          </Form>
        </FilterWrapper>
      </HeadingWrapper>
      <CauseCampaigns value={value} cause={cause} />
      <StyledButton onClick={() => navigate(-1)}>Back</StyledButton>
    </Wrapper>
  )
}
