import styled from 'styled-components/macro'
import { rem } from 'polished'
import { useNavigate } from 'react-router-dom'

import { StyledButton } from 'components/styles'
import { Leaderboard } from './index'

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(45)};
`

const LightBlueButton = styled(StyledButton)`
  color: #fff;
  border-color: #fff;
  background-color: ${({ theme }) => theme.lightBlue};
  margin-right: ${rem(36)};
  margin: 0 auto;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-right: 0;
  }
`

export const FullLeaderboard = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <Leaderboard maxAmountOfDonators={250} full>
      <LinkWrapper>
        <LightBlueButton onClick={() => navigate(-1)}>Back</LightBlueButton>
      </LinkWrapper>
    </Leaderboard>
  )
}
