import styled from 'styled-components'

const Progress = styled.div<{
  width: number
  height: number
  background: string
  radius: string
  widget: any
  progressBarStyle: any
}>`
  width: ${({ width }) => width}%;
  ${({ widget, height }) => widget && `height: ${height}px`};
  min-height: ${({ height }) => height}px;
  background: ${({ background }) => background};
  border-radius: ${({ radius }) => radius}px;
  position: relative;
  transform-origin: 100%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  border: solid 1px transparent;

  ${({ progressBarStyle }) =>
    progressBarStyle &&
    `
      transform: skewX(-35deg);
    `}
`

const BarWrapper = styled.div<{
  width: number
  radius: string
  customBarWrapper: boolean
  barHeight: number
}>`
  height: 100%;
  width: ${({ width }) => width}%;
  border-radius: ${({ radius }) => radius}px;
  overflow: hidden;
  position: absolute;

  ${({ customBarWrapper, barHeight }) =>
    customBarWrapper &&
    `
    height: ${barHeight}%;
    width: calc(100% - 10px);
    left: 5px;
  `}
`
const Bar = styled.div<{ foreground: string; radius: string; percent: number | string }>`
  background: ${({ foreground }) => foreground};
  border-radius: ${({ radius }) => radius}px;
  transform: scaleX(${({ percent }) => percent});
  transform-origin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 500ms;
`

const ChildWrapper = styled.div`
  float: left;
  width: 100%;
  z-index: 1;
`

export const ProgressBar = ({
  height,
  width,
  percent,
  background,
  foreground,
  children,
  radius,
  widget,
  progressBarStyle,
  barHeight,
  customBarWrapper,
  ...rest
}: any) => {
  if (percent < 0) percent = 0
  if (percent > 1) percent = 1

  return (
    <Progress
      radius={radius}
      width={width}
      height={height}
      background={background}
      widget={widget}
      progressBarStyle={progressBarStyle}
      {...rest}
    >
      <BarWrapper
        customBarWrapper={customBarWrapper}
        barHeight={barHeight}
        radius={radius}
        width={width}
      >
        <Bar id='bar' radius={radius} percent={percent} foreground={foreground} />
      </BarWrapper>
      <ChildWrapper>{children}</ChildWrapper>
    </Progress>
  )
}

ProgressBar.defaultProps = {
  percent: 0,
  height: 12,
  width: 100,
  background: 'rgba(129,190,244, .1)',
  foreground: 'rgba(129, 190, 244, 0.2)',
  widget: false,
  barHeight: 100,
  customBarWrapper: false,
}
