import { readableColor, rem } from 'polished'
import styled from 'styled-components'

import { Avatar } from '../../../Avatar'
import { Currency } from '../../../Currency'
import { TiltifyLink } from '../../../TiltifyLink'

const TopThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const TopThreeHeader = styled.h3<{ nameTextColor: string }>`
  line-height: ${rem(24)};
  font-size: ${rem(16)};
  margin-bottom: ${rem(2)};
  margin-top: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  color: ${({ theme, nameTextColor }) => nameTextColor || readableColor(theme.backgroundColor)};
`

const Place = styled(TiltifyLink)`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-bottom: ${rem(24)};
`

const UserAvatar = styled(Avatar)`
  margin-right: ${({ mRight }) => rem(mRight)};
  border-radius: 100px;
  border: ${({ accentColor }) => `4px solid ${accentColor}`};
`

const PlaceContentWrapper = styled.div`
  width: 100%;
  display: flex;
`

const NumberColumn = styled.div`
  font-weight: bold;
  color: ${({ theme }) => readableColor(theme.backgroundColor)};
  min-width: 15px;
`

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 52px;
`

const AmountRaised = styled.div<{ fontFamily: string; textColor: string }>`
  color: ${({ theme, textColor }) => textColor || readableColor(theme.backgroundColor)};
  ${({ fontFamily }) =>
    fontFamily &&
    `
    font-family: ${fontFamily};
  `}
`

const Number = styled.div`
  display: flex;
  line-height: ${rem(24)};
  margin-bottom: ${rem(2)};
`

export const RoundList = ({
  c,
  textColor,
  localLinks,
  currency,
  nameTextColor,
  fontFamily,
}: any) => {
  return (
    <TopThreeWrapper>
      {c[0] && (
        <Place url={c[0].url} localLink={localLinks}>
          <UserAvatar
            height={64}
            width={64}
            mRight={12}
            shape='square'
            src={c[0].avatar?.src}
            alt='First place avatar'
            accentColor='#FFDA00'
          />
          <PlaceContentWrapper>
            <NumberColumn>
              <Number>1.</Number>
            </NumberColumn>
            <DetailsColumn>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[0].name}</TopThreeHeader>
              <AmountRaised textColor={textColor} fontFamily={fontFamily}>
                <Currency currency={currency || 'USD'} value={c[0].amountRaised} />
              </AmountRaised>
            </DetailsColumn>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[1] && (
        <Place url={c[1].url} localLink={localLinks}>
          <UserAvatar
            height={58}
            width={58}
            mRight={18}
            shape='square'
            src={c[1].avatar?.src}
            alt='Second place avatar'
            accentColor='#9F9F9F'
          />
          <PlaceContentWrapper>
            <NumberColumn>
              <Number>2.</Number>
            </NumberColumn>
            <DetailsColumn>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[1].name}</TopThreeHeader>
              <AmountRaised textColor={textColor} fontFamily={fontFamily}>
                <Currency currency={currency || 'USD'} value={c[1].amountRaised} />
              </AmountRaised>
            </DetailsColumn>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[2] && (
        <Place url={c[2].url} localLink={localLinks}>
          <UserAvatar
            height={52}
            width={52}
            mRight={24}
            shape='square'
            src={c[2].avatar?.src}
            alt='Third place avatar'
            accentColor='#A18D1C'
          />
          <PlaceContentWrapper>
            <NumberColumn>
              <Number>3.</Number>
            </NumberColumn>
            <DetailsColumn>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[2].name}</TopThreeHeader>
              <AmountRaised textColor={textColor} fontFamily={fontFamily}>
                <Currency currency={currency || 'USD'} value={c[2].amountRaised} />
              </AmountRaised>
            </DetailsColumn>
          </PlaceContentWrapper>
        </Place>
      )}
    </TopThreeWrapper>
  )
}
