const formatNode = (node: any) => {
  return {
    ...node,
    amountRaised: node?.amount?.value ?? 0,
  }
}

export const formatFELeaderboards = (leaderboardsData: any, updateLeaderboards: any) => {
  const individual: any = []
  const team: any = []
  const weekly: any = []
  const donor: any = []

  const { userLeaderboard, teamLeaderboard, weeklyLeaderboard, donorLeaderboard } =
    leaderboardsData.data.fundraisingEvent

  if (userLeaderboard?.entries?.edges && userLeaderboard.entries.edges.length > 0)
    userLeaderboard.entries.edges.map(({ node }: any) => individual.push(formatNode(node)))
  if (teamLeaderboard?.entries?.edges && teamLeaderboard.entries.edges.length > 0)
    teamLeaderboard.entries.edges.map(({ node }: any) => team.push(formatNode(node)))
  if (weeklyLeaderboard?.entries?.edges && weeklyLeaderboard.entries.edges.length > 0)
    weeklyLeaderboard.entries.edges.map(({ node }: any) => weekly.push(formatNode(node)))
  if (donorLeaderboard?.entries?.edges && donorLeaderboard.entries.edges.length > 0)
    donorLeaderboard.entries.edges.map(({ node }: any) => donor.push(formatNode(node)))

  updateLeaderboards({ individual, team, weekly, donor })
}

export const formatCauseLeaderboards = (leaderboardsData: any, updateLeaderboards: any) => {
  const individual: any = []
  const team: any = []

  const { userLeaderboard, teamLeaderboard } = leaderboardsData.data.cause

  if (userLeaderboard?.entries?.edges && userLeaderboard.entries.edges.length > 0)
    userLeaderboard.entries.edges.map(({ node }: any) => individual.push(formatNode(node)))
  if (teamLeaderboard?.entries?.edges && teamLeaderboard.entries.edges.length > 0)
    teamLeaderboard.entries.edges.map(({ node }: any) => team.push(formatNode(node)))

  updateLeaderboards({ individual, team })
}
