import { useState, useEffect, createRef } from 'react'

import styled from 'styled-components'
import { readableColor, rem } from 'polished'

import { Portal } from '../Portal'
import { styledScrollbar } from '../../styles'
import { CloseCircle } from '../../icons/CloseCircle'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'

const ModalContainer = styled.div<{ modalStyles: any }>`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.modalOverlay};
  ${({ modalStyles }) => modalStyles};
`
const ModalContent = styled.div`
  width: 100%;
  ${({ theme }) => `max-width: ${theme.contentMaxWidth};`}
  margin-top: 4rem;
  color: ${({ theme }) => readableColor(theme.backgroundColor)};
  display: flex;
  flex-direction: column;
`

const ModalBody = styled.div`
  flex: 1;
  padding: 14px 29px 14px 29px;
  overflow-y: auto;
  ${styledScrollbar}
`
const ModalHeader = styled.div`
  position: relative;
  width: 100%;
  padding: 9px 10px;
  font-size: ${rem(24)};
  min-height: 20px;
  ${({ title }) =>
    title &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25px;
  `}
`

const CloseButton = styled.button<{ fullscreen: boolean }>`
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  cursor: pointer;
  height: 32px;
  ${({ disabled }) => disabled && '& > svg{opacity: 0.5;}'}

  ${({ fullscreen }) => fullscreen && 'top: 30px; right: 30px'}
`

export const FullPageModal = ({
  setIsVisible,
  children,
  modalRoot,
  skipOutsideClick,
  className,
  locked,
  scrollLock,
  modalStyles,
  fullscreen,
  title,
}: any) => {
  const handleClose = () => setIsVisible(false)
  const [focusTrapped, setFocusTrapped] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function keyListener(e: any) {
      const listener = keyListenersMap.get(e.keyCode)
      return listener && listener(e)
    }

    document.addEventListener('keydown', keyListener)

    return () => document.removeEventListener('keydown', keyListener)
  })

  const modalRef = createRef()

  useOnOutsideClick(modalRef, handleClose, skipOutsideClick)

  const handleTabKey = (e: any) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea,   input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
    const firstElement = focusableModalElements[0]
    const lastElement = focusableModalElements[focusableModalElements.length - 1]

    if (!focusTrapped) {
      if (Array.from(focusableModalElements).find((e) => e === document.activeElement)) {
        setFocusTrapped(true)
      } else {
        firstElement.focus()
        setFocusTrapped(true)
      }
    }

    if (focusableModalElements.length === 1) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const keyListenersMap = new Map([
    [27, () => setIsVisible(false)],
    [9, handleTabKey],
  ])

  return (
    <Portal default={modalRoot} scrollLock={scrollLock}>
      <ModalContainer role='dialog' aria-modal='true' modalStyles={modalStyles}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <ModalContent className={className} id='modal-content' ref={modalRef}>
          <ModalHeader title={title}>
            {title && <span>{title}</span>}
            <CloseButton
              title='close modal'
              onClick={handleClose}
              disabled={locked}
              fullscreen={fullscreen}
            >
              <CloseCircle
                height={32}
                width={32}
                fill={({ theme }: any) => theme.lightBlue}
                stroke={({ theme }: any) => theme.lightBlue}
              />
            </CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ModalContainer>
    </Portal>
  )
}

FullPageModal.defaultProps = {
  default: true,
  skipOutsideClick: false,
  locked: false,
  scrollLock: false,
  overflowScroll: false,
  fullscreen: false,
}
