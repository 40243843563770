import styled from 'styled-components'
import { FirstCaret } from '../../icons/FirstCaret'
import { CaretLeft } from '../../icons/CaretLeft'
import { CaretRight } from '../../icons/CaretRight'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 2rem;
`

const Button = styled.button`
  align-items: center;
  border-radius: 50%;
  background: transparent;
  border: solid 2px ${({ theme }) => theme.lightBlue};
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  line-height: 32px;
  margin-left: 8px;
  transition: background-color 0.3s ease-in-out;
  width: 32px;
  margin: 0.25rem;
  padding: 0;

  svg,
  svg g {
    display: block;
    height: 16px;
    stroke: ${({ theme }) => theme.lightBlue};
    width: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`

export const PaginatedControls = ({
  firstPage,
  prevPage,
  nextPage,
  canGoPrev,
  canGoNext,
  ...props
}: any) => {
  return (
    <Wrapper {...props}>
      <Button onClick={firstPage} disabled={!canGoNext} type='button'>
        <FirstCaret />
      </Button>
      <Button onClick={nextPage} disabled={!canGoNext} type='button'>
        <CaretLeft />
      </Button>
      <Button onClick={prevPage} disabled={!canGoPrev} type='button'>
        <CaretRight />
      </Button>
    </Wrapper>
  )
}
