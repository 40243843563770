import { useEffect } from 'react'

import { Formik, Form as FormikForm, useFormikContext } from 'formik'

export const useFormContext = useFormikContext

export const Form = ({
  initialValues,
  validate,
  onSubmit,
  enableReinitialize,
  children,
  ...rest
}: any) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    validate={validate}
    onSubmit={onSubmit}
    {...rest}
  >
    <FormikForm>{children}</FormikForm>
  </Formik>
)

export const FormErrorHandler = ({ errors }: any) => {
  const { setErrors } = useFormContext()

  useEffect(() => {
    if (errors) {
      setErrors(errors)
    }
  }, [errors, setErrors])

  return null
}
