import React, { useEffect } from 'react'
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  useLazyQuery,
  useQuery,
} from '@apollo/client'

import { GET_AUTHENTICATED_USER } from '../../gql/queries/user'

const ACTION_TYPES = {
  INIT_CONTEXT: 'INIT_CONTEXT',
  UPDATE_USER: 'UPDATE_USER',
  CREATE_USER: 'CREATE_USER',
  CLEAR_USER: 'CLEAR_USER',
  SHOW_AUTHENTICATION: 'SHOW_AUTHENTICATION',
  HIDE_AUTHENTICATION: 'HIDE_AUTHENTICATION',
}

interface IAuthenticationContext {
  user: TiltifyPrivateUser | null
  loading: boolean | null
  error: ApolloError | string | null
  showLogin: boolean
  showAuthentication: boolean
  userTypeVisible: string | null
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<{ data: TiltifyPrivateUser | null }>>
}

type AuthenticationDispatch = (action: {
  type: string
  item?: Record<string, unknown> | null
  showLogin?: any
  user?: any
  userTypeVisible?: any
}) => void

const AuthenticationContext = React.createContext<IAuthenticationContext>({
  user: null,
  loading: null,
  error: null,
  showLogin: true,
  showAuthentication: false,
  userTypeVisible: null,
})
const AuthenticationDispatchContext = React.createContext<AuthenticationDispatch>(() => undefined)

const authenticationReducer = (state: IAuthenticationContext, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.INIT_CONTEXT: {
      return { ...state, ...action.item }
    }
    case ACTION_TYPES.UPDATE_USER: {
      return { ...state, user: { ...state.user, ...action.user } }
    }
    case ACTION_TYPES.CLEAR_USER: {
      return { ...state, user: null }
    }
    case ACTION_TYPES.SHOW_AUTHENTICATION: {
      const showLogin = action.showLogin || false

      return {
        ...state,
        showAuthentication: true,
        showLogin: showLogin,
        userTypeVisible: action.userTypeVisible || state.userTypeVisible,
      }
    }
    case ACTION_TYPES.HIDE_AUTHENTICATION: {
      return { ...state, showAuthentication: false, userTypeVisible: null }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const AuthenticationProvider = ({
  children,
  ignoreAuth = false,
  userTypeVisible = null,
}: {
  children: React.ReactNode | React.ReactNode[]
  ignoreAuth?: boolean
  userTypeVisible?: string | null
}) => {
  const queryData = useQuery(GET_AUTHENTICATED_USER, {
    skip: ignoreAuth || (window?.parent?.name === 'tiltifyWindow' && !!window.opener),
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (window?.parent?.name === 'tiltifyWindow' && window.opener) {
      window.opener.postMessage('closeTiltifyLogin', window.location.origin)
    }
  }, [])

  if (window?.parent?.name === 'tiltifyWindow' && window.opener) return null

  return (
    <AuthenticationContainer
      queryData={queryData}
      ignoreAuth={ignoreAuth}
      userTypeVisible={userTypeVisible}
    >
      {children}
    </AuthenticationContainer>
  )
}

const AuthenticationContainer = ({
  children,
  ignoreAuth = false,
  queryData,
  userTypeVisible = null,
}: {
  children: React.ReactNode | React.ReactNode[]
  ignoreAuth?: boolean
  queryData?: any
  userTypeVisible: string | null
}) => {
  const [getAuthenticatedUser, lazyQueryData] = useLazyQuery(GET_AUTHENTICATED_USER)
  const [state, dispatch] = React.useReducer(authenticationReducer, {
    user: !ignoreAuth ? queryData?.data?.authenticatedUser : null,
    loading: !ignoreAuth ? queryData.loading : null,
    error: !ignoreAuth ? queryData.error : null,
    showLogin: true,
    showAuthentication: false,
    userTypeVisible: userTypeVisible,
    refetch: getAuthenticatedUser,
  })

  useEffect(() => {
    if (!queryData.loading && queryData.data) {
      const {
        data: { authenticatedUser },
        loading,
        error,
      } = queryData

      dispatch({
        type: ACTION_TYPES.INIT_CONTEXT,
        item: {
          user: authenticatedUser?.id ? authenticatedUser : null,
          loading,
          error,
        },
      })
    }
  }, [queryData])

  useEffect(() => {
    if (lazyQueryData?.data?.authenticatedUser) {
      dispatch({
        type: ACTION_TYPES.UPDATE_USER,
        user: lazyQueryData?.data?.authenticatedUser,
      })
    }
  }, [lazyQueryData])

  return (
    <AuthenticationContext.Provider
      value={{ ...state, error: !ignoreAuth ? queryData.error : null }}
    >
      <AuthenticationDispatchContext.Provider value={dispatch}>
        {/* {state?.user?.notice && <ImpersonationBanner id={state?.user?.id} />} */}
        {children}
      </AuthenticationDispatchContext.Provider>
    </AuthenticationContext.Provider>
  )
}

const useAuthenticationState = () => {
  const context = React.useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error('useAuthenticationState must be used within a AuthenticationProvider')
  }
  return context
}

const useAuthenticationDispatch = () => {
  const context = React.useContext(AuthenticationDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthenticationDispatch must be used within a AuthenticationProvider')
  }
  return context
}

useAuthenticationDispatch.ACTION_TYPES = ACTION_TYPES

export { AuthenticationProvider, useAuthenticationState, useAuthenticationDispatch }
