import { readableColor, rem } from 'polished'
import styled from 'styled-components'

import { Avatar } from '../../../Avatar'
import { Currency } from '../../../Currency'
import { TiltifyLink } from '../../../TiltifyLink'

const TopThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 90%;
`

const TopThreeHeader = styled.h3<{ nameTextColor: string }>`
  font-size: ${rem(16)};
  line-height: 22px;
  margin-bottom: ${rem(2)};
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  color: ${({ theme, nameTextColor }) => nameTextColor || readableColor(theme.backgroundColor)};
`

const Place = styled(TiltifyLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin-bottom: ${rem(32)};
`

const UserAvatar = styled(Avatar)`
  margin: 0 0 ${rem(8)} 0;
  border-radius: 4px;
  border: ${({ accentColor }) => `4px solid ${accentColor}`};
`

const PlaceContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 52px;
`

const Name = styled.div<{ fontFamily: string }>`
  display: flex;
  ${({ fontFamily }) =>
    fontFamily &&
    `
  font-family: ${fontFamily};
`}
`

const AmountRaised = styled.div<{ textColor: string }>`
  color: ${({ theme, textColor }) => textColor || readableColor(theme.backgroundColor)};
`

export const CenteredSquareList = ({
  c,
  col,
  textColor,
  localLinks,
  currency,
  nameTextColor,
  fontFamily,
}: any) => {
  return (
    <TopThreeWrapper key={col}>
      {c[0] && (
        <Place url={c[0].url} localLink={localLinks}>
          <UserAvatar
            height={64}
            width={64}
            shape='square'
            src={c[0].avatar?.src}
            alt='First place avatar'
            accentColor='#FFDA00'
          />
          <PlaceContentWrapper>
            <Name fontFamily={fontFamily}>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[0].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor}>
              <Currency currency={currency || 'USD'} value={c[0].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[1] && (
        <Place url={c[1].url} localLink={localLinks}>
          <UserAvatar
            height={58}
            width={58}
            shape='square'
            src={c[1].avatar?.src}
            alt='Second place avatar'
            accentColor='#9F9F9F'
          />
          <PlaceContentWrapper>
            <Name fontFamily={fontFamily}>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[1].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor}>
              <Currency currency={currency || 'USD'} value={c[1].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[2] && (
        <Place url={c[2].url} localLink={localLinks}>
          <UserAvatar
            height={52}
            width={52}
            shape='square'
            src={c[2].avatar?.src}
            alt='Third place avatar'
            accentColor='#A18D1C'
          />
          <PlaceContentWrapper>
            <Name fontFamily={fontFamily}>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[2].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor}>
              <Currency currency={currency || 'USD'} value={c[2].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
    </TopThreeWrapper>
  )
}
