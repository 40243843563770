import { readableColor, rem } from 'polished'
import styled from 'styled-components'

import { Avatar } from '../../../Avatar'
import { Currency } from '../../../Currency'
import { TiltifyLink } from '../../../TiltifyLink'

const TopThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`

const TopThreeHeader = styled.h3<{ nameTextColor: string }>`
  font-size: ${rem(20)};
  line-height: 25px;
  margin-bottom: ${rem(2)};
  margin-top: 0;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-decoration: underline;
  color: ${({ theme, nameTextColor }) => nameTextColor || readableColor(theme.backgroundColor)};
`

const Place = styled(TiltifyLink)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-bottom: ${rem(24)};
`

const UserAvatar = styled(Avatar)`
  margin-right: ${({ mRight }) => rem(mRight)};
  border-radius: 0px;
`

const PlaceContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 52px;
`

const AccentBar = styled.div<{ accentColor: string; width: string }>`
  background-color: ${({ accentColor }) => accentColor};
  height: 6px;
  width: ${({ width }) => width};
  transform: skewX(-45deg);
`

const Name = styled.div`
  display: flex;
`

const AmountRaised = styled.div<{ fontFamily: string; textColor: string }>`
  margin-left: ${rem(18)};
  color: ${({ theme, textColor }) => textColor || readableColor(theme.backgroundColor)};
  ${({ fontFamily }) =>
    fontFamily &&
    `
  font-family: ${fontFamily};
`}
`

const Number = styled.span`
  font-weight: bold;
  color: ${({ theme }) => readableColor(theme.backgroundColor)};
`

export const SquareList = ({
  c,
  textColor,
  localLinks,
  currency,
  nameTextColor,
  fontFamily,
}: any) => {
  return (
    <TopThreeWrapper>
      {c[0] && (
        <Place url={c[0].url} localLink={localLinks}>
          <UserAvatar
            height={64}
            width={64}
            mRight={12}
            shape='square'
            src={c[0].avatar?.src}
            alt='First place avatar'
          />
          <PlaceContentWrapper>
            <AccentBar accentColor='#FFDA00' width='170px' />
            <Name>
              <Number style={{ marginRight: '5px', fontSize: '22px', minWidth: '14px' }}>1.</Number>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[0].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor} fontFamily={fontFamily}>
              <Currency currency={currency || 'USD'} value={c[0].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[1] && (
        <Place url={c[1].url} localLink={localLinks}>
          <UserAvatar
            height={58}
            width={58}
            mRight={18}
            shape='square'
            src={c[1].avatar?.src}
            alt='Second place avatar'
          />
          <PlaceContentWrapper>
            <AccentBar accentColor='#9F9F9F' width='140px' />
            <Name>
              <Number style={{ marginRight: '5px', fontSize: '22px' }}>2.</Number>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[1].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor} fontFamily={fontFamily}>
              <Currency currency={currency || 'USD'} value={c[1].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
      {c[2] && (
        <Place url={c[2].url} localLink={localLinks}>
          <UserAvatar
            height={52}
            width={52}
            mRight={24}
            shape='square'
            src={c[2].avatar?.src}
            alt='Third place avatar'
          />
          <PlaceContentWrapper>
            <AccentBar accentColor='#A18D1C' width='100px' />
            <Name>
              <Number style={{ marginRight: '5px', fontSize: '22px' }}>3.</Number>
              <TopThreeHeader nameTextColor={nameTextColor}>{c[2].name}</TopThreeHeader>
            </Name>
            <AmountRaised textColor={textColor} fontFamily={fontFamily}>
              <Currency currency={currency || 'USD'} value={c[2].amountRaised} />
            </AmountRaised>
          </PlaceContentWrapper>
        </Place>
      )}
    </TopThreeWrapper>
  )
}
