import { ApolloError } from '@apollo/client'

interface IQueryData {
  cause?: TiltifyPublicCause
  loading: boolean
  error?: ApolloError | string
}

export const ACTION_TYPES = {
  INIT_CONTEXT: 'INIT_CONTEXT',
}

export const routeReducer = (
  state: IQueryData,
  action: { type: string; item: IQueryData }
): IQueryData => {
  switch (action.type) {
    case ACTION_TYPES.INIT_CONTEXT: {
      return {
        ...action.item,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
