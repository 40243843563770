import { gql } from '@apollo/client'

export const GET_AUTHENTICATED_USER = gql`
  query authenticatedUser {
    authenticatedUser {
      id
      email
      username
      firstName
      lastName
      avatar {
        src
        alt
        width
        height
      }
      roles {
        donor
        fundraiser
      }
      slug
      profileComplete
    }
  }
`

export const get_auth_user_address = gql`
  query authenticatedUser {
    authenticatedUser {
      shippingAddresses {
        id
        addressLine1
        addressLine2
        firstName
        lastName
        city
        state
        zipCode
        country
        default
      }
      id
      email
      username
      avatar {
        src
        alt
        width
        height
      }
      slug
    }
  }
`

export const get_user_badges = gql`
  query get_user_badges($userId: Int!) {
    badgeGroups {
      description
      key
      name
      rank
      translationKey
      badges(userId: $userId) {
        earned
        id
        key
        description
        name
        rank
        translationKey
        shareText
        image {
          src
        }
        smallImage {
          src
        }
        __typename
      }
    }
  }
`
