import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { Link } from 'react-router-dom'

import { useRouteContext } from 'contexts/RouteContext'
import { NewLeaderboard } from '@tiltify/ui'
import { FullLeaderboardButton } from '../styles'
import { RightArrow } from 'assets/RightArrow'
import { get_cause_leaderboards } from '@tiltify/ui/gql/queries/leaderboards'
import { useLazyQuery } from '@apollo/client'
import { formatCauseLeaderboards } from '@tiltify/ui/utils/formatNewLeaderboards'

const HeadingStyles = css`
  color: ${({ theme }) => theme.headingBlue};
  font-size: ${rem(40)};
  font-weight: 400;
  text-align: center;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(34)};
    line-height: 24px;
    text-align: center;
  }
`
const subHeadingStyles = css`
  color: ${({ theme }) => theme.darkBlue};
  font-size: ${rem(30)};
  font-weight: 400;
  text-align: center;
  line-height: 36px;
  margin-bottom: ${rem(11)};
  margin-top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(24)};
  }
`

const AccentStyles = css`
  background: ${({ theme }) => theme.lightGrey};
  height: 6px;
  width: 5%;
  margin: 0 auto;
  margin-bottom: ${rem(34)};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Leaderboard = ({
  full = false,
  children,
  maxAmountOfDonators,
}: {
  full?: boolean
  children?: JSX.Element
  maxAmountOfDonators?: number
}): JSX.Element | null => {
  const { cause } = useRouteContext()
  const [leaderboards, updateLeaderboards] = useState()

  const [getLeaderboards, leaderboardsData] = useLazyQuery(get_cause_leaderboards, {
    variables: { slug: cause?.slug },
  })

  useEffect(() => {
    if (!cause) return

    getLeaderboards()
    // eslint-disable-next-line
  }, [cause])

  useEffect(() => {
    if (!leaderboardsData?.loading && leaderboardsData?.data)
      formatCauseLeaderboards(leaderboardsData, updateLeaderboards)
  }, [leaderboardsData])

  if (!leaderboards || !cause) return null

  return (
    <NewLeaderboard
      leaderboards={leaderboards}
      leaderboardBackground='#fff'
      headingStyles={HeadingStyles}
      subHeadingStyles={subHeadingStyles}
      accentStyles={AccentStyles}
      currency={cause?.paymentOptions?.currency || 'USD'}
      maxAmountOfDonators={maxAmountOfDonators}
    >
      {!full && (
        <ButtonContainer>
          <FullLeaderboardButton as={Link} to='/leaderboard'>
            View Full Leaderboard <RightArrow />
          </FullLeaderboardButton>
        </ButtonContainer>
      )}
      {children}
    </NewLeaderboard>
  )
}
