import { useEffect } from 'react'

import { useGA } from '../../hooks/useGA'

export const GATrackers = ({ tracker, campaign, cause, fundraisingEvent }: any) => {
  const { pageView, addTracker, setCustomDimension } = useGA()
  const campaignId = campaign?.publicId
  const causeId = cause?.id
  const fundraisingEventId = fundraisingEvent?.legacyFundraisingEventId

  useEffect(() => {
    if (causeId || fundraisingEventId || campaignId) {
      setCustomDimension(causeId, fundraisingEventId, campaignId)
    }
    // eslint-disable-next-line
  }, [causeId, fundraisingEventId, campaignId])

  useEffect(() => {
    pageView()
  }, [pageView])

  useEffect(() => {
    if (tracker?.trackingId) {
      const { trackingId, gaOptions } = tracker
      addTracker([{ trackingId, gaOptions }])
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      pageView([gaOptions.name])
    }
    // eslint-disable-next-line
  }, [tracker?.trackingId])

  return null
}
