import styled, { css } from 'styled-components'
import { rem } from 'polished'

export const StyledLabel = styled.label<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.textColor};
  font-size: 0.875rem;
  & > span:first-of-type {
    margin-left: 0.625rem;
  }
  position: relative;
  display: flex;
  flex-wrap: wrap;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

export const InlineStyledLabel = styled.label`
  color: ${({ theme }) => theme.textColor};
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;

  .inline__label {
    margin-left: 0.5rem;
    max-width: 85%;
  }
`

export const sharedInputStyle = css<{ displayError: string | boolean; disabled: boolean; as: any }>`
  background-color: ${({ theme }) => theme.input?.backgroundColor || theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border: 1px solid;
  border-color: ${({ theme, displayError }) =>
    displayError ? theme.danger : theme.input.borderColor};
  padding: 0.875rem;
  padding-left: 0.625rem;
  border-radius: 3px;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: 100%;
  line-height: 1.4;

  ${({ as }) => {
    if (as === 'textarea') {
      return `
        height: 95px;
        resize: none;
      `
    }
  }}
`

export const StyledInput = styled.input`
  ${sharedInputStyle}
`

export const StyledSelect = styled(StyledInput)`
  appearance: none;
  font-family: 'Open Sans', Helvetica, san-serif;
  font-size: 1em;

  &::-ms-expand {
    display: none;
  }
  option {
    font-weight: normal;
    font-size: 0.875rem;
  }
`

export const StyledError = styled.span`
  color: ${({ theme }) => theme.danger};
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
`

export const Help = styled.div`
  display: flex;
  font-size: 0.6rem;
  justify-content: flex-start;
  margin-bottom: ${rem(6)};
  margin-top: ${rem(6)};
  margin-left: 0.5rem;
  color: #697890;
  width: 100%;
`

export const ErrorWrapper = styled.div`
  min-height: 1.125rem;
  margin-bottom: 0.5rem;
`
