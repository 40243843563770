const colors = {
  primary: '#196FCC',
  success: 'rgb(42, 176, 38)',
  lightGrey: 'rgb(212, 220, 228)',
  medLightGrey: '#c4c4c4',
  darkLightGrey: '#919aa1',
  black: 'rgb(83, 91, 98)',
  grey: '#32353a',
  medDarkGrey: '#303034',
  darkGrey: '#1A1C1E',
  darkTextColor: '#EEF1F4',
  lightTextColor: '#3A3941',
  white: '#FFF',
  linkBlue: '#81BEFF',
}

const themeConstants = {
  breakpoints: ['40em', '52em', '64em'],
  gutter: '1.5rem',
  contentMaxWidth: '1300px',
}

export const light = {
  ...colors,
  ...themeConstants,
  isDark: false,
  backgroundColor: 'rgba(212, 220, 228, 0.4)',
  backgroundAccent: '#fff',
  borderColor: '#D4DCE4',
  input: { borderColor: '#D4DCE4', checkboxColor: colors.primary },
  cardShadow: `box-shadow: 0 1px 0 0 ${colors.grey}`,
  tableAlt: '#d4dce4',
  modalOverlay: 'rgba(83, 91, 98, 0.9)',
  lightBlue: colors.primary,
  textColor: colors.lightTextColor,
  danger: 'rgb(218, 27, 27)',
  toggleColor: '#196FCC',
  donate: '#20861D',
  donateColor: colors.darkTextColor,
  phoneNumberDropdownBackground: '#fff',
  phoneNumberDropdownBorder: '#D4DCE4',
  logoColor: '#1F2124',
  progressBackground: 'rgba(129,190,244, .1)',
  headerProgressBackground: 'rgba(32,134,29,0.1)',
  authModal: '#FFF',
  goalMilestone: '#A57F24',
}

export const dark = {
  ...colors,
  ...themeConstants,
  isDark: true,
  backgroundColor: '#232628',
  backgroundAccent: '#1F2124',
  borderColor: 'rgba(238, 241, 244, 0.1)',
  input: { borderColor: '#535B62', checkboxColor: '#FFF' },
  cardShadow: `box-shadow: 0 1px 0 0 ${colors.black}`,
  tableAlt: 'rgba(238, 241, 244, 0.03)',
  modalOverlay: 'rgba(31, 33, 36, 0.9)',
  lightBlue: colors.linkBlue,
  textColor: colors.darkTextColor,
  danger: '#FF2121',
  toggleColor: colors.darkTextColor,
  donate: '#46C618',
  donateColor: '#232628',
  phoneNumberDropdownBackground: '#232628',
  phoneNumberDropdownBorder: '#535B62',
  logoColor: colors.darkTextColor,
  progressBackground: 'rgba(238,241,244,.1)',
  headerProgressBackground: colors.darkTextColor,
  authModal: colors.darkGrey,
}
