import { useContext, useEffect, useState } from 'react'

import { useIntl } from 'react-intl'
import styled, { ThemeContext } from 'styled-components'
import { rem } from 'polished'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { getCampaignUrl } from '../../utils/tiltifyUrl'
import { uppercaseMessages } from '../../translations'
import { Avatar } from '../Avatar'
import { ProgressBar as PB } from '../ProgressBar'
import { Currency } from '../Currency'
import { NewStreamCard } from '../StreamCard/NewStreamCard'
import { TiltifyLink } from '../TiltifyLink'
import { useReadableColor } from '../../hooks/useReadableColor'

const UserAvatar = styled(Avatar)`
  position: absolute;
  opacity: 0;
  left: 50%;
  margin-left: ${({ width }) => -(width / 2)}px;
  transition: all 0.2s ease-in-out;
  transform: translateY(-0.8rem);
  border-radius: ${({ borderRadius, width }) =>
    `${borderRadius > width / 2 ? borderRadius / 2 : borderRadius}px`};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    opacity: 1;
    bottom: -2rem;
  }
`

const StyledCampaignCard = styled(({ ...rest }) => {
  return <TiltifyLink {...rest} />
})`
  display: block;
  text-decoration: none;
  background-color: ${({ theme }) => theme.backgroundAccent};
  width: 100%;
  max-width: 592px;
  min-height: 300px;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  ${({ hasBorder }) => hasBorder && 'border: 1px solid #DDDBE8'};
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    display: block;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
    && div div img:first-of-type {
      transform: scale(1.02);
    }
  }
  &:hover ${UserAvatar} {
    opacity: 1;
    transform: translateY(-2rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`

const StyledLive = styled.div<{ borderRadius: number; showStatus: boolean; status: string }>`
  box-sizing: border-box;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 8px;
  margin-left: 8px;
  position: absolute;
  z-index: 2;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  min-width: 38px;
  height: 18px;
  background-color: ${({ showStatus, status, theme }) =>
    showStatus ? (status === 'published' ? theme.donate : '#535B62') : '#cc1919'};
  text-align: center;
  color: #fff;
  font-size: ${rem(12)};
  font-weight: bold;
  text-transform: uppercase;
`

const StyledStreamWrapper = styled.div`
  position: relative;
  justify-content: center;
`

const ProgressBarWrapper = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(11)};
`

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${rem(3)};
  font-size: 0.875rem;
`

const StyledProgressBar = styled(PB)`
  margin: 0 0 8px 0;
  min-height: ${rem(6)};
  padding-bottom: 0;
  width: 100%;
  border: none;
`

const AmountWrapper = styled.div<{ amountColor?: string; amountSize?: string }>`
  color: ${({ theme, amountColor }) => amountColor || theme.textColor};
  display: flex;
  flex-direction: column;
  line-height: 1.15;
  text-align: left;
  font-size: ${({ amountSize }) => rem(parseInt(amountSize || '16'))};
  font-weight: bold;
`

const StyledDescription = styled.div`
  text-align: left;
`

const StyledUserName = styled.div<{
  campaignCardHeaderFontSize?: number
  campaignCardCNFontFamily?: string
  textColor?: string
}>`
  font-size: ${({ campaignCardHeaderFontSize }) => rem(campaignCardHeaderFontSize || 16)};
  font-weight: ${({ campaignCardCNFontFamily }) => (campaignCardCNFontFamily ? 'bold' : 'normal')};
  color: ${({ theme, textColor }) => textColor || theme.textColor};
  letter-spacing: 0;
  line-height: 1rem;
  margin-bottom: ${rem(7)};
`

const StyledCauseName = styled.div`
  font-size: ${rem(14)};
  color: #535b62;
  margin-bottom: 15px;
`

const StyledCampaignName = styled.div<{
  campaignCardCNFontSize?: number
  campaignCardCNFontFamily?: string
  textColor?: string
}>`
  font-size: ${({ campaignCardCNFontSize }) => rem(campaignCardCNFontSize || 16)};
  font-weight: ${({ campaignCardCNFontFamily }) => (campaignCardCNFontFamily ? 'normal' : 'bold')};
  color: ${({ theme, textColor }) => textColor || theme.textColor};
  line-height: ${rem(16)};
  ${({ campaignCardCNFontFamily }) =>
    campaignCardCNFontFamily &&
    `
    font-family: ${campaignCardCNFontFamily};
  `}
`

const StyledTeamName = styled.div`
  font-size: ${rem(14)};
  color: #535b62;
  margin-top: 15px;
`

const BottomContentWrapper = styled.div`
  padding: 0 ${rem(16)} ${rem(16)} ${rem(16)};
`

export const CampaignCard = ({
  campaign,
  hideCause,
  hideAvatar,
  hideUser,
  showTeam,
  amountColor,
  amountSize,
  textColor,
  progressBackground,
  progressForeground,
  localLink,
  customLink,
  showStatus,
  loading,
  isDark,
  borderRadius,
  campaignCardHeaderFontSize,
  campaignCardCNFontSize,
  campaignCardCNFontFamily,
  progressBarStyle,
  hasBorder,
  ...rest
}: any) => {
  const { formatMessage } = useIntl()
  const themeContext = useContext(ThemeContext)
  const [raised, setRaised] = useState(0)
  const [currency, setCurrency] = useState('USD')
  const [percent, setPercent] = useState(0)
  const statusText =
    campaign?.status === 'published'
      ? formatMessage(uppercaseMessages.published)
      : formatMessage(uppercaseMessages.unpublished)

  const live = campaign?.live
  const name = campaign?.name
  const userName = campaign?.team?.name || campaign?.user?.username
  const userAvatar =
    campaign?.avatar?.src || campaign?.team?.avatar?.src || campaign?.user?.avatar?.src
  const url = getCampaignUrl(campaign, true)
  const progressForegroundColor = progressForeground || themeContext.donate
  const readableProgressBar = useReadableColor(
    themeContext.backgroundAccent,
    progressForegroundColor,
    1
  )

  useEffect(() => {
    if (campaign) {
      const { totalAmountRaised, goal } = campaign

      setRaised(totalAmountRaised?.value)
      setCurrency(totalAmountRaised?.currency)

      let width =
        parseFloat(totalAmountRaised?.value) > 0 && parseFloat(goal?.value)
          ? parseFloat(totalAmountRaised.value) / parseFloat(goal.value)
          : 0

      if (width > 1) width = 1

      setPercent(width)
    }
  }, [campaign])

  if (loading) {
    return (
      <SkeletonTheme baseColor={isDark ? '#232628' : '#d4dce466'} highlightColor={isDark && '#444'}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <StyledCampaignCard hasBorder={hasBorder} borderRadius={borderRadius} as='div'>
          <StyledStreamWrapper>
            <NewStreamCard loading={loading} campaign={campaign} />
          </StyledStreamWrapper>
          <BottomContentWrapper>
            <ProgressBarWrapper>
              <TotalContainer>
                <AmountWrapper>
                  <Skeleton />
                </AmountWrapper>
              </TotalContainer>
              <Skeleton />
            </ProgressBarWrapper>
            <StyledDescription>
              <StyledUserName>
                <Skeleton />
              </StyledUserName>
              <StyledCauseName>
                <Skeleton />
              </StyledCauseName>
              <StyledCampaignName>
                <Skeleton />
              </StyledCampaignName>
            </StyledDescription>
          </BottomContentWrapper>
        </StyledCampaignCard>
      </SkeletonTheme>
    )
  }

  return (
    <StyledCampaignCard
      borderRadius={borderRadius}
      hasBorder={hasBorder}
      localLink={localLink}
      url={customLink || url}
      {...rest}
    >
      <StyledStreamWrapper>
        {showStatus || live ? (
          <StyledLive showStatus={showStatus} status={campaign?.status} borderRadius={borderRadius}>
            {showStatus ? statusText : formatMessage(uppercaseMessages.live)}
          </StyledLive>
        ) : null}
        <NewStreamCard campaign={campaign} borderRadius={borderRadius} />
        {!hideAvatar && userAvatar && (
          <UserAvatar
            borderRadius={borderRadius}
            src={userAvatar}
            height={46}
            width={46}
            shape='square'
          />
        )}
      </StyledStreamWrapper>
      <BottomContentWrapper>
        <ProgressBarWrapper>
          <TotalContainer>
            <AmountWrapper amountSize={amountSize} amountColor={amountColor}>
              <Currency currency={currency} value={raised} />
            </AmountWrapper>
          </TotalContainer>
          <StyledProgressBar
            radius={borderRadius}
            background={progressBackground}
            foreground={readableProgressBar}
            percent={percent}
            progressBarStyle={progressBarStyle}
          />
        </ProgressBarWrapper>
        <StyledDescription>
          {!hideUser && (
            <StyledUserName
              campaignCardHeaderFontSize={campaignCardHeaderFontSize}
              campaignCardCNFontFamily={campaignCardCNFontFamily}
              textColor={textColor}
            >
              {userName}
            </StyledUserName>
          )}
          {!hideCause && <StyledCauseName>{campaign.cause.name}</StyledCauseName>}
          <StyledCampaignName
            campaignCardCNFontFamily={campaignCardCNFontFamily}
            campaignCardCNFontSize={campaignCardCNFontSize}
            textColor={textColor}
          >
            {name}
          </StyledCampaignName>
          {showTeam && <StyledTeamName>{campaign.team.name}</StyledTeamName>}
        </StyledDescription>
      </BottomContentWrapper>
    </StyledCampaignCard>
  )
}

CampaignCard.defaultProps = {
  hideAvatar: false,
  hideCause: true,
  hideUser: false,
  showTeam: false,
  progressBackground: '#DDDBE8',
  localLink: false,
  borderRadius: 6,
  campaignCardHeaderFontSize: 16,
  campaignCardCNFontSize: 14,
  amountSize: 14,
  hasBorder: false,
}
