import styled from 'styled-components'

const firstColor = (palette: any) => {
  if (!palette) return ''
  return (
    `${palette.vibrant}40` ||
    `${palette.lightvibrant}40` ||
    `${palette.darkVibrant}40` ||
    `${palette.muted}40` ||
    `${palette.lightMuted}40` ||
    `${palette.darkMuted}40` ||
    '#222222'
  )
}

export const StyledStreamEmpty = styled.div<{ borderRadius?: string; palette?: string }>`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  border-radius: ${({ borderRadius }) => `${borderRadius}px ${borderRadius}px 0px 0px`};
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  background-color: ${({ palette }) => firstColor(palette)};
  transition: all 0.2s ease-in;
`
