import { readableColor, rem } from 'polished'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

import { Loading } from '../Loading'

type SizeType = Record<
  string,
  {
    'font-size'?: string
    'line-height': string
    padding: string
    'border-radius': string
    'letter-spacing': string
    'font-weight'?: string
  }
>

const SIZE: SizeType = {
  small: {
    'font-size': rem(14),
    'line-height': '.75rem',
    padding: `${rem(10)} ${rem(12)}`,
    'border-radius': '2px',
    'letter-spacing': '.06rem',
  },
  medium: {
    'line-height': rem(14),
    padding: `${rem(12)} ${rem(20)}`,
    'border-radius': '3px',
    'letter-spacing': '.05rem',
  },
  large: {
    'line-height': rem(14),
    padding: `${rem(16)} ${rem(24)}`,
    'border-radius': '4px',
    'letter-spacing': '.05rem',
  },
  extraLarge: {
    'font-size': rem(18),
    'line-height': rem(18),
    padding: `${rem(16)} ${rem(36)}`,
    'border-radius': '4px',
    'font-weight': 'bold',
    'letter-spacing': '.05rem',
  },
}

const computeColor = ({ theme, intent, outline }: any) => {
  if (outline) {
    if (theme[intent]) return theme[intent]
    return readableColor(theme.backgroundColor || '#000')
  }

  if (!outline && theme[intent]) return '#FFF'

  return readableColor(theme.backgroundColor || '#000')
}

const computeBorderColor = ({ theme, intent, outline }: any) => {
  if (theme[intent]) return theme[intent]
  if (outline) return readableColor(theme.backgroundColor || '#000')
  return 'transparent'
}

const computeBackgroundColor = ({ theme, outline, intent }: any) => {
  if (outline) {
    return 'transparent'
  }

  if (theme[intent]) {
    return theme[intent]
  }

  return 'transparent'
}

const StyledButton = styled.button<{ size: string }>`
  display: inline-block;
  text-decoration: none;
  padding: 0;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${(props) => computeColor(props)};
  background-color: ${(props) => computeBackgroundColor(props)};
  border: 2px solid ${(props) => computeBorderColor(props)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  -webkit-appearance: none !important;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : null)}
  ${({ size }) => SIZE[size]};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const Icon = styled.span<{ direction: string; size: string }>`
  display: inline-block;
  vertical-align: middle;
  padding-left: ${({ direction }) => (direction === 'right' ? '1rem' : '0')};
  padding-right: ${({ direction }) => (direction === 'left' ? '1rem' : '0')};

  svg {
    height: ${({ size }) => (SIZE[size] ? SIZE[size]['font-size'] : '1rem')};
    width: ${({ size }) => (SIZE[size] ? SIZE[size]['font-size'] : '1rem')};
  }
`

const LoadingSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledLoading = styled(Loading)<{ size: string }>`
  height: ${({ size }) => (SIZE[size] && SIZE[size]['font-size']) || '1rem'};
  width: ${({ size }) => (SIZE[size] && SIZE[size]['font-size']) || '1rem'};
  circle {
    stroke: ${(props) => computeColor(props)}!important;
  }
`

const ContentSpan = styled.span<{ $loading: boolean }>`
  width: 100%;
  opacity: ${({ $loading }) => ($loading ? '0' : '1')};
`

export const Button = forwardRef(
  (
    {
      intent,
      size = 'small',
      outline,
      disabled,
      iconRight,
      iconLeft,
      children,
      onClick,
      loading,
      ariaLabel,
      ...props
    }: any,
    ref
  ) => {
    const localRef = useRef()
    useImperativeHandle(ref, () => localRef.current)

    const ariaLabelValue = loading ? 'loading' : ariaLabel
    return (
      <StyledButton
        disabled={disabled}
        intent={intent}
        size={size}
        outline={outline}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
        {...props}
        aria-label={ariaLabelValue}
        ref={localRef}
      >
        <div style={{ position: 'relative' }}>
          <ContentSpan $loading={loading}>
            {iconLeft && (
              <Icon direction='left' size={size}>
                {iconLeft}
              </Icon>
            )}
            {children}
            {iconRight && (
              <Icon direction='right' size={size}>
                {iconRight}
              </Icon>
            )}
          </ContentSpan>
          {loading && (
            <LoadingSpan>
              <StyledLoading intent={intent} outline={outline} focusable='false' />
            </LoadingSpan>
          )}
        </div>
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

// Button.propTypes = {
//   /**
//    * Changes the background color of the button
//    */
//   intent: oneOf(['primary', 'danger', 'donate', 'lightBlue']),
//   /**
//    * Determines the size of the button
//    */
//   size: oneOf(['small', 'medium', 'large', 'extraLarge']),
//   /**
//    * Is it a solid button or just an outline
//    */
//   outline: bool,
//   /**
//    * Disables the button and adds opacity
//    */
//   disabled: bool,
//   iconRight: oneOfType([object, string, func, array]),
//   iconLeft: oneOfType([object, string, func, array]),
//   children: oneOfType([object, string, func, array]),
//   onClick: func,
//   /**
//    * Renders a loading animation
//    */
//   loading: bool,
//   ariaLabel: string,
// }
