import { ApolloError } from '@apollo/client'

interface IRouteData {
  cause?: TiltifyPublicCause
  loading: boolean
  error?: ApolloError | string
}

export const formatQueryData = (
  cause: TiltifyPublicCause,
  loading: boolean,
  error?: ApolloError
): IRouteData => {
  let routeData = {
    cause: undefined,
  } as IRouteData

  if (loading) {
    routeData = { ...routeData, loading: true }
  } else if (cause) {
    routeData = { ...routeData, cause }
  } else {
    routeData = { ...routeData, loading: false, error: error || 'Invalid Route' }
  }

  return routeData
}

export const filterRewards = (rewards: TiltifyPublicReward[]): TiltifyPublicReward[] => {
  return rewards
    .filter((reward: TiltifyPublicReward) => reward.active)
    .filter((reward: TiltifyPublicReward) => {
      return !reward.deleted
    })
}
