import styled from 'styled-components/macro'
import { rem } from 'polished'

import { SingleIncentive } from './SingleIncentive'
import { MaxWidthWrapper, StyledHeading, AccentBar, StyledButton } from '../styles'
import Tote from 'assets/incentives/tote.jpg'
import Backpack from 'assets/incentives/backpack.jpg'
import Speaker from 'assets/incentives/speaker.jpg'

const IncentiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(30)};

  && div:nth-of-type(2) {
    margin-left: ${rem(25)};
    margin-right: ${rem(25)};
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }

  @media (max-width: 400px) {
    && div:nth-of-type(2) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const LightBlueButton = styled(StyledButton)`
  color: #fff;
  border-color: #fff;
  background-color: ${({ theme }) => theme.lightBlue};
  text-decoration: none;
  transition: background-color 0.5s ease;

  && :hover {
    background: #008dba;
  }
`

const DescWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: coluimn;
  align-items: center;
`
const Description = styled.p`
  text-align: center;
  line-height: 1.2rem;
  margin-bottom: 2rem;
  margin-top: 0;
  max-width: 60%;
  color: ${({ theme }) => theme.headingBlue};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    max-width: 100%;
  }
`

export const Incentives = ({
  setIsVisible,
}: {
  setIsVisible: (value: boolean) => void
}): JSX.Element => {
  return (
    <MaxWidthWrapper>
      <StyledHeading>Fundraiser incentives</StyledHeading>
      <AccentBar />
      <DescWrapper>
        <Description>
          As a thank you for supporting the Humane Society of the United States, we'd like to reward
          your fundraising success with special incentives. Prizes are cumulative, so the more you
          raise, the more you earn!
        </Description>
        {/* <Description>
          Raise $250 or more and earn a Reclaim&#8482; 100% recycled zipper tote.
        </Description>
        <Description>
          Raise $500 or more and earn the Reclaim&#8482; zipper tote and a Koozie&#174; backpack
          cooler chair. Durable steel frame and folds down easily to become a backpack. Outfitted
          with a waterproof interior cooler. Comes with attached bottle opener.
        </Description>
        <Description>
          Raise $1000 or more and earn the Reclaim&#8482; zipper tote, Koozie&#174; backpack cooler
          chair and Waterproof 360 speaker. Indoor/outdoor waterproof 10-watt Bluetooth speaker with
          360 degree sound. Built-in microphone for hands free calls. Operating range 33 ft.
        </Description> */}
      </DescWrapper>
      <IncentiveWrapper>
        <SingleIncentive
          title='Reclaim&#8482; 100% recycled zipper tote'
          img={Tote}
          amount={250}
          imageWidth={350}
        />
        <SingleIncentive title='Koozie&#174; backpack cooler chair' img={Backpack} amount={500} />
        <SingleIncentive
          title='Waterproof 360 speaker with built-in microphone'
          img={Speaker}
          amount={1000}
        />
      </IncentiveWrapper>
      <ButtonContainer>
        <LightBlueButton outline={false} onClick={() => setIsVisible(true)}>
          Start Fundraising
        </LightBlueButton>
      </ButtonContainer>
    </MaxWidthWrapper>
  )
}
