import { useEffect, useState } from 'react'

export const useDebounce = (value: any, delay: any) => {
  const [debounceValue, setDebounceValue] = useState(value)

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)
    return () => {
      clearTimeout(timeoutHandler)
    }
  }, [value])

  return debounceValue
}
