import { FormattedNumber } from 'react-intl'

const getProps = (props: any) => {
  switch (props.currency) {
    case 'JPY':
      return { ...props, ...{ minimumFractionDigits: 0, maximumFractionDigits: 0 } }
    default:
      return props
  }
}

export const Currency = (props: any) => {
  return <FormattedNumber {...getProps(props)} />
}

Currency.defaultProps = {
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  value: 0,
}
