import styled from 'styled-components/macro'
import { rem } from 'polished'

import { getSubdomainUrl, baseTiltifyUrl } from '@tiltify/ui/utils'
import { useRouteContext } from 'contexts/RouteContext'
import { StyledHeading, MaxWidthWrapper, StyledButton, LightBlueButton } from 'components/styles'
import { Instagram } from 'assets/Instagram'
import { DiscordSimple, FacebookSimple, Twitter, Youtube } from '@tiltify/ui/icons'
import Logo from 'assets/Logo.png'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.darkBlue};
  text-align: center;
`

const SocialHeading = styled(StyledHeading)`
  color: ${({ theme }) => theme.whiteText};
  margin-top: 0;
  margin-bottom: ${rem(32)};
  font-size: ${rem(40)};
  font-weight: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(20)};
  }
`

const ButtonContainer = styled.div`
  margin-bottom: ${rem(48)};

  @media (max-width: 644px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const RedButton = styled(StyledButton)`
  background: ${({ theme }) => theme.red};
  border-color: ${({ theme }) => theme.red};
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
  && :hover {
    background: #c83021;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(30)} ${rem(55)} ${rem(55)} ${rem(55)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 1rem;
  }
`

const LogoWrapper = styled.a`
  margin-bottom: ${rem(26)};
`

const DiscordMessage = styled.div`
  color: ${({ theme }) => theme.whiteText};
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  a {
    color: ${({ theme }) => theme.lightBlue};
    font-weight: bold;
  }

  svg {
    margin-right: 1rem;
  }

  @media (max-width: 642px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;

    svg {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`

const SocialButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 237px;
  margin-top: ${rem(27)};
`

const SocialButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  border: solid #fff 1px;
  height: 48px;
  width: 48px;

  && svg {
    fill: ${({ theme }) => theme.lightBlue};
    height: 24px;
    width: 24px;
  }

  && path {
    fill: ${({ theme }) => theme.lightBlue};
  }

  && path:nth-child(2) {
    fill: ${({ theme }) => theme.darkBlue};
  }

  && rect {
    fill: ${({ theme }) => theme.lightBlue};
  }
`

const DiscordLink = styled.a`
  display: flex;
  margin-left: 0.4rem;

  @media (max-width: 640px) {
    margin-left: 0;
  }
`

const Image = styled.img`
  width: 306px;
  @media (max-width: 450px) {
    width: 240px;
  }
`

export const Social = ({
  setIsVisible,
}: {
  setIsVisible: (value: boolean) => void
}): JSX.Element | null => {
  const { cause } = useRouteContext()

  if (!cause) return null

  const { facebook, twitter, youtube, instagram } = cause.social
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Container>
          <SocialHeading>Join the fight for all animals</SocialHeading>
          <ButtonContainer>
            <LightBlueButton outline={false} onClick={() => setIsVisible(true)}>
              Start Fundraising
            </LightBlueButton>
            <RedButton
              as='a'
              href={getSubdomainUrl(`${baseTiltifyUrl()}/${cause?.causeFactPublicId}`, 'donate')}
              target='_blank'
              rel='noopenner noreferrer'
            >
              Donate
            </RedButton>
          </ButtonContainer>
          <LogoWrapper
            href='https://www.humanesociety.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image src={Logo} />
          </LogoWrapper>
          <DiscordMessage>
            <DiscordSimple height='25px' width='25px' fill='#FFF' />
            Join our community at{' '}
            <DiscordLink
              href='https://discordapp.com/invite/CT9Msra'
              target='_blank'
              rel='noopener noreferrer'
            >
              discord.gg/hsus
            </DiscordLink>
          </DiscordMessage>
          <SocialButtonWrapper>
            <SocialButton
              href={`https://www.facebook.com/${facebook}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FacebookSimple />
            </SocialButton>
            <SocialButton
              href={`https://twitter.com/${twitter}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Twitter />
            </SocialButton>
            <SocialButton
              href={`https://www.youtube.com/channel/${youtube}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Youtube />
            </SocialButton>
            <SocialButton
              href={`https://www.instagram.com/${instagram}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Instagram />
            </SocialButton>
          </SocialButtonWrapper>
        </Container>
      </MaxWidthWrapper>
    </Wrapper>
  )
}
