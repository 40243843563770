import { defineMessages } from 'react-intl'

export const achievementMessages = defineMessages({
  topFundraiserAchievement: {
    id: 'Achievement.TopFundraiserAchievement',
    defaultMessage: 'Top Fundraiser',
    description: 'Label for badges where the user was a top fundraiser for the year',
  },
  lifetimeAchievement: {
    id: 'Achievement.LifetimeAchievement',
    defaultMessage: 'Lifetime Fundraising',
    description: 'Label for badges that are for the lifetime of their account',
  },
  customAchievement: {
    id: 'Achievement.CustomAchiement',
    defaultMessage: 'Cause Fundraising',
    description: 'Label for badges that are for the cause specific achievements their account',
  },
  lifetime1Mil: {
    id: 'Achievement.Lifetime1Mil',
    defaultMessage: '$1,000,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime10k: {
    id: 'Achievement.Lifetime10k',
    defaultMessage: '$10,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime25k: {
    id: 'Achievement.Lifetime25k',
    defaultMessage: '$25,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime50k: {
    id: 'Achievement.Lifetime50k',
    defaultMessage: '$50,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime100k: {
    id: 'Achievement.Lifetime100k',
    defaultMessage: '$100,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime250k: {
    id: 'Achievement.Lifetime250k',
    defaultMessage: '$250,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime500k: {
    id: 'Achievement.Lifetime500k',
    defaultMessage: '$500,000 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  lifetime7500: {
    id: 'Achievement.Lifetime7500',
    defaultMessage: '$7,500 raised',
    description: 'Label for badge earned at lifetime amount raised of ',
  },
  yOY2016: {
    id: 'Achievement.YOY2016',
    defaultMessage: '2016 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2017: {
    id: 'Achievement.YOY2017',
    defaultMessage: '2017 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2018: {
    id: 'Achievement.YOY2018',
    defaultMessage: '2018 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2019: {
    id: 'Achievement.YOY2019',
    defaultMessage: '2019 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2020: {
    id: 'Achievement.YOY2020',
    defaultMessage: '2020 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2021: {
    id: 'Achievement.YOY2021',
    defaultMessage: '2021 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  yOY2022: {
    id: 'Achievement.YOY2022',
    defaultMessage: '2022 Top 300 Fundraiser',
    description: 'Label for badge earned for being a top fundraiser during the year of ',
  },
  stJude351: {
    id: 'Achievement.StJude351',
    defaultMessage: 'St. Jude Fundraiser',
    description: 'Label for badge earned for raising money for St. Jude',
  },
  thankmas: {
    id: 'Achievement.Thankmas',
    defaultMessage: 'Thankmas Fundraiser',
    description: 'Label for badge earned for raising money for Thankmas',
  },
})

export const authenticationMessages = defineMessages({
  birthday: {
    id: 'Authentication.SignUp.Birthday',
    defaultMessage: 'Birthday',
    description: 'Label for the birthday sign up field.',
  },
  TOS: {
    id: 'Authentication.SignUp.TOS',
    defaultMessage:
      'By signing up, you agree to our <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>',
    description: 'Terms of Service messages displayed to user during sign up.',
  },
  invalidEmailOrPassword: {
    id: 'Authentication.Login.InvalidEmailOrPassword',
    defaultMessage: 'Email or password is invalid.',
    description: 'Error text when login email or password is invalid',
  },
  internalServerError: {
    id: 'Authentication.Login.InternalServerError',
    defaultMessage: 'Internal server error',
    description: 'Error text when server is unreachable',
  },
  emailPlaceholder: {
    id: 'EmailInput.EmailPlaceholder',
    defaultMessage: 'email@domain.com',
    description: 'The text for the email input placeholder.',
  },
  passwordLabel: {
    id: 'PasswordInput.Password',
    defaultMessage: 'Password',
    description: 'The label for the password input field.',
  },
  passwordConfirmation: {
    id: 'PasswordConfirmationInput.Password',
    defaultMessage: 'Password confirmation',
    description: 'The label for the password confirmation input field.',
  },
  forgot: {
    id: 'Authentication.Login.Forgot',
    defaultMessage: 'Forgot your password?',
    description: 'The button text to reset password.',
  },
  forgotPassword: {
    id: 'Authentication.Login.ForgotPassword',
    defaultMessage: 'Forgot password?',
    description: 'The link text to go to the forgot password page.',
  },
  resetPassword: {
    id: 'Authentication.ResetPassword',
    defaultMessage: 'reset password',
    description: 'The button text to reset password.',
  },
  confirmationInstructions: {
    id: 'Authentication.ConfirmationInstructions',
    defaultMessage: 'Resend confirmation instructions',
    description: 'The header text for the resend email confirmation page.',
  },
  emailConfirmed: {
    id: 'Authentication.EmailConfirmed',
    defaultMessage: 'The email address has been confirmed.',
    description: 'The status text when successfully confirming an email.',
  },
  resend: {
    id: 'Authentication.ResendEmail',
    defaultMessage: 'Resend',
    description: 'The button text to resend a confirmation email.',
  },
  login: {
    id: 'Authentication.Login.LogIn',
    defaultMessage: 'log In',
    description: 'The button text to login to the website.',
  },
  loginQuestion: {
    id: 'Authentication.Login.LogInQuestion',
    defaultMessage: 'Log in?',
    description: 'The button text to login to the website.',
  },
  new: {
    id: 'Authentication.Login.New',
    defaultMessage: 'Not on Tiltify yet?',
    description: 'Text for signup flow change.',
  },
  request: {
    id: 'Authentication.Login.Request',
    defaultMessage: 'Request an account',
    description: 'The button text to request an account.',
  },
  already: {
    id: 'Authentication.Signup.Already',
    defaultMessage: 'Already have an account?',
    description: 'text for the signin flow change',
  },
  subheading1: {
    id: 'Authentication.Signup.Subheading1',
    defaultMessage:
      'Only official representatives from a charitable organization are authorized to sign up a charity on Tiltify. For your charity to be approved, please register with your official organization email and provide evidence of charitable status in your home country.',
    description: 'Subheading 1 for cause signup.',
  },
  subheading2: {
    id: 'Authentication.Signup.Subheading2',
    defaultMessage:
      '<a>If you are a content creator and the charity you are interested in fundraising for is not on the Tiltify platform, please reach out to that charity and encourage them to sign up. Content creators interested in fundraising for an organization already on Tiltify, can </a><b>sign up here.</b>',
    description: 'Subheading 2 for cause signup.',
  },
  signin: {
    id: 'Authentication.Signup.Signin',
    defaultMessage: 'Sign in now',
    description: 'Button for signin flow change',
  },
  thankYouSignUp: {
    id: 'Authentication.Signup.ThankYou',
    defaultMessage: "Thank you! We'll be in touch soon",
    description: 'Text displayed on the sign up confirmation page.',
  },
  changePassword: {
    id: 'MyAccount.ChangePassword',
    defaultMessage:
      'An email will be sent to the address associated with this account with details on how to proceed.',
    description: 'Description for change password button',
  },
  changePasswordLabel: {
    id: 'MyAccount.ChangePasswordLabel',
    defaultMessage: 'change password',
    description: 'Description for change password button',
  },
  userUpdated: {
    id: 'MyAccount.UserUpdated',
    defaultMessage: 'User updated',
    description: 'STatus text when user updated succesfully',
  },
  expiredtoken: {
    id: 'MyAccount.Expiredtoken',
    defaultMessage: 'The password reset token has expired.',
    description: 'Status text when password token has expired',
  },
  signUpNow: {
    id: 'CauseHome.SignUpNow',
    defaultMessage: 'sign up now',
    description: 'Label for sign up now button',
  },
  signUpHere: {
    id: 'CauseHome.SignUpHere',
    defaultMessage: 'sign up here',
    description: 'Label for sign up here button',
  },
  signUp: {
    id: 'CauseHome.SignUp',
    defaultMessage: 'sign up',
    description: 'Label for sign up here button',
  },
  createAccount: {
    id: 'Authentication.SignUp.Create',
    defaultMessage: 'create account',
    description: 'The button text to create a new account.',
  },
  createYourAccount: {
    id: 'Authentication.SignUp.CreateYourAccount',
    defaultMessage: 'Create your account',
    description: 'The heading text to create a new account.',
  },
  createYourAccountDescription: {
    id: 'Authentication.SignUp.CreateYourAccountDescription',
    defaultMessage:
      'Creating an account allows you to track donations and make future donations quicker and easier to complete.',
    description: 'The description text to create a new account.',
  },
  becomeAFundraiser: {
    id: 'Authentication.BecomeAFundraiser',
    defaultMessage: 'Become a Fundraiser!',
    description: 'Heading text explaining what a fundraiser is.',
  },
  becomeAFundraiserConfirmation: {
    id: 'Authentication.BecomeAFundraiserConfirmation',
    defaultMessage: 'become a fundraiser',
    description: 'Heading text explaining what a fundraiser is.',
  },
  becomeAFundraiserDescription: {
    id: 'Authentication.BecomeAFundraiserDescription',
    defaultMessage:
      "You too can become a fundraiser on Tiltify! You can skip all this for now and fill it in later if you want to, or not, it's completely up to you.",
    description: 'Description text explaining what a fundraiser is.',
  },
  becomeAFundraiserShortDescription: {
    id: 'Authentication.BecomeAFundraiserShortDescription',
    defaultMessage:
      'To continue creating a campaign, you must upgrade your account to unlock all the features available to Tiltify Fundraisers.',
    description: 'Description text explaining what a fundraiser is.',
  },
  becomeAFundraiserShortDescriptionTeam: {
    id: 'Authentication.BecomeAFundraiserShortDescriptionTeam',
    defaultMessage:
      'To continue joining a team, you must upgrade your account to unlock all the features available to Tiltify Fundraisers.',
    description: 'Description text explaining what a fundraiser is.',
  },
  becomeAFundraiserAction: {
    id: 'Authentication.BecomeAFundraiserACtion',
    defaultMessage: 'upgrade account',
    description: 'Button text to become a fundraiser.',
  },
  continueTwitter: {
    id: 'Authentication.ContinueTwitter',
    defaultMessage: 'Continue with Twitter',
    description: 'Button text to login with Twitter.',
  },
  continueFacebook: {
    id: 'Authentication.ContinueFacebook',
    defaultMessage: 'Continue with Facebook',
    description: 'Button text to login with Facebook.',
  },
  continueTwitch: {
    id: 'Authentication.ContinueTwitch',
    defaultMessage: 'Continue with Twitch',
    description: 'Button text to login with Twitch.',
  },
  continueGoogle: {
    id: 'Authentication.ContinueGoogle',
    defaultMessage: 'Continue with Google',
    description: 'Button text to login with Google.',
  },
  continueDiscord: {
    id: 'Authentication.ContinueDiscord',
    defaultMessage: 'Continue with Discord',
    description: 'Button text to login with Discord.',
  },
  continueStrava: {
    id: 'Authentication.ContinueStrava',
    defaultMessage: 'Continue with Strava',
    description: 'Button text to login with Strava.',
  },
  createAnAccount: {
    id: 'Authentication.CreateAnAccount',
    defaultMessage: 'Create an account',
    description: 'Button text to create a new account.',
  },
  causeLogin: {
    id: 'Authentication.CauseLogin',
    defaultMessage: 'Looking to sign in as a cause?',
    description: 'Label text to sign in as a cause.',
  },
  causeSignIn: {
    id: 'Authentication.CauseSignIn',
    defaultMessage: 'CAUSE SIGN IN',
    description: 'Button text to sign in as a cause.',
  },
  signInToContinue: {
    id: 'Authentication.SignInToContinue',
    defaultMessage: 'Sign in to continue',
    description: 'HEading text to sign in.',
  },
  fundraiserSignUpLabel: {
    id: 'Authentication.FundraiserSignUpLabel',
    defaultMessage: 'If you want to start fundraising or record your donations, start here',
    description: 'Label text to sign up as a fundraiser/donor.',
  },
  causeSignUpLabel: {
    id: 'Authentication.CauseSignUpLabel',
    defaultMessage: 'Looking to register as a cause?',
    description: 'Label text to sign up as a cause.',
  },
  fundraiserDonor: {
    id: 'Authentication.FundraiserDonor',
    defaultMessage: 'FUNDRAISER/DONOR',
    description: 'Button text to sign up as a fundraiser/donor.',
  },
  createACauseAccount: {
    id: 'Authentication.CreateACauseAccount',
    defaultMessage: 'CREATE A CAUSE ACCOUNT',
    description: 'Button text to sign up as a cause.',
  },
})

export const userMenuMessages = defineMessages({
  userAvatar: {
    id: 'UserMenu.Avatar',
    defaultMessage: 'User Avatar',
    description: 'Title for the user avatar image.',
  },
  signInText: {
    id: 'UserMenu.SignIn',
    defaultMessage: 'SIGN IN',
    description: 'Text for navbar sign in link',
  },
  signUpText: {
    id: 'UserMenu.SignUp',
    defaultMessage: 'SIGN UP',
    description: 'Text for navbar sign up link',
  },
  help: {
    id: 'UserMenu.Help',
    defaultMessage: 'help!!',
    description: 'Text for user menu help link',
  },
  dashboard: {
    id: 'UserMenu.Dashboard',
    defaultMessage: 'dashboard',
    description: 'Text for user menu dashboard link',
  },
  profile: {
    id: 'UserMenu.Profile',
    defaultMessage: 'profile',
    description: 'Text for user menu profile link',
  },
  signOut: {
    id: 'UserMenu.SignOut',
    defaultMessage: 'sign out',
    description: 'Text for user menu sign out link',
  },
  editCampaign: {
    id: 'UserMenu.EditCampaign',
    defaultMessage: 'edit campaign',
    description: 'Text for user menu edit campaign link',
  },
})

export const donateMessages = defineMessages({
  membership: {
    id: 'CampaignDonateFlow.Membership',
    defaultMessage: 'Membership',
    description: 'Text for the membership summary.',
  },
  acceptsCrypto: {
    id: 'CampaignDonateFlow.AcceptsCrypto',
    defaultMessage: 'Complete your donation using...',
    description: 'Heading Text for the donation type panel.',
  },
  pleaseNote: {
    id: 'CampaignDonateFlow.PleaseNote',
    defaultMessage: 'Please note:',
    description: 'Text for please note above crypto restrictions.',
  },
  cryptoP1: {
    id: 'CampaignDonateFlow.CryptoP1',
    defaultMessage:
      // eslint-disable-next-line
      'As determined by the cause, the minimum donation via cryptocurrency has been set at the equivalent of ${amount}. On the following page you will be asked to enter the monetary donation amount you wish to contribute followed by a page where you can select the actual cryptocurrency you would like to use.',
    description: 'Paragraph one of crypto restrictions.',
  },
  cryptoP2: {
    id: 'CampaignDonateFlow.CryptoP2',
    defaultMessage:
      'Certain time-limited rewards may not eligible when donating with cryptocurrency.',
    description: 'Paragraph two of crypto restrictions.',
  },
  cryptoDisclaimer: {
    id: 'CampaignDonateFlow.CryptoDisclaimer',
    defaultMessage:
      'Cryptocurrency donations are powered by our third party partner, the Giving Block.  Cryptocurrency donations are not refundable or reversible.',
    description: 'Disclaimer for crypto donation.',
  },
  donateUsing: {
    id: 'CampaignDonateFlow.DonateUsing',
    defaultMessage: 'Donate using:',
    description: 'Label for the payment methods list on donation type',
  },
  donate: {
    id: 'CampaignDonateFlow.Donate',
    defaultMessage: 'Donate',
    description: 'Text for the donate button.',
  },
  donated: {
    id: 'CampaignDonateFlow.Donated',
    defaultMessage: 'donated',
    description: 'Text for the donate button.',
  },
  justDonate: {
    id: 'CampaignDonateFlow.JustDonate',
    defaultMessage: 'Just donate',
    description: 'Text for the just donate button.',
  },
  next: {
    id: 'CampaignDonateFlow.Next',
    defaultMessage: 'next',
    description: 'Text for the next button.',
  },
  amount: {
    id: 'DonateFlow.Donate.Amount',
    defaultMessage: 'Amount',
    description: 'Label for the summary donation amount.',
  },
  signIn: {
    id: 'DonateFlow.Donate.SignIn',
    defaultMessage: '<a>Sign in</a> <b>or</b> <c>Sign up</c>',
    description: 'Text to sign in or sign up on the website.',
  },
  saveDetails: {
    id: 'DonateFlow.Donate.SaveDetails',
    defaultMessage: 'to save your donation details',
    description: 'Text explaining benefit of signing in or signing up on the website.',
  },
  yes: {
    id: 'DonateFlow.Donate.Yes',
    defaultMessage: 'Yes',
    description: 'Text agreeing to option.',
  },
  yesAction: {
    id: 'DonateFlow.Donate.YesAction',
    defaultMessage: 'yes',
    description: 'Text agreeing to option.',
  },
  no: {
    id: 'DonateFlow.Donate.No',
    defaultMessage: 'No',
    description: 'Text not agreeing to option.',
  },
  noAction: {
    id: 'DonateFlow.Donate.NoAction',
    defaultMessage: 'no',
    description: 'Text not agreeing to option.',
  },
  complete: {
    id: 'Create.Complete.Complete',
    defaultMessage: 'Donation Complete',
    description: 'The label displayed when a donation is complete.',
  },
  returnCampaign: {
    id: 'Create.Complete.ReturnCampaign',
    defaultMessage: 'return to campaign',
    description: 'The link text to return to the campaign.',
  },
  returnFundraisingEvent: {
    id: 'Create.Complete.ReturnFundraisingEvent',
    defaultMessage: 'Return to fundraising event',
    description: 'The link text to return to the fundraising event.',
  },
  returnCause: {
    id: 'Create.Complete.ReturnCause',
    defaultMessage: 'return to cause',
    description: 'The link text to return to the cause.',
  },
  singleFlow: {
    id: 'DonateFlow.SingleFlow',
    defaultMessage: 'switch to a single donation',
    description: 'Text for the single donation button.',
  },
  monthlyFlow: {
    id: 'DonateFlow.MonthlyFlow',
    defaultMessage: 'switch to a monthly donation',
    description: 'Text for the monthly giving button.',
  },
  processing: {
    id: 'Create.Processing.Processing',
    defaultMessage: 'Donation Processing',
    description: 'The label displayed when a donation is processing.',
  },
  share: {
    id: 'CampaignShare.Share',
    defaultMessage: 'Share',
    description: 'The label for social media links.',
  },
  causeAvatar: {
    id: 'DonateFlow.CauseAvatar',
    defaultMessage: '{cause} Avatar',
    description: 'The label for social media links.',
  },
  shareMessage: {
    id: 'CampaignShare.Message',
    defaultMessage: "Go check out {owner}'s fundraiser for {name} on {accountLink}",
    description: 'The message prepared by to send out through social media for campaign.',
  },
  shareMessagePersonal: {
    id: 'CampaignShare.MessagePersonal',
    defaultMessage: 'Go check out my fundraiser for {name} on {accountLink}',
    description: 'The message prepared by to send out through social media for campaign.',
  },
  shareMessageCause: {
    id: 'CampaignShare.MessageCause',
    defaultMessage: 'Go check out {name} on {accountLink} and help support a great cause!',
    description: 'The message prepared by to send out through social media for cause.',
  },
  shareMessageFE: {
    id: 'CampaignShare.MessageFE',
    defaultMessage: 'Go check out {owner} for {name} on {accountLink}',
    description: 'The message prepared by to send out through social media for FE.',
  },
  cancel: {
    id: 'DonateFlow.Donate.Cancel',
    defaultMessage: 'Your donation has been cancelled.',
    description: 'Text for cancelled payment heading.',
  },
  restart: {
    id: 'DonateFlow.Donate.Restart',
    defaultMessage: 'Restart',
    description: 'Text for the restart button.',
  },
  requiresLogin: {
    id: 'DonateFlow.Requires Login',
    defaultMessage: '(requires login)',
    description: 'Label text for login requires prompt',
  },
  closeTab: {
    id: 'DonateFlow.CloseTab',
    defaultMessage: 'You can now close this window',
    description: 'Label text for close message',
  },
})

export const layoutMessages = defineMessages({
  error404: {
    id: 'DonateFlow.Error404',
    defaultMessage: 'Sorry this page has moved.',
    description: 'The text displayed for a 404 error message.',
  },
  errorGraphQL403: {
    id: 'Error.GraphQLError403',
    defaultMessage: 'Not Authorized',
    description: 'The text displayed on receiving a GraphQL 403 error',
  },
  errorGraphQL404: {
    id: 'Error.GraphQLError404',
    defaultMessage: '{path} not found',
    description: 'The text displayed on receiving a GraphQL 404 error',
  },
  errorTitle: {
    id: 'DonateFlow.ErrorPage.Title',
    defaultMessage: 'Something Went Wrong.',
    description: 'The text displayed on the default error page.',
  },
  messageSent: {
    id: 'Success.MessageSent',
    defaultMessage: 'Message Sent',
    description: 'The text displayed on message sent notification',
  },
  signInText: {
    id: 'NavBar.SignIn',
    defaultMessage: 'Sign in',
    description: 'Text for navbar sign in link',
  },
  signUpText: {
    id: 'NavBar.SignUp',
    defaultMessage: 'Sign up',
    description: 'Text for navbar sign up link',
  },
  darkMode: {
    id: 'ThemeSwitcher.NightMode',
    defaultMessage: 'dark mode',
    description: 'Text for the dark toggle theme button.',
  },
  lightMode: {
    id: 'ThemeSwitcher.DayMode',
    defaultMessage: 'light mode',
    description: 'Text for the light toggle theme button.',
  },
  privacyPolicy: {
    id: 'LegalMenu.PrivacyPolicy',
    defaultMessage: 'Privacy policy',
    description: 'The menu item to view the privacy policy of the website.',
  },
  TOS: {
    id: 'LegalMenu.TOS',
    defaultMessage: 'Terms of service',
    description: 'The menu item to view the Terms of Service of the website.',
  },
  copyright: {
    id: 'LegalMenu.Copyright',
    defaultMessage: 'Copyright policy',
    description: 'The menu item to view the Copyright Policy of the website.',
  },
  blog: {
    id: 'DonateFlow.Footer.Blog',
    defaultMessage: 'Blog',
    description: 'The label for social media links.',
  },
  support: {
    id: 'DonateFlow.Footer.Support',
    defaultMessage: 'Support',
    description: 'The label for social media links.',
  },
  success: {
    id: 'Notification.Success',
    defaultMessage: 'Success',
    description: 'The heading for success notification.',
  },
  error: {
    id: 'Notification.Error',
    defaultMessage: 'Error',
    description: 'The heading for error notification.',
  },
  viewChat: {
    id: 'Frontend.CampaignShow.ViewChat',
    defaultMessage: 'view chat',
    description: 'Text for the view chat button',
  },
  hideChat: {
    id: 'Frontend.CampaignShow.HideChat',
    defaultMessage: 'hide chat',
    description: 'Text for the hide chat button',
  },
  charityPlatform: {
    id: 'LayoutMessages.CharityPlatform',
    defaultMessage: 'The #1 charity fundraising platform for content creators',
    description: 'Text describing our charity platform',
  },
})

export const navigationMessages = defineMessages({
  donationType: {
    id: 'SideBarLinks.DonationType',
    defaultMessage: 'Donation Type',
    description: 'Navigation option to view donation type.',
  },
  details: {
    id: 'SideBarLinks.Details',
    defaultMessage: 'Your Details',
    description: 'Navigation option to view donation details.',
  },
  rewards: {
    id: 'SideBarLinks.Rewards',
    defaultMessage: 'Rewards',
    description: 'Navigation option to view rewards.',
  },
  challenges: {
    id: 'SideBarLinks.Challenges',
    defaultMessage: 'Challenges',
    description: 'Navigation option to view challenges.',
  },
  targets: {
    id: 'SideBarLinks.Targets',
    defaultMessage: 'Targets',
    description: 'Navigation option to view targets.',
  },
  polls: {
    id: 'SideBarLinks.Polls',
    defaultMessage: 'Polls',
    description: 'Navigation option to view polls.',
  },
  summary: {
    id: 'SideBarLinks.Summary',
    defaultMessage: 'Summary',
    description: 'Navigation option to view summary.',
  },
  legal: {
    id: 'SideBarLinks.Legal',
    defaultMessage: 'Legal',
    description: 'Navigation option to view legal.',
  },
  addInfo: {
    id: 'SideBarLinks.AddInfo',
    defaultMessage: 'Additional Info',
    description: 'Navigation option to view additional information.',
  },
  back: {
    id: 'NavigationLanguage.Back',
    defaultMessage: 'back',
    description: 'Navigation option to go back a page.',
  },
  backToCause: {
    id: 'NavigationLanguage.BackToCause',
    defaultMessage: 'back to cause',
    description: 'Navigation option to go back to cause.',
  },
  backToFE: {
    id: 'NavigationLanguage.BackToFE',
    defaultMessage: 'back to fundraising event',
    description: 'Navigation option to go back to fundraising event.',
  },
  skip: {
    id: 'NavigationLanguage.Skip',
    defaultMessage: 'Skip',
    description: 'Navigation option to skip a page.',
  },
  select: {
    id: 'Frontend.CampaignShow.Select',
    defaultMessage: 'select',
    description: 'Text for the select button',
  },
  selectLabel: {
    id: 'Frontend.CampaignShow.SelectLabel',
    defaultMessage: 'Select',
    description: 'Text for the select label',
  },
  continue: {
    id: 'NavigationLanguage.Continue',
    defaultMessage: 'continue',
    description: 'Navigation option to continue.',
  },
  causeMessages: {
    id: 'NavigationLanguage.CauseMessages',
    defaultMessage: 'Cause messages',
    description: 'Navigation option Cause messages.',
  },
  donorMessages: {
    id: 'NavigationLanguage.DonorMessages',
    defaultMessage: 'Donor messages',
    description: 'Navigation option Donor messages.',
  },
  teamMessages: {
    id: 'NavigationLanguage.TeamMessages',
    defaultMessage: 'Team messages',
    description: 'Navigation option Team messages.',
  },
  messages: {
    id: 'NavigationLanguage.messages',
    defaultMessage: 'Messages',
    description: 'Navigation option messages.',
  },
  viewPage: {
    id: 'CauseAdmin.ViewPage',
    defaultMessage: 'view page',
    description: 'Button text for to link to cause/event page',
  },
  settings: {
    id: 'NavigationLanguage.Settings',
    defaultMessage: 'Settings',
    description: 'Navigation option settings.',
  },
  campaignTypes: {
    id: 'NavigationLanguage.CampaignTypes',
    defaultMessage: 'Campaign types',
    description: 'Navigation option campaign types.',
  },
  reporting: {
    id: 'NavigationLanguage.Reporting',
    defaultMessage: 'Reporting',
    description: 'The label for the reporting nav link.',
  },
  activities: {
    id: 'NavigationLanguage.Activities',
    defaultMessage: 'Activities',
    description: 'Navigation option activities.',
  },
})

export const rewardMessages = defineMessages({
  codesUploading: {
    id: 'Rewards.CodesUploading',
    defaultMessage: 'Your codes are uploading, please do not navigate away',
    description: 'Prompt when user is uploading redemption codes',
  },
  eligibleReward: {
    id: 'CampaignRewards.EligibleReward',
    defaultMessage: 'Your donation is eligible for a reward',
    description: 'Heading for Your Rewards page.',
  },
  search: {
    id: 'CampaignRewards.RewardSearch',
    defaultMessage: 'search for a reward',
    description: 'Placeholder for reward search',
  },
  showMore: {
    id: 'CampaignRewards.ShowMore',
    defaultMessage: 'Show more rewards',
    description: 'Text for the show more rewards button.',
  },
  minDivider: {
    id: 'CampaignRewards.MinimumDivider',
    defaultMessage: 'Selecting rewards below will increase your donation amount',
    description: 'Rewards list amount warning.',
  },
  minWarning: {
    id: 'CampaignRewards.MinimumWarning',
    defaultMessage: 'Selecting this reward will increase your donation to ',
    description: 'Text for the minimum donation warning.',
  },
  unavailable: {
    id: 'DonateFlow.RewardUnavailable',
    defaultMessage: 'Reward no longer available',
    description: 'Error displayed when a reward is no longer available.',
  },
  locked: {
    id: 'DonateFlow.RewardLocked',
    defaultMessage: 'Reward has not been unlocked',
    description: 'Error displayed when a reward has not been unlocked.',
  },
  countdownLocked: {
    id: 'Countdown.Locked',
    defaultMessage: 'Locked',
    description: 'Locked text for countdown timer.',
  },
  locksIn: {
    id: 'Countdown.LocksIn',
    defaultMessage: 'Locks in',
    description: 'Locks in text for countdown timer.',
  },
  unlockedIn: {
    id: 'Countdown.UnlockedIn',
    defaultMessage: 'Unlocked in',
    description: 'Unlocked in text for countdown timer.',
  },
  shippingRequired: {
    id: 'CampaignRewards.ShippingRequired',
    defaultMessage: 'This reward requires a shipping address.',
    description: 'Text for the reward options heading',
  },
  rewardOptions: {
    id: 'CampaignRewards.RewardOptions',
    defaultMessage: 'Reward options',
    description: 'Text for the reward options heading',
  },
  selectReward: {
    id: 'CampaignRewards.SelectReward',
    defaultMessage: 'select this reward',
    description: 'Text for the select this reward button.',
  },
  skipReward: {
    id: 'CampaignRewards.SkipReward',
    defaultMessage: 'skip reward',
    description: 'Text for the skip reward button.',
  },
  back: {
    id: 'CampaignRewardDonateFlow.Back',
    defaultMessage: 'back to rewards',
    description: 'Text for the back to reward list button.',
  },
  color: {
    id: 'CampaignRewards.Color',
    defaultMessage: 'Color',
    description: 'Text for the color label.',
  },
  size: {
    id: 'CampaignRewards.Size',
    defaultMessage: 'Size',
    description: 'Text for the size label.',
  },
  soldOut: {
    id: 'Create.Info.Info.NotAvailable',
    defaultMessage: 'Reward no longer available',
    description: 'Text for sold out rewards',
  },
  available: {
    id: 'RewardDetail.Remaining.Remaining',
    defaultMessage: '{remaining}/{quantity} available',
    description: 'Text for available remaining for reward',
  },
  FMV: {
    id: 'CampaignRewards.FMV',
    defaultMessage: 'FMV',
    description: 'Fair Market Value Abbreviation',
  },
  fullFMV: {
    id: 'CampaignRewards.FullFMV',
    defaultMessage: 'Fair Market Value',
    description: 'Fair Market Value label',
  },
  addAddress: {
    id: 'CampaignRewards.AddAddress',
    defaultMessage: 'Add Address',
    description: 'Button label to add address',
  },
  saveAddress: {
    id: 'CampaignRewards.SaveAddress',
    defaultMessage: 'Save Address',
    description: 'Button label to save address',
  },
  additionalInfoNeeded: {
    id: 'DonateFlow.RewardNeedsAdditionalInfo',
    defaultMessage: 'Reward requires additional information',
    description: 'Error displayed when a reward requires additional information.',
  },
  firstName: {
    id: 'Creat.Shipping.FirstName',
    defaultMessage: 'First name',
    description: 'Text for the first name label.',
  },
  lastName: {
    id: 'Creat.Shipping.LastName',
    defaultMessage: 'Last name',
    description: 'Text for the last name label.',
  },
  add1: {
    id: 'Creat.Shipping.AddressLine1',
    defaultMessage: 'Address line 1',
    description: 'Text for the address 1 label.',
  },
  add2: {
    id: 'Creat.Shipping.AddressLine2',
    defaultMessage: 'Address line 2 (optional)',
    description: 'Text for the address 2 label.',
  },
  country: {
    id: 'Creat.Shipping.Country',
    defaultMessage: 'Country',
    description: 'Text for the country label.',
  },
  state: {
    id: 'Creat.Shipping.State',
    defaultMessage: 'State/Region',
    description: 'Text for the region label.',
  },
  city: {
    id: 'Creat.Shipping.City',
    defaultMessage: 'City',
    description: 'Text for the city label.',
  },
  zip: {
    id: 'Creat.Shipping.Zip',
    defaultMessage: 'Zip/Postal code',
    description: 'Text for the zip code label.',
  },
  rewardCreated: {
    id: 'Rewards.RewardCreated',
    defaultMessage: 'Reward Created',
    description: 'Status text when reward created successfully',
  },
  rewardUpdated: {
    id: 'Rewards.RewardUpdated',
    defaultMessage: 'Reward Updated',
    description: 'Status text when reward updated successfully',
  },
  rewardDeleted: {
    id: 'Rewards.RewardDeleted',
    defaultMessage: 'Reward Deleted',
    description: 'Status text when reward deleted successfully',
  },
  rewardActivated: {
    id: 'Rewards.RewardActivated',
    defaultMessage: 'Reward Activated',
    description: 'Status text when reward activated successfully',
  },
  rewardDeactivated: {
    id: 'Rewards.RewardDeactivated',
    defaultMessage: 'Reward Deactivated',
    description: 'Status text when reward deactivated successfully',
  },
  rewardPromoted: {
    id: 'Rewards.RewardPromoted',
    defaultMessage: 'Reward has been promoted',
    description: 'Status text when reward promoted successfully',
  },
  rewardDemoted: {
    id: 'Rewards.RewardDemoted',
    defaultMessage: 'Reward is no longer promoted',
    description: 'Status text when reward demoted successfully',
  },
  rewardRetired: {
    id: 'Rewards.RewardRetired',
    defaultMessage: 'Reward Retired',
    description: 'Status text when reward retired successfully',
  },
  rewardNotFound: {
    id: 'Rewards.RewardNotFound',
    defaultMessage: 'Reward could not be found.',
    description: 'Status text when reward cannot be found',
  },
  addNewReward: {
    id: 'Rewards.AddNewReward',
    defaultMessage: 'add new reward',
    description: 'Button text to add a new reward',
  },
})

export const registrationMessages = defineMessages({
  back: {
    id: 'RegistrationMessages.Back',
    defaultMessage: 'back to registrations',
    description: 'Text for the back to registrations list button.',
  },
  registrationUpdated: {
    id: 'RegistrationMessages.RegistrationUpdated',
    defaultMessage: 'Registration Updated',
    description: 'Notification text when user updates registration',
  },
  registrationNotFound: {
    id: 'RegistrationMessages.RegistrationNotFound',
    defaultMessage: 'Registration could not be found.',
    description: 'Notification text when a registration could not be found',
  },
  singleRegistrationDescription: {
    id: 'RegistrationMessages.SingleRegistrationDescription',
    defaultMessage:
      'Review and update your registration. Changes can only be made to active events.',
    description: 'Single registration section description',
  },
})

export const challengeMessages = defineMessages({
  unavailable: {
    id: 'DonateFlow.ChallengeUnavailable',
    defaultMessage: 'Challenge no longer available',
    description: 'Error displayed when a challenge is no longer available.',
  },
  heading: {
    id: 'DonateFlow.ChallengeHeading',
    defaultMessage: 'Put your donation towards a challenge!',
    description: 'Heading for Your Challenges page.',
  },
  selected: {
    id: 'DonateFlow.SelectedChallenge',
    defaultMessage: 'Challenge Selected',
    description: 'Text for the challenge selected button.',
  },
  skip: {
    id: 'DonateFlow.SkipChallenge',
    defaultMessage: 'Skip Challenge',
    description: 'Text for the skip challenge button.',
  },
  complete: {
    id: 'DonateFlow.ChallengeComplete',
    defaultMessage: 'Complete!',
    description: 'Label for a completed challenge.',
  },
})

export const targetMessages = defineMessages({
  unavailable: {
    id: 'DonateFlow.TargetUnavailable',
    defaultMessage: 'Target no longer available',
    description: 'Error displayed when a target is no longer available.',
  },
  heading: {
    id: 'DonateFlow.TargetHeading',
    defaultMessage: 'Put your donation towards a target!',
    description: 'Heading for Your targets page.',
  },
  selected: {
    id: 'DonateFlow.SelectedTarget',
    defaultMessage: 'Target selected',
    description: 'Text for the target selected button.',
  },
  skip: {
    id: 'DonateFlow.SkipTarget',
    defaultMessage: 'skip target',
    description: 'Text for the skip target button.',
  },
  addNewTarget: {
    id: 'DonateFlow.AddNewTarget',
    defaultMessage: 'add new target',
    description: 'Text for the add new target button.',
  },
})

export const pollMessages = defineMessages({
  unavailable: {
    id: 'DonateFlow.PollOptionUnavailable',
    defaultMessage: 'Poll Option no longer available',
    description: 'Error displayed when a poll option is no longer available.',
  },
  heading: {
    id: 'DonateFlow.PollHeading',
    defaultMessage: 'Put your donation towards a poll',
    description: 'Heading for Your Polls page.',
  },
  selected: {
    id: 'DonateFlow.SelectedPoll',
    defaultMessage: 'Poll selected',
    description: 'Text for the poll selected button.',
  },
  skip: {
    id: 'DonateFlow.SkipPoll',
    defaultMessage: 'skip poll',
    description: 'Text for the skip poll button.',
  },
  addNewPoll: {
    id: 'DonateFlow.AddNewPoll',
    defaultMessage: 'add new poll',
    description: 'Text for the add new poll button.',
  },
})

export const mgLegalMessages = defineMessages({
  mainHeading: {
    id: 'SustainedGivingInfo.InfoText.Heading',
    defaultMessage: 'Monthly giving',
    description: 'Legal page heading.',
  },
  listHeading: {
    id: 'SustainedGivingInfo.InfoText.BecomingMonthly',
    defaultMessage: 'Becoming a monthly donor helps you simplify your giving:',
    description: 'List heading',
  },
  bullet1: {
    id: 'SustainedGivingInfo.InfoText.YourDonationWill',
    defaultMessage:
      'Your donation will be made today and on the same day of each following month using the payment method selected for the same amount.',
    description: 'Sustained giving bullet 1',
  },
  bullet2: {
    id: 'SustainedGivingInfo.InfoText.TrackAndControl',
    defaultMessage: 'Track and control your monthly donations from your user dashboard.',
    description: 'Sustained giving bullet 2',
  },
  bullet3: {
    id: 'SustainedGivingInfo.InfoText.CancelAnytime',
    defaultMessage: 'Cancel anytime directly from your dashboard.',
    description: 'Sustained giving bullet 3',
  },
  bullet4: {
    id: 'SustainedGivingInfo.InfoText.OnDonatingTheAmount',
    defaultMessage:
      'On donating the amount you choose will be taken from your credit card each month until you cancel your regular giving.',
    description: 'Sustained giving bullet 4',
  },
  accept: {
    id: 'SustainedGivingInfo.InfoText.IAccept',
    defaultMessage: 'I understand the terms of monthly giving',
    description: 'Label text for checkbox',
  },
  continue: {
    id: 'SustainedGivingInfo.InfoText.Continue',
    defaultMessage: 'Continue with monthly donation',
    description: 'Text for continue button',
  },
})

export const summaryMessages = defineMessages({
  canadaCreditAvailable: {
    id: 'DonateFlow.Summary.CanadaCreditAvailable',
    defaultMessage: 'This donation is eligible for Canadian Charitable Donations Tax Credit.',
    description: 'Label for the summary canada credit available.',
  },
  canadaCreditDone: {
    id: 'DonateFlow.Summary.CanadaCreditDone',
    defaultMessage: 'Canadian Charitable Donations Tax Credit information completed.',
    description: 'Label for the summary canada credit available.',
  },
  giftAidAvailable: {
    id: 'DonateFlow.Summary.GiftAidAvailable',
    defaultMessage: 'This donation is eligible for Gift Aid.',
    description: 'Label for the summary gift aid available.',
  },
  giftAidDone: {
    id: 'DonateFlow.Summary.GiftAidDone',
    defaultMessage: 'Gift Aid completed.',
    description: 'Label for the summary gift aid available.',
  },
  generalRequestAvailable: {
    id: 'DonateFlow.Summary.GeneralRequestAvailable',
    defaultMessage: 'Provide additional information to the charity.',
    description: 'Label for the summary gift aid available.',
  },
  generalRequestDone: {
    id: 'DonateFlow.Summary.GeneralRequestDone',
    defaultMessage: 'Address Provided',
    description: 'Label for the summary gift aid available.',
  },
  reward: {
    id: 'IncentiveCallout.Reward',
    defaultMessage: 'Reward',
    description: 'Label for the summary selected reward.',
  },
  poll: {
    id: 'IncentiveCallout.Poll',
    defaultMessage: 'Poll',
    description: 'Label for the summary selected poll.',
  },
  challenge: {
    id: 'IncentiveCallout.Challenge',
    defaultMessage: 'Challenge',
    description: 'Label for the summary selected challenge.',
  },
  target: {
    id: 'IncentiveCallout.Target',
    defaultMessage: 'Target',
    description: 'Label for the summary selected target.',
  },
  paneHeading: {
    id: 'DonateFlow.Summary.PaneHeading',
    defaultMessage: 'Donation summary',
    description: 'Text for the summary pane heading.',
  },
  cause: {
    id: 'DonateFlow.Summary.Cause',
    defaultMessage: 'Cause',
    description: 'Label for the summary pane cause.',
  },
  fundraisingEvent: {
    id: 'Frontend.CampaignShow.FundraisingEvnet',
    defaultMessage: 'Fundraising event',
    description: 'Label text for the fundraising event info card.',
  },
  fundraisingEventTitleCase: {
    id: 'StartFlow.FundraisingEvent',
    defaultMessage: 'Fundraising Event',
    description: 'Label for fundraising event on summary pane',
  },
  fundraiser: {
    id: 'DonateFlow.Summary.Fundraiser',
    defaultMessage: 'Fundraiser',
    description: 'Label for the summary fundraiser.',
  },
  campaign: {
    id: 'DonateFlow.Summary.Campaign',
    defaultMessage: 'Campaign',
    description: 'Label for the summary campaign.',
  },
  supporting: {
    id: 'DonateFlow.Summary.Supporting',
    defaultMessage: 'Supporting',
    description: 'Label for the summary team that the campaign is supporting.',
  },
  regions: {
    id: 'DonateFlow.Summary.Regions',
    defaultMessage: 'Regions',
    description: 'Label for the region that the campaign is supporting.',
  },
  charityContact: {
    id: 'DonateForm.CharityContact',
    defaultMessage: 'Allow the charity to contact you?',
    description: 'Label for charity contactcheckbox.',
  },
  donationMethod: {
    id: 'DonateFlow.Summary.DonationMethod',
    defaultMessage: 'Donation Method',
    description: 'Label for available donation methods.',
  },
  summaryHeading: {
    id: 'DonateFlow.Summary.SummaryHeading',
    defaultMessage: 'Secure donation summary',
    description: 'Text for the summary heading.',
  },
  edit: {
    id: 'DonateFlow.Summary.Edit',
    defaultMessage: 'edit',
    description: 'Text for the summary email edit button.',
  },
  paymentError: {
    id: 'DonateFlow.Summary.PaymentError',
    defaultMessage: 'An error occurred while processing your payment',
    description: 'Text for when a payment error has occurred.',
  },
  rewardError: {
    id: 'DonateFlow.Summary.RewardError',
    defaultMessage:
      'Reward information is missing. Please return to the reward and finish making your selections.',
    description: 'Text for when a payment error has occurred.',
  },
  authError: {
    id: 'DonateFlow.Summary.AuthError',
    defaultMessage: 'You must be logged in to complete a monthly donation.',
    description: 'Text for when a user is not logged in for as required for a monthly donation.',
  },
})

export const inputMessages = defineMessages({
  uploadDragAndDrop: {
    id: 'InputMessage.Upload.DragAndDrop',
    defaultMessage: 'drag and drop',
    description: 'Instructions for image upload',
  },
  instructions: {
    id: 'InputMessage.iInstructions',
    defaultMessage: 'Redemption Reward Instructions',
    description: 'Label for instructions input',
  },
  makeRequired: {
    id: 'InputMessage.MakeRequired',
    defaultMessage: 'Make Required',
    description: 'Label for required input',
  },
  font: {
    id: 'InputMessage.Font',
    defaultMessage: 'Font',
    description: 'Label for font input',
  },
  year: {
    id: 'InputMessage.Year',
    defaultMessage: 'Year',
    description: 'label for year input',
  },
  uploadDrop: {
    id: 'InputMessage.Upload.Drop',
    defaultMessage: 'drop the files here...',
    description: 'Instructions for image upload',
  },
  uploadFilesHere: {
    id: 'InputMessage.Upload.FilesHere',
    defaultMessage: 'your files here, or click to upload',
    description: 'Instructions for image upload',
  },
  acceptedFiles: {
    id: 'InputMessage.Upload.AcceptedFiles',
    defaultMessage: '(jpg, jpeg, png)',
    description: 'Instructions for image upload',
  },
  uploadFailed: {
    id: 'InputMessage.Upload.Failed',
    defaultMessage: 'Incorrect file type',
    description: 'Notification for failed image upload',
  },
  recommended: {
    id: 'InputMessage.Upload.recommended',
    defaultMessage: 'recommended size:',
    description: 'Instructions for image upload recommended size',
  },
  uploadTitle: {
    id: 'InputMessage.Upload.UploadTitle',
    defaultMessage: 'Add / edit your {title}',
    description: 'Title for image upload',
  },
  birthdayInvalidError: {
    id: 'StartFlow.BirthdayInvalidError',
    defaultMessage: 'Must submit a valid date',
    description: 'Label for numbered registration section',
  },
  charityNameLabel: {
    id: 'CharityNameInput.CharityName',
    defaultMessage: 'Charity name',
    description: 'The label for the charity name text input.',
  },
  charityLegalNameLabel: {
    id: 'CharityNameInput.CharityLegalName',
    defaultMessage: 'Charity legal name',
    description: 'The label for the charity legal name text input.',
  },
  charityWebsiteLabel: {
    id: 'CharityWebsiteInput.CharityWebsite',
    defaultMessage: 'Official Cause Website',
    description: 'The label for the charity website text input.',
  },
  currencyLabel: {
    id: 'CharityCurrencyInput.Currency',
    defaultMessage: 'Preferred currency',
    description: 'The label for the charity name text input.',
  },
  jobTitle: {
    id: 'CharityJobTitleInput.JobTitle',
    defaultMessage: 'Job Title',
    description: 'The label for the charity job title text input.',
  },
  phone: {
    id: 'MyAccount.PhoneNumber',
    defaultMessage: 'Phone number',
    description: 'Label text for phone number input',
  },
  visibilityLeaderboardRange: {
    id: 'CauseAdmin.VisibilityLeaderboardRange',
    defaultMessage: 'Date range',
    description: 'Label text for Leaderboard Date Range input',
  },
  brandAvatar: {
    id: 'CauseAdmin.BrandAvatar',
    defaultMessage: 'Avatar',
    description: 'Label text for cause page avatar image upload',
  },
  brandFeaturedImage: {
    id: 'CauseAdmin.BrandFeaturedImage',
    defaultMessage: 'Featured image',
    description: 'Label text for cause page featured image upload',
  },
  brandFeaturedVideo: {
    id: 'CauseAdmin.BrandFeaturedVideo',
    defaultMessage: 'Featured video',
    description: 'Label text for cause page featured video input',
  },
  brandBanner: {
    id: 'CauseAdmin.BrandBanner',
    defaultMessage: 'Banner',
    description: 'Label text for cause page banner image upload',
  },
  externalPhone: {
    id: 'CauseAdmin.ExternalPhone',
    defaultMessage: 'Phone',
    description: 'Label text for the external charity contact phone input',
  },
  address: {
    id: 'CauseAdmin.Address',
    defaultMessage: 'Address',
    description: 'Label text for the address input',
  },
  sizes: {
    id: 'CauseAdmin.RewardSizes',
    defaultMessage: 'Sizes',
    description: 'Header text for reward sizes',
  },
  colors: {
    id: 'CauseAdmin.RewardColors',
    defaultMessage: 'Colors',
    description: 'Header text for reward colors',
  },
  shipping: {
    id: 'CauseAdmin.RewardShipping',
    defaultMessage: 'Shipping',
    description: 'Header text for reward shipping',
  },
  question: {
    id: 'CauseAdmin.RewardQuestion',
    defaultMessage: 'Question',
    description: 'Header text for reward questions',
  },
  legal: {
    id: 'CauseAdmin.RewardLegal',
    defaultMessage: 'Legal',
    description: 'Header text for reward legal information',
  },
  description: {
    id: 'CauseAdmin.Description',
    defaultMessage: 'Description',
    description: 'Label text for the description input',
  },
  shortDescription: {
    id: 'CauseAdmin.ShortDescription',
    defaultMessage: 'Short description',
    description: 'Label text for the short description input',
  },
  detailContactEmail: {
    id: 'EventAdmin.DetailContactEmail',
    defaultMessage: 'Contact email',
    description: 'Label text for event page Contact Email input',
  },
  registrationEmailMessage: {
    id: 'CauseAdmin.RegistrationEmailMessage',
    defaultMessage: 'Email Message',
    description: 'Label text for the registation email message input',
  },
  enabled: {
    id: 'CauseAdmin.Enabled',
    defaultMessage: 'Enabled',
    description: 'Label text for enabled inputs',
  },
  disabled: {
    id: 'CauseAdmin.Disabled',
    defaultMessage: 'Disabled',
    description: 'Label text for disabled inputs',
  },
  registrationShirtSize: {
    id: 'CauseAdmin.RegistrationShirtSize',
    defaultMessage: 'Shirt size',
    description: 'Label text for the registation shirt size input',
  },
  registrationShirtFit: {
    id: 'CauseAdmin.RegistrationShirtFit',
    defaultMessage: 'Shirt fit',
    description: 'Label text for the registation shirt fit input',
  },
  registrationDateOfBirth: {
    id: 'CauseAdmin.RegistrationDateOfBirth',
    defaultMessage: 'Date of birth',
    description: 'Label text for the registation date of birth input',
  },
  registrationContactInformation: {
    id: 'CauseAdmin.RegistrationContactInformation',
    defaultMessage: 'Contact information',
    description: 'Header text for the contact information registration fields',
  },
  startDate: {
    id: 'Reports.StartDate',
    defaultMessage: 'Start date',
    description: 'Label for start date',
  },
  endDate: {
    id: 'Reports.EndDate',
    defaultMessage: 'End date',
    description: 'Label for end date',
  },
  registrationDate: {
    id: 'Reports.RegistrationDate',
    defaultMessage: 'Registration date',
    description: 'Label for registration date',
  },
  donorName: {
    id: 'Reports.DonorName',
    defaultMessage: 'Donor name',
    description: 'Label for donor name',
  },
  status: {
    id: 'Reports.Status',
    defaultMessage: 'Status',
    description: 'Label for status',
  },
  username: {
    id: 'Reports.Username',
    defaultMessage: 'Username',
    description: 'Label for username',
  },
  usernameHelper: {
    id: 'Reports.UsernameHelper',
    defaultMessage: 'You can only change your username once per month',
    description: 'Helper for username',
  },
  shippingRequired: {
    id: 'GlobalRewards.ShippingRequired',
    defaultMessage: 'Shipping required',
    description: 'Label for shipping required input',
  },
  askAQuestion: {
    id: 'GlobalRewards.AskAQuestion',
    defaultMessage: 'Ask a Question',
    description: 'Label for ask a question input',
  },
  customQuestion: {
    id: 'GlobalRewards.CustomQuestion',
    defaultMessage: 'Custom question',
    description: 'Label for custom question input',
  },
  fairMarketValue: {
    id: 'GlobalRewards.FairMarketValue',
    defaultMessage: 'Fair market value',
    description: 'Label for fair market value input',
  },
  image: {
    id: 'GlobalRewards.Image',
    defaultMessage: 'Image',
    description: 'Label for image upload input',
  },
  campaign: {
    id: 'Reports.Campaign',
    defaultMessage: 'Campaign',
    description: 'Label for campaign',
  },
  paymentMethod: {
    id: 'Reports.PaymentMethod',
    defaultMessage: 'Payment method',
    description: 'Label for payment method',
  },
  timeProcessed: {
    id: 'Reports.TimeProcessed',
    defaultMessage: 'Time processed',
    description: 'Label for time processed',
  },
  transactionId: {
    id: 'Reports.TransactionId',
    defaultMessage: 'Transaction Id',
    description: 'Label for transaction id',
  },
  eventDescriptionGoal: {
    id: 'EventAdmin.EventDescriptionGoal',
    defaultMessage: 'Goal amount',
    description: 'Label text for the event goal amount field.',
  },
  eventURL: {
    id: 'EventAdmin.EventURL',
    defaultMessage: 'Permalink',
    description: 'Label text for the event URL field.',
  },
  brandShortDescription: {
    id: 'EventAdmin.BrandShortDescription',
    defaultMessage: 'Shareable description',
    description: 'Label text for event page Shareable Description input',
  },
  contactHeading: {
    id: 'EventAdmin.ContactHeading',
    defaultMessage: 'Contact information',
    description: 'Heading text for the event contact section',
  },
  active: {
    id: 'CauseAdmin.Active',
    defaultMessage: 'Active',
    description: 'Label text for active inputs',
  },
  monthlyGivingMinimumAmount: {
    id: 'CauseAdmin.MonthlyGivingActive',
    defaultMessage: 'Minimum amount',
    description: 'Label text for Monthly Giving Minimum Amount Input',
  },
  giftAidHeading: {
    id: 'CauseAdmin.GiftAidHeading',
    defaultMessage: 'Gift Aid',
    description: 'Heading text for the cause feature gift aid section',
  },
  goal: {
    id: 'CauseAdmin.GoalLabel',
    defaultMessage: 'Goal',
    description: 'Label text for the goal column',
  },
  team: {
    id: 'CauseAdmin.Team',
    defaultMessage: 'Team',
    description: 'Label text for the team column',
  },
  donorEmail: {
    id: 'CauseAdmin.DonorEmail',
    defaultMessage: 'Donor email',
    description: 'Label text for the donor email column',
  },
  donorComment: {
    id: 'CauseAdmin.DonorComment',
    defaultMessage: 'Donor comment',
    description: 'Label text for the donor comment column',
  },
  timeOfdonation: {
    id: 'CauseAdmin.TimeOfDonation',
    defaultMessage: 'Time of donation',
    description: 'Label text for the time of donation column',
  },
  code: {
    id: 'CauseAdmin.Code',
    defaultMessage: 'Code',
    description: 'Label text for the code column',
  },
  bio: {
    id: 'InputMessages.Bio',
    defaultMessage: 'Bio',
    description: 'Label text for the bio input',
  },
  date: {
    id: 'InputMessages.Date',
    defaultMessage: 'Date',
    description: 'Label text for the date input',
  },
  quantity: {
    id: 'GenericMessages.Quantity',
    defaultMessage: 'Quantity',
    description: 'Label for quantity input.',
  },
  quantityWithEmpty: {
    id: 'GenericMessages.QuantityWithEmpty',
    defaultMessage: 'Quantity (leave blank if unlimited)',
    description: 'Label for quantity input with empty text',
  },
  url: {
    id: 'InputMessages.Url',
    defaultMessage: 'URL',
    description: 'Label text for the url input',
  },
  service: {
    id: 'InputMessages.Service',
    defaultMessage: 'Service (if blank value is false)',
    description: 'Label text for the service input',
  },
  activeTitle: {
    id: 'InputMessages.ActiveTitle',
    defaultMessage: 'Not available on your campaign until active.',
    description: 'Title text for the active hover',
  },
  rewardDateHeading: {
    id: 'InputMessages.RewardDateHeading',
    defaultMessage: 'Makes reward available on start date and unavailable after end date',
    description: 'Title text for the active hover',
  },
  type: {
    id: 'InputMessages.Type',
    defaultMessage: 'Type',
    description: 'Column header for type',
  },
  primaryFont: {
    id: 'InputMessages.PrimaryFont',
    defaultMessage: 'Primary font',
    description: 'Label for font input',
  },
  secondaryFont: {
    id: 'InputMessages.SecondaryFont',
    defaultMessage: 'Secondary font',
    description: 'Label for font input',
  },
  selectAPoll: {
    id: 'InputMessages.SelectAPoll',
    defaultMessage: 'Select a poll',
    description: 'Label for blank poll input',
  },
  selectAReward: {
    id: 'InputMessages.SelectAReward',
    defaultMessage: 'Select a reward',
    description: 'Label for blank reward input',
  },
  selectATarget: {
    id: 'InputMessages.SelectATarget',
    defaultMessage: 'Select a target',
    description: 'Label for blank target input',
  },
  enableAll: {
    id: 'InputMessages.EnableAll',
    defaultMessage: 'Enable all',
    description: 'Label for enable all input',
  },
  disableAll: {
    id: 'InputMessages.DisableAll',
    defaultMessage: 'Disable all',
    description: 'Label for disable all input',
  },
  promote: {
    id: 'InputMessages.Promote',
    defaultMessage: 'Promote',
    description: 'Label for promote input',
  },
  promoteAction: {
    id: 'InputMessages.PromoteAction',
    defaultMessage: 'promote',
    description: 'Label for promote input',
  },
  promoted: {
    id: 'InputMessages.Promoted',
    defaultMessage: 'Promoted',
    description: 'Label text for promoted',
  },
  demote: {
    id: 'InputMessages.Demote',
    defaultMessage: 'Demote',
    description: 'Label for demote input',
  },
  demoteAction: {
    id: 'InputMessages.DemoteAction',
    defaultMessage: 'demote',
    description: 'Label for demote input',
  },
  demoted: {
    id: 'InputMessages.Demoted',
    defaultMessage: 'Demoted',
    description: 'Label text for demoted',
  },
  promoteThisReward: {
    id: 'InputMessages.PromoteThisReward',
    defaultMessage: 'Promote this reward',
    description: 'Label for promoted input',
  },
  customStatement: {
    id: 'InputMessages.CustomStatement',
    defaultMessage: 'Custom statement',
    description: 'Label for custom statement input',
  },
  distance: {
    id: 'InputMessages.Distance',
    defaultMessage: 'Distance',
    description: 'Column header for distance',
  },
  duration: {
    id: 'InputMessages.Duration',
    defaultMessage: 'Duration',
    description: 'Column header for duration',
  },
  averagePace: {
    id: 'InputMessages.AveragePace',
    defaultMessage: 'Average Pace',
    description: 'Column header for average pace',
  },
  elevationGain: {
    id: 'InputMessages.ElevationGain',
    defaultMessage: 'Elevation Gain',
    description: 'Column header for elevation gain',
  },
  tracker: {
    id: 'InputMessages.Tracker',
    defaultMessage: 'Tracker',
    description: 'Column header for tracker',
  },
  include: {
    id: 'InputMessages.Include',
    defaultMessage: 'Include',
    description: 'Column header for include',
  },
  activitiesFormType: {
    id: 'InputMessages.ActivitiesFormType',
    defaultMessage: 'Activity type',
    description: 'Label text for activity type',
  },
})

export const dashboardLayoutMessages = defineMessages({
  save: {
    id: 'DashboardLayout.Save',
    defaultMessage: 'save changes',
    description: 'Button text for the save button',
  },
  cancel: {
    id: 'DashboardLayout.Cancel',
    defaultMessage: 'cancel',
    description: 'Button text for the cancel button',
  },
  undo: {
    id: 'DashboardLayout.Undo',
    defaultMessage: 'undo',
    description: 'Button text for the undo button',
  },
  reset: {
    id: 'DashboardLayout.Reset',
    defaultMessage: 'reset',
    description: 'Button text for the reset button',
  },
  remove: {
    id: 'DashboardLayout.Remove',
    defaultMessage: 'remove',
    description: 'Button text for the remove button',
  },
  setAsDefault: {
    id: 'DashboardLayout.SetAsDefault',
    defaultMessage: 'set as default',
    description: 'Button text for the set as default button',
  },
  default: {
    id: 'DashboardLayout.Default',
    defaultMessage: 'default',
    description: 'Button text for the default button',
  },
})

export const paymentMessages = defineMessages({
  creditCard: {
    id: 'DonateFlow.StripeButton',
    defaultMessage: 'Credit Card',
    description: 'The Donation button text for Stripe.',
  },
  paymentMethod: {
    id: 'StripeCheckout.PaymentMethod',
    defaultMessage: 'Payment Method',
    description: 'The label for the saved card dropdown.',
  },
  cardEndingIn: {
    id: 'StripeCheckout.CardEndingIn',
    defaultMessage: '{brand} ending in {lastFour}',
    description: 'The label for the cards in the saved card dropdown.',
  },
  cardNumber: {
    id: 'StripeCheckout.CardNumber',
    defaultMessage: 'Card Number',
    description: 'The label for the card number textbox.',
  },
  cardExp: {
    id: 'StripeCheckout.ExpiryDate',
    defaultMessage: 'Expiry Date',
    description: 'The label for the expiry date textbox',
  },
  cardCVC: {
    id: 'StripeCheckout.CVCCode',
    defaultMessage: 'CVC Code',
    description: 'The label for the cvc code textbox',
  },
  cardPostal: {
    id: 'StripeCheckout.Postal',
    defaultMessage: 'Postal',
    description: 'The label for the postal textbox',
  },
  inProgress: {
    id: 'StripeCheckout.InProgress',
    defaultMessage: 'In Progress',
    description: 'The label for the donation button when a donation is in progress.',
  },
  donate: {
    id: 'StripeCheckout.Donate',
    defaultMessage: 'Donate',
    description: 'The label for the donate button.',
  },
  anotherCard: {
    id: 'StripeCheckout.AnotherCard',
    defaultMessage: 'use another card',
    description: 'The label for the button to use another card instead of saved methods',
  },
  ach: {
    id: 'ACHCheckout.ACHPayment',
    defaultMessage: 'ACH Payment',
    description: 'The label for the ACH payment button.',
  },
  complete: {
    id: 'ACHCheckout.Complete',
    defaultMessage: 'Complete',
    description: 'The label for the ACH complete button.',
  },
  achBankRequiredHeading: {
    id: 'ACHCheckout.BankRequiredHeading',
    defaultMessage: 'Bank Account Required',
    description: 'The title for the bank required modal',
  },
  achBankRequiredDescription: {
    id: 'ACHCheckout.BankRequiredDescription',
    defaultMessage: 'You must connect and verify your bank account first',
    description: 'The description/help for the bank required modal',
  },
  achBankRequiredConnect: {
    id: 'ACHCheckout.BankRequiredConnect',
    defaultMessage: 'connect bank account',
    description: 'The label for the button that connects your bank account',
  },
  bank: {
    id: 'ACHCheckout.Bank',
    defaultMessage: 'Institution',
    description: 'The label for the ACH institution.',
  },
  heading: {
    id: 'ACHCheckout.Heading',
    defaultMessage: 'ACH Donation Detail',
    description: 'The heading for ACH.',
  },
  policy: {
    id: 'ACHCheckout.Policy',
    defaultMessage:
      '<a>By continuing, you agree to the</a> <b>Tiltify Terms of Use</b> <c>and</c> <d>Privacy Policy.</d>',
    description: 'Text to agree to the terms and policy',
  },
  achPaymentTo: {
    id: 'ACHCheckout.PaymentTo',
    defaultMessage: 'Payment to {cause}',
    description: 'Description for table of who is receiving the payment',
  },
  achMandate: {
    id: 'ACHCheckout.Mandate',
    defaultMessage:
      "By clicking donate, you authorize {cause} to debit the bank account specified above for any amount donated pursuant to Tiltify's website and terms, until this authorization is revoked.",
    description: 'Ach authorization mandate when completing a donation',
  },
  auth: {
    id: 'ACHCheckout.Auth',
    defaultMessage:
      'By completing this ACH transaction you are authorizing the transfer of funds from {bank} to {cause}.',
    description: 'Label for auth text.',
  },
  auth2: {
    id: 'ACHCheckout.Auth2',
    defaultMessage:
      'There may be a delay in the notification of your ACH donation.  Your bank may not reflect the donation for up to 5 business days.',
    description: 'Label for auth text p2.',
  },
  paypal: {
    id: 'PaymentMessages.Paypal',
    defaultMessage: 'PayPal',
    description: 'Label for paypal button',
  },
  stripe: {
    id: 'PaymentMessages.Stripe',
    defaultMessage: 'Stripe',
    description: 'Label for stripe button',
  },
  paypalGivingFund: {
    id: 'PaymentMessages.PaypalGivingFund',
    defaultMessage: 'Paypal Giving Fund',
    description: 'Label for paypal giving fund button',
  },
  paypalMarketplace: {
    id: 'PaymentMessages.PaypalMarketplace',
    defaultMessage: 'Paypal Marketplace',
    description: 'Label for paypal marketplace button',
  },
  selectCypto: {
    id: 'DonateFlow.SelectCrypto',
    defaultMessage: 'Select your crypto currency',
    description: 'Heading text for crypto select.',
  },
  conversionRate: {
    id: 'DonateFlow.ConversionRate',
    defaultMessage: 'Current Conversion Rate',
    description: 'Heading text for crypto conversion amount.',
  },
  crypto: {
    id: 'DonateFlow.Crypto',
    defaultMessage: 'Donate Crypto',
    description: 'The Donation button text for Giving Block Crypto.',
  },
  selectCoin: {
    id: 'PaymentMessages.SelectCoin',
    defaultMessage: 'Select coin',
    description: 'Label for select coin dropdown for crypto',
  },
  pledgeNote: {
    id: 'PaymentMessages.PledgeNote',
    defaultMessage:
      'Please note that your donation will clear even if you donate a different amount than you pledged.',
    description: 'Note for pledge amount',
  },
  cryptoWallet: {
    id: 'PaymentMessages.CryptoWallet',
    defaultMessage:
      '<a>Use the address below to donate</a> <b>{amount} {coin}</b> <c>from your wallet</c>',
    description: 'Note for pledge amount',
  },
})

export const detailMessages = defineMessages({
  membershipOptions: {
    id: 'DonateFlow.Details.MembershipOptions',
    defaultMessage: 'Membership Options',
    description: 'Label for membership options selection.',
  },
  feeLabel: {
    id: 'DonateFlow.Details.FeeLabel',
    defaultMessage: 'Fee Amount ({currency})',
    description: 'Label fee amount.',
  },
  details: {
    id: 'DonateFlow.Details.Details',
    defaultMessage: 'Your Details',
    description: 'Heading for Your Details page.',
  },
  currency: {
    id: 'AmountInput.DonationAmount',
    defaultMessage: 'Donation amount ({currency})',
    description: 'Label for amount currency input.',
  },
  displayName: {
    id: 'DonateForm.DisplayName',
    defaultMessage: 'Display name',
    description: 'Label public name text input',
  },
  comment: {
    id: 'DonateForm.Comments',
    defaultMessage: 'Comments?',
    description: 'Label public comment text area',
  },
  email: {
    id: 'EmailInput.Email',
    defaultMessage: 'Email',
    description: 'Label for user email.',
  },
  primaryContactEmail: {
    id: 'EmailInput.PrimaryContactEmail',
    defaultMessage: 'Primary Contact Email',
    description: 'Label for primary contact email.',
  },
  anonymous: {
    id: 'DonateForm.Anonymous',
    defaultMessage: 'Anonymous',
    description: 'Label for user anonymity.',
  },
  public: {
    id: 'DonateForm.Public',
    defaultMessage: 'Public',
    description: 'Label for ach public checkbox.',
  },
})

export const mediaMessages = defineMessages({
  mediaType: {
    id: 'Media.MediaType',
    defaultMessage: 'Media type',
    description: 'Label text for a media type input',
  },
  livestream: {
    id: 'Media.Livestream',
    defaultMessage: 'Livestream',
    description: 'Label text for a livestream button',
  },
  twitch: {
    id: 'Media.Twitch',
    defaultMessage: 'Twitch',
    description: 'Text for the Twitch media type',
  },
  facebook: {
    id: 'Media.Facebook',
    defaultMessage: 'Facebook',
    description: 'Label text for the Facebook media type',
  },
  mixer: {
    id: 'Media.Mixer',
    defaultMessage: 'Mixer',
    description: 'Label text for the Mixer media type',
  },
  smashcast: {
    id: 'Media.Smashcast',
    defaultMessage: 'Smashcast',
    description: 'Label text for the Smashcast media type',
  },
  image: {
    id: 'Media.Image',
    defaultMessage: 'Image',
    description: 'Label text for the Image media type',
  },
  youtubeLive: {
    id: 'Media.YouTubeLive',
    defaultMessage: 'YouTube Live',
    description: 'Label text for the YouTube Live media type',
  },
  youtubeVideo: {
    id: 'Media.YouTubeVideo',
    defaultMessage: 'YouTube Video',
    description: 'Label text for the YouTube Video media type',
  },
  twitchInput: {
    id: 'Media.TwitchInput',
    defaultMessage: 'Twitch Username',
    description: 'Text for the Twitch media detail input',
  },
  facebookInput: {
    id: 'Media.FacebookInput',
    defaultMessage: 'Facebook Video URL',
    description: 'Label text for the Facebook media detail input',
  },
  mixerInput: {
    id: 'Media.MixerInput',
    defaultMessage: 'Mixer Username',
    description: 'Label text for the Mixer media detail input',
  },
  smashcastInput: {
    id: 'Media.SmashcastInput',
    defaultMessage: 'Smashcast Username',
    description: 'Label text for the Smashcast media detail input',
  },
  imageInput: {
    id: 'Media.ImageInput',
    defaultMessage: 'Image',
    description: 'Label text for the Image media detail input',
  },
  youtubeLiveInput: {
    id: 'Media.YouTubeLiveInput',
    defaultMessage: 'YouTube Channel ID',
    description: 'Label text for the YouTube Live media detail input',
  },
  youtubeVideoInput: {
    id: 'Media.YouTubeVideoInput',
    defaultMessage: 'YouTube Video URL',
    description: 'Label text for the YouTube Video media detail input',
  },
  findChannelId: {
    id: 'Media.FindChannelId',
    defaultMessage: 'Find your Channel id',
    description: 'Label text for the YouTube find your channel id link',
  },
  addLivestream: {
    id: 'Media.AddLivestream',
    defaultMessage: 'add your {type} livestream',
    description: 'Label text for button to add a connected livestream account',
  },
  selectPlatform: {
    id: 'Media.SelectPlatform',
    defaultMessage: 'Select a platform',
    description: 'Label for livestream media type selection',
  },
  orSelectPlatform: {
    id: 'Media.OrSelectPlatform',
    defaultMessage: 'or add one manually by selecting a platform',
    description: 'Label for livestream media type selection',
  },
  addConnectedAccount: {
    id: 'Media.AddConnectedAccount',
    defaultMessage: 'Add one of your connected accounts',
    description: 'Label for adding connected account panel',
  },
  preview: {
    id: 'Media.Preview',
    defaultMessage: 'Preview',
    description: 'Label for previewing a media embed',
  },
  notValid: {
    id: 'Media.NotValid',
    defaultMessage: 'Please enter a valid channel',
    description: 'Error label for missing channel value',
  },
})

export const genericMessages = defineMessages({
  memberships: {
    id: 'Generic.Memberships',
    defaultMessage: 'Memberships',
    description: 'Label text memberships',
  },
  owner: {
    id: 'Roles.Owner',
    defaultMessage: 'owner',
    description: 'Label text for owner',
  },
  manager: {
    id: 'Roles.Manager',
    defaultMessage: 'manager',
    description: 'Label text for manager',
  },
  member: {
    id: 'Roles.Member',
    defaultMessage: 'member',
    description: 'Label text for  member',
  },
  achievements: {
    id: 'GenericMessages.Achievements',
    defaultMessage: 'Achievements',
    description: 'Label for the achievements section',
  },
  error429: {
    id: 'GenericMessages.Error429',
    defaultMessage: 'Number of tries exceeded, try again later.',
    description: 'Message for rate limit exceeded',
  },
  error404Title: {
    id: 'GenericMessages.Error404Title',
    defaultMessage: "It's not you, it's us",
    description: 'Heading for error 404 page',
  },
  full404Body: {
    id: 'GenericMessages.Full404Body',
    defaultMessage:
      "The page you were looking for isn't available. You can either use the navigation bar above, or the search below to find what you are looking for.",
    description: 'Description for error 404 page',
  },
  limited404Body: {
    id: 'GenericMessages.Limited404Body',
    defaultMessage:
      "The page you were looking for isn't available. You can use the navigation bar above to find what you are looking for.",
    description: 'Limited description for error 404 page',
  },
  activationId: {
    id: 'GenericMessages.ActivationId',
    defaultMessage: 'Activation ID ',
    description: 'Label for activation id',
  },
  additionalDetail: {
    id: 'GenericMessages.AdditionalDetail',
    defaultMessage: 'Additional Detail',
    description: 'Label for additional detail',
  },
  search: {
    id: 'GenericMessages.Search',
    defaultMessage: 'search',
    description: 'Label for search',
  },
  close: {
    id: 'GenericMessages.Close',
    defaultMessage: 'close',
    description: 'Label for close',
  },
  update: {
    id: 'GenericMessages.Update',
    defaultMessage: 'update',
    description: 'Label for update',
  },
  submit: {
    id: 'GenericMessages.Submit',
    defaultMessage: 'submit',
    description: 'Label for submit',
  },
  continue: {
    id: 'GenericMessages.Continue',
    defaultMessage: 'continue',
    description: 'Label for continue',
  },
  previous: {
    id: 'GenericMessages.Previous',
    defaultMessage: 'previous',
    description: 'Label for previous',
  },
  automatic: {
    id: 'GenericMessages.Automatic',
    defaultMessage: 'automatic',
    description: 'Label for automatic',
  },
  manual: {
    id: 'GenericMessages.Manual',
    defaultMessage: 'manual',
    description: 'Label for manual',
  },
  raised: {
    id: 'Frontend.CampaignShow.Raised',
    defaultMessage: 'Raised',
    description: 'label for raised',
  },
  raise: {
    id: 'Frontend.CauseFEShow.Raise',
    defaultMessage: 'Raise {amount}',
    description: 'label for raise',
  },
  goal: {
    id: 'CampaignProgressBar.Labels.Goal',
    defaultMessage: 'Goal',
    description: 'Label for goal',
  },
  benefitting: {
    id: 'GenericMessages.Benefitting',
    defaultMessage: 'Benefitting',
    description: 'Label for benefitting',
  },
  benefittingName: {
    id: 'GenericMessages.BenefittingName',
    defaultMessage: 'Benefitting {name}',
    description: 'Label for benefitting name',
  },
  sending: {
    id: 'Authentication.Signup.Sending',
    defaultMessage: 'Sending',
    description: 'Button text when sending signup submit',
  },
  basic: {
    id: 'Reports.BasicSearch',
    defaultMessage: 'basic search',
    description: 'Label for basic search',
  },
  advanced: {
    id: 'Reports.AdvancedSearch',
    defaultMessage: 'advanced search',
    description: 'Label for advanced search',
  },
  csv: {
    id: 'Reports.ExportCSV',
    defaultMessage: 'export csv',
    description: 'Label for export csv button',
  },
  xlsx: {
    id: 'Reports.ExportXLSX',
    defaultMessage: 'export xlsx',
    description: 'Label for export xlsx button',
  },
  userInfo: {
    id: 'Authentication.Signup.UserInfo',
    defaultMessage: 'Organizational Representative Information',
    description: 'Heading for user info',
  },
  heading: {
    id: 'Authentication.Signup.Heading',
    defaultMessage: 'Register your Charity',
    description: 'Heading for cause signup.',
  },
  touch: {
    id: 'Authentication.Signup.GetInTocuh',
    defaultMessage: 'Get in touch',
    description: 'Button for signup submit',
  },
  charityInfo: {
    id: 'Authentication.Signup.CharityInfo',
    defaultMessage: 'Charity information',
    description: 'Heading for charity info',
  },
  admin: {
    id: 'IconNav.Admin',
    defaultMessage: 'Admin',
    description: 'The label for the admin nav link.',
  },
  events: {
    id: 'IconNav.Events',
    defaultMessage: 'Events',
    description: 'The label for the events nav link.',
  },
  reporting: {
    id: 'IconNav.Reporting',
    defaultMessage: 'Reporting',
    description: 'The label for the reporting nav link.',
  },
  donationMethods: {
    id: 'IconNav.DonationMethods',
    defaultMessage: 'Donation methods',
    description: 'The label for the donation methods nav link.',
  },
  integrations: {
    id: 'IconNav.Integrations',
    defaultMessage: 'Integrations',
    description: 'The label for the integrations nav link.',
  },
  myAccount: {
    id: 'IconNav.MyAccount',
    defaultMessage: 'My account',
    description: 'The label for the My Account nav link.',
  },
  loginHeading: {
    id: 'MyAccount.LoginHeading',
    defaultMessage: 'Login Details',
    description: 'Heading for my account login section',
  },
  loginDescription: {
    id: 'MyAccount.LoginDescription',
    defaultMessage: 'Change your login email address and password',
    description: 'Description for my account login section',
  },
  detailHeading: {
    id: 'MyAccount.PersonalHeading',
    defaultMessage: 'Personal details',
    description: 'Heading for my account detail section',
  },
  detailDescription: {
    id: 'MyAccount.PersonalDescription',
    defaultMessage: 'Update the account details for your personal account.',
    description: 'Description for my account detail section',
  },
  emailHeading: {
    id: 'MyAccount.EmailHeading',
    defaultMessage: 'Email',
    description: 'Heading for my account email section',
  },
  socialHeading: {
    id: 'CauseAdmin.SocialHeading',
    defaultMessage: 'Social links',
    description: 'Heading text for the cause admin social section',
  },
  visibilityLeaderboardRangeAll: {
    id: 'CauseAdmin.VisibilityLeaderboardRangeAll',
    defaultMessage: 'All Time',
    description: 'Option in the Leaderboard Date Range input',
  },
  visibilityLeaderboardRangeYearToDate: {
    id: 'CauseAdmin.VisibilityLeaderboardRangeYearToDate',
    defaultMessage: 'Year to Date',
    description: 'Option in the Leaderboard Date Range input',
  },
  globalRewards: {
    id: 'CauseAdmin.GlobalRewards',
    defaultMessage: 'Cause rewards',
    description: 'Heading text for the cause rewards feature section',
  },
  globalRewardManage: {
    id: 'CauseAdmin.GlobalRewardManage',
    defaultMessage: 'Cause reward',
    description: 'Heading text for the cause rewards feature section',
  },
  monthlyGivingRewards: {
    id: 'CauseAdmin.MonthlyGivingRewards',
    defaultMessage: 'Monthly giving rewards',
    description: 'Heading text for the monthly giving rewards feature section',
  },
  incentive: {
    id: 'CauseAdmin.Incentive',
    defaultMessage: 'Incentive',
    description: 'Heading text for the incentives edit section',
  },
  regions: {
    id: 'CauseAdmin.Regions',
    defaultMessage: 'Regions',
    description: 'Heading text for the regions section',
  },
  customAnalytics: {
    id: 'CauseAdmin.CustomAnalytics',
    defaultMessage: 'Custom Analytics',
    description: 'Heading text for the custom analytics section',
  },
  mailingList: {
    id: 'CauseAdmin.MailingList',
    defaultMessage: 'Mailing List',
    description: 'Heading text for the mailing list section',
  },
  sync: {
    id: 'CauseAdmin.Sync',
    defaultMessage: 'Sync',
    description: 'Button text for the mailing list sync',
  },
  brandHeading: {
    id: 'EventAdmin.BrandHeading',
    defaultMessage: 'Design',
    description: 'Heading text for the cause admin brand section',
  },
  registrationServiceHours: {
    id: 'CauseAdmin.RegistrationServiceHours',
    defaultMessage: 'Eligible for student service hours?',
    description: 'Label text for the registation service hours input',
  },
  registrationPersonalInformation: {
    id: 'CauseAdmin.RegistrationPersonalInformation',
    defaultMessage: 'Personal information',
    description: 'Header text for the personal information registration fields',
  },
  fundraisingEventsHeader: {
    id: 'EventAdmin.FundraisingEventsHeader',
    defaultMessage: 'Fundraising events',
    description: 'Header text for the fundraising even list section',
  },
  duration: {
    id: 'Reports.Duration',
    defaultMessage: 'Duration',
    description: 'Label for duration',
  },
  campaignCount: {
    id: 'Reports.CampaignCount',
    defaultMessage: 'Campaign Count',
    description: 'Label for campaign count',
  },
  createReward: {
    id: 'GlobalRewards.CreateReward',
    defaultMessage: 'create reward',
    description: 'Text for create reward button',
  },
  utcTime: {
    id: 'Reports.UTCTime',
    defaultMessage: '* All dates shown are in UTC',
    description: 'Label letting user know dates shown are in UTC.',
  },
  cancelMonthlyDonation: {
    id: 'CauseAdmin.CancelMonthlyDonation',
    defaultMessage: 'Cancel monthly donation',
    description: 'Button text for cancelling a monthly donation.',
  },
  monthlyDonationCanceled: {
    id: 'CauseAdmin.MonthlyDonationCanceled',
    defaultMessage: 'Monthly Donation Cancelled',
    description: 'Status text when successfully cancelling a monthly donation.',
  },
  cancelled: {
    id: 'CauseAdmin.Cancelled',
    defaultMessage: 'Cancelled',
    description: 'Status text when cancelled.',
  },
  pending: {
    id: 'CauseAdmin.Pending',
    defaultMessage: 'Pending',
    description: 'Status text when pending.',
  },
  publishedDate: {
    id: 'CauseAdmin.PublishedDate',
    defaultMessage: 'Published date',
    description: 'Label text for the published date.',
  },
  retiredDate: {
    id: 'CauseAdmin.RetiredDate',
    defaultMessage: 'Retired date',
    description: 'Label text for the retired date.',
  },
  donationCount: {
    id: 'CauseAdmin.DonationCount',
    defaultMessage: 'Donation count',
    description: 'Label text for the donation count.',
  },
  finalGoal: {
    id: 'CauseAdmin.FinalGoal',
    defaultMessage: 'Final goal',
    description: 'Label text for the final goal.',
  },
  originalGoal: {
    id: 'CauseAdmin.OriginalGoal',
    defaultMessage: 'Original goal',
    description: 'Label text for the original goal.',
  },
  unpublish: {
    id: 'CauseAdmin.Unpublish',
    defaultMessage: 'unpublish',
    description: 'Button text to unpublish item.',
  },
  publish: {
    id: 'CauseAdmin.Publish',
    defaultMessage: 'publish',
    description: 'Button text to publish item.',
  },
  previewAction: {
    id: 'GenericMessages.PreviewAction',
    defaultMessage: 'preview',
    description: 'Button text to preview item.',
  },
  supportAction: {
    id: 'GenericMessages.SupportAction',
    defaultMessage: 'support',
    description: 'Button text to suppor event',
  },
  retire: {
    id: 'CauseAdmin.Retire',
    defaultMessage: 'retire',
    description: 'Button text to retire item.',
  },
  activate: {
    id: 'CauseAdmin.Activate',
    defaultMessage: 'activate',
    description: 'Button text to activate item.',
  },
  revoke: {
    id: 'GenericMessages.Revoke',
    defaultMessage: 'revoke',
    description: 'Button text to revoke item.',
  },
  generate: {
    id: 'GenericMessages.Generate',
    defaultMessage: 'generate',
    description: 'Button text to generate item.',
  },
  deactivate: {
    id: 'CauseAdmin.Deactivate',
    defaultMessage: 'deactivate',
    description: 'Button text to deactivate item.',
  },
  delete: {
    id: 'CauseAdmin.Delete',
    defaultMessage: 'delete',
    description: 'Button text to delete item.',
  },
  field: {
    id: 'CauseAdmin.Field',
    defaultMessage: 'Field',
    description: 'Label text for field.',
  },
  fields: {
    id: 'CauseAdmin.Fields',
    defaultMessage: 'Fields',
    description: 'Label text for fields.',
  },
  information: {
    id: 'CauseAdmin.Information',
    defaultMessage: 'Information',
    description: 'Label text for information.',
  },
  title: {
    id: 'CauseAdmin.Title',
    defaultMessage: 'Title',
    description: 'Label text for title.',
  },
  reportGenerated: {
    id: 'CauseAdmin.ReportGenerated',
    defaultMessage: 'Report generated',
    description: 'Status text when report was successfully generated.',
  },
  donations: {
    id: 'CauseAdmin.Donations',
    defaultMessage: 'Donations',
    description: 'Label for donations',
  },
  leaderboard: {
    id: 'GenericMessages.Leaderboard',
    defaultMessage: 'Leaderboard',
    description: 'Label for leaderboard',
  },
  monthlyGivingDonations: {
    id: 'CauseAdmin.MonthlyGivingDonations',
    defaultMessage: 'Monthly giving donations',
    description: 'Label for monthly giving donations',
  },
  registrations: {
    id: 'CauseAdmin.Registrations',
    defaultMessage: 'Registrations',
    description: 'Label for event registrations',
  },
  registrationsDescription: {
    id: 'CauseAdmin.RegistrationsDescription',
    defaultMessage: 'View and manage Fundraising Events you are registered for here.',
    description: 'Description for event registrations',
  },
  missingRegistrations: {
    id: 'CauseAdmin.MissingRegistrations',
    defaultMessage: 'Missing Registrations',
    description: 'Label for missing event registrations',
  },
  missingRegistrationsDescription: {
    id: 'CauseAdmin.MissingRegistrationsDescription',
    defaultMessage: 'View and manage missing Fundraising Events you are registered for here.',
    description: 'Description for missing event registrations',
  },
  social: {
    id: 'CauseAdmin.Social',
    defaultMessage: 'Social',
    description: 'Label for social links',
  },
  contact: {
    id: 'CauseAdmin.Contact',
    defaultMessage: 'Contact',
    description: 'Label for contact',
  },
  manualDonation: {
    id: 'CauseDonation.ManualDonation',
    defaultMessage: 'Manual Donations',
    description: 'Label for Manual Donations',
  },
  createPublicManualDonation: {
    id: 'CauseDonation.CreatePublicManualDonation',
    defaultMessage: 'Create public donation',
    description: 'Label for Create public donation modal',
  },
  createPrivateManualDonation: {
    id: 'CauseDonation.CreatePrivateManualDonation',
    defaultMessage: 'Create private donation',
    description: 'Label for Create Private donation modal',
  },
  fundraisingEventSelectHeader: {
    id: 'CauseDonation.FundraisingEventSelectHeader',
    defaultMessage: 'Fundraising Events',
    description: 'Label for Fundraising Events header',
  },
  fundraisingEventSelectDescription: {
    id: 'CauseDonation.FundraisingEventSelectDescription',
    defaultMessage: 'Choose a fundraising event to create a manual donation for',
    description: 'Label for Fundraising Events description',
  },
  campaignSelectHeader: {
    id: 'CauseDonation.CampaignSelectHeader',
    defaultMessage: 'Campaigns',
    description: 'Label for campaigns header',
  },
  campaignSelectDescription: {
    id: 'CauseDonation.CampaignSelectDescription',
    defaultMessage: 'Choose a campaign to create a manual donation for',
    description: 'Label for campaigns description',
  },
  manage: {
    id: 'CauseAdmin.Manage',
    defaultMessage: 'Manage',
    description: 'Label for manage',
  },
  features: {
    id: 'CauseAdmin.Features',
    defaultMessage: 'Features',
    description: 'Label for features',
  },
  detail: {
    id: 'CauseAdmin.Detail',
    defaultMessage: 'Detail',
    description: 'Label for detail',
  },
  overview: {
    id: 'CauseAdmin.Overview',
    defaultMessage: 'Overview',
    description: 'Label for overview',
  },
  overlays: {
    id: 'CauseAdmin.Overlays',
    defaultMessage: 'Overlays',
    description: 'Label for overlays',
  },
  registration: {
    id: 'CauseAdmin.Registration',
    defaultMessage: 'Registration',
    description: 'Label for registration',
  },
  completed: {
    id: 'CauseAdmin.Completed',
    defaultMessage: 'Completed',
    description: 'Label for completed item',
  },
  unpublished: {
    id: 'CauseAdmin.Unpublished',
    defaultMessage: 'Unpublished',
    description: 'Label for unpublished item',
  },
  published: {
    id: 'CauseAdmin.Published',
    defaultMessage: 'Published',
    description: 'Label for Published item',
  },
  retired: {
    id: 'CauseAdmin.Retired',
    defaultMessage: 'Retired',
    description: 'Label for Retired item',
  },
  publishSettings: {
    id: 'CauseAdmin.PublishSettings',
    defaultMessage: 'Publish settings',
    description: 'Label for publish settings',
  },
  connected: {
    id: 'CauseAdmin.Connected',
    defaultMessage: 'connected',
    description: 'Label for connected item',
  },
  connecting: {
    id: 'CauseAdmin.Connecting',
    defaultMessage: 'connecting',
    description: 'Label for connecting item',
  },
  connect: {
    id: 'CauseAdmin.Connect',
    defaultMessage: 'connect',
    description: 'Label for connect item',
  },
  genericId: {
    id: 'CauseAdmin.GenericId',
    defaultMessage: 'ID',
    description: 'Label for generic id',
  },
  disconnect: {
    id: 'CauseAdmin.Disconnect',
    defaultMessage: 'disconnect',
    description: 'Label for disconnect',
  },
  instructions: {
    id: 'CauseAdmin.Instructions',
    defaultMessage: 'Instructions',
    description: 'Label for instructions',
  },
  apiAccess: {
    id: 'CauseAdmin.ApiAccess',
    defaultMessage: 'API Access',
    description: 'Label for API access',
  },
  currentlyConnectedApplications: {
    id: 'CauseAdmin.CurrentlyConnectedApplications',
    defaultMessage: 'These are your currently connected applications',
    description: 'Label for connected application',
  },
  credentials: {
    id: 'CauseAdmin.Credentials',
    defaultMessage: 'Credentials',
    description: 'Label for credentials',
  },
  clientId: {
    id: 'CauseAdmin.ClientId',
    defaultMessage: 'Client Id',
    description: 'Label for client id',
  },
  secret: {
    id: 'CauseAdmin.Secret',
    defaultMessage: 'Secret',
    description: 'Label for Secret',
  },
  connections: {
    id: 'CauseAdmin.Connections',
    defaultMessage: 'Connections',
    description: 'Label for Connections',
  },
  noData: {
    id: 'CauseAdmin.NoData',
    defaultMessage: 'No Data',
    description: 'Label when there is no data',
  },
  nowLive: {
    id: 'CauseAdmin.NowLive',
    defaultMessage: 'Now Live',
    description: 'Label when event is live',
  },
  totalAmountRaised: {
    id: 'UserTotal.TotalAmountRaised',
    defaultMessage: 'Total amount raised',
    description: 'The label next to the total amount raised amount for a user.',
  },
  amountRaised: {
    id: 'UserTotal.AmountRaised',
    defaultMessage: 'Amount raised',
    description: 'The label next to the amount raised amount for an fe.',
  },
  teamRaised: {
    id: 'Profile.TeamRaised',
    defaultMessage: 'Team Raised',
    description: 'The heading text for how much a team has raised',
  },
  viewAll: {
    id: 'Profile.ViewAll',
    defaultMessage: 'view all ({count})',
    description: 'Text for view all button',
  },
  startFundraising: {
    id: 'Frontend.HomePage.StartFundraising',
    defaultMessage: 'start fundraising',
    description: 'Button text for start fundraising',
  },
  startFundraisingSentenceCase: {
    id: 'Frontend.HomePage.StartFundraisingSentenceCase',
    defaultMessage: 'Start fundraising',
    description: 'Button text for start fundraising in sentence case',
  },
  subject: {
    id: 'Frontend.CampaignShow.Subject',
    defaultMessage: 'Subject',
    description: 'Label text for the subject input',
  },
  name: {
    id: 'Frontend.CampaignShow.Name',
    defaultMessage: 'Your Name',
    description: 'Label text for the name input',
  },
  question: {
    id: 'Frontend.CampaignShow.Question',
    defaultMessage: 'Question/comment',
    description: 'Label text for the question/comment input',
  },
  send: {
    id: 'Frontend.CampaignShow.Send',
    defaultMessage: 'Send Message',
    description: 'Text for the send message button',
  },
  readMore: {
    id: 'Frontend.CampaignShow.ReadMore',
    defaultMessage: 'read more',
    description: 'Text for the Read More button',
  },
  hide: {
    id: 'Frontend.CampaignShow.Hide',
    defaultMessage: 'hide',
    description: 'Text for the Hide button',
  },
  noDonations: {
    id: 'NoDonations.NoDonations',
    defaultMessage: 'No donations',
    description: 'The label to show there are no donations towards the campaign.',
  },
  viewMore: {
    id: 'Frontend.CampaignShow.ViewMore',
    defaultMessage: 'view more',
    description: 'Text for view more supporting campaign button',
  },
  topDonor: {
    id: 'TopDonation.TopDonor',
    defaultMessage: 'Top donor',
    description: 'The label to identify the top donor.',
  },
  topDonation: {
    id: 'GenericMessages.TopDonation',
    defaultMessage: 'Top Donation',
    description: 'The label to identify the top donation.',
  },
  newDonation: {
    id: 'GenericMessages.NewDonation',
    defaultMessage: 'New Donation!',
    description: 'The label to identify the new donation.',
  },
  lastDonation: {
    id: 'GenericMessages.lastDonation',
    defaultMessage: 'Last Donation',
    description: 'The label to identify the latest donation.',
  },
  contactUs: {
    id: 'CauseFEShow.ContactUs',
    defaultMessage: 'contact us',
    description: 'Button text for contact us',
  },
  accept: {
    id: 'Frontend.TeamInvite.Accept',
    defaultMessage: 'accept',
    description: 'Button label for accepting a pending team invite',
  },
  registrationClosed: {
    id: 'Frontend.CauseFE.RegistrationClosed',
    defaultMessage: 'Registration closed for this event',
    description: 'Text display when registration for a fundraising event is closed.',
  },
  startedOn: {
    id: 'GenericMessages.StartedOn',
    defaultMessage: 'Started on',
    description: 'Label for when sustained donation began.',
  },
  donorsRaisedThroughMG: {
    id: 'GenericMessages.DonorsRaisedThroughMG',
    defaultMessage: 'donors raised {mgAmount} through monthly giving',
    description: 'Label for when sustained donation began.',
  },
  unlimited: {
    id: 'GenericMessages.Unlimited',
    defaultMessage: 'Unlimited',
    description: 'Label for when rewards has no defined quantity.',
  },
  schedule: {
    id: 'GenericMessages.Schedule',
    defaultMessage: 'Schedule',
    description: 'Label for the schedule management page.',
  },
  milestones: {
    id: 'GenericMessages.Milestones',
    defaultMessage: 'Milestones',
    description: 'Label for the milestones management page.',
  },
  milestone: {
    id: 'GenericMessages.Milestone',
    defaultMessage: 'Milestone',
    description: 'Heading text for milestone edit page',
  },
  eventMilestone: {
    id: 'GenericMessages.EventMilestone',
    defaultMessage: 'event milestone',
    description: 'Tag text for an event milestone',
  },
  addresses: {
    id: 'GenericMessages.Addresses',
    defaultMessage: 'Addresses',
    description: 'Section heading for user addresses',
  },
  startsAt: {
    id: 'GenericMessages.StartsAt',
    defaultMessage: 'Starts at',
    description: 'Label for when an incentive ends',
  },
  endsAt: {
    id: 'GenericMessages.EndsAt',
    defaultMessage: 'Ends at',
    description: 'Label for when an incentive ends',
  },
  crypto: {
    id: 'GenericMessages.Crypto',
    defaultMessage: 'Cryptocurrency',
    description: 'Label for cryptocurrency',
  },
  today: {
    id: 'GenericMessages.Today',
    defaultMessage: 'Today',
    description: 'Label for amount of donations occurred today',
  },
  thisWeek: {
    id: 'GenericMessages.ThisWeek',
    defaultMessage: 'This week',
    description: 'Label for amount of donations occurred this week',
  },
  averageRaised: {
    id: 'GenericMessages.AverageRaised',
    defaultMessage: 'Avg. raised per campaign',
    description: 'Label for avarage amount of donations raised per campaign',
  },
  connectedAccounts: {
    id: 'GenericMessages.ConnectedAccounts',
    defaultMessage: 'Connected accounts',
    description: 'Label for connected oauth accounts',
  },
  personal: {
    id: 'GenericMessages.Personal',
    defaultMessage: 'Personal',
    description: 'Label for personal details',
  },
  clickToReveal: {
    id: 'GenericMessages.ClickToReveal',
    defaultMessage: 'This is blurred to protect your privacy. Click to reveal.',
    description: 'Text covering hidden personal sections',
  },
  causeName: {
    id: 'GenericMessages.CauseName',
    defaultMessage: 'Cause name',
    description: 'Label text for the cause name column',
  },
  fundraisingEventName: {
    id: 'GenericMessages.FundraisingEventName',
    defaultMessage: 'Fundraising event name',
    description: 'Label text for the fundraising event name column',
  },
  campaignName: {
    id: 'GenericMessages.CampaignName',
    defaultMessage: 'Campaign name',
    description: 'Label text for the campaign name column',
  },
  copiedToClipboard: {
    id: 'GenericMessages.CopiedToClipboard',
    defaultMessage: 'Copied to clipboard',
    description: 'Label text for the successful copy to clipboard',
  },
  uniqueIdentifier: {
    id: 'StartFlow.UniqueIdentifier',
    defaultMessage: 'Unique identifier',
    description: 'Label for unique identifier input',
  },
  unlockGlobalRewards: {
    id: 'CauseAdmin.UnlockGlobalRewards',
    defaultMessage: 'Global rewards',
    description: 'Label text for global reward feature',
  },
  messageNotFound: {
    id: 'GenericMessage.messageNotFound',
    defaultMessage: 'Message not found',
    description: 'Status text displayed when a message cannot be loaded',
  },
  createdOn: {
    id: 'GenericMessage.CreatedOn',
    defaultMessage: 'Created on',
    description: 'Label text for created on field',
  },
  message: {
    id: 'GenericMessage.Message',
    defaultMessage: 'Message',
    description: 'Label text for message field',
  },
  done: {
    id: 'GenericMessage.Done',
    defaultMessage: 'done',
    description: 'Button text for completing an action',
  },
  approve: {
    id: 'GenericMessage.Approve',
    defaultMessage: 'approve',
    description: 'Button text for approving an item',
  },
  deny: {
    id: 'GenericMessage.Deny',
    defaultMessage: 'deny',
    description: 'Button text for denying an item',
  },
  downloadToolkitHeading: {
    id: 'GenericMessage.DownloadToolkitHeading',
    defaultMessage: 'Download our fundraiser toolkit',
    description: 'Heading for download toolkit section',
  },
  toolkitDescription: {
    id: 'GenericMessage.ToolkitDescription',
    defaultMessage:
      'View our toolkit to help get you started with talking points, fundraising tips, Tiltify tutorials and other content to help make your fundraiser a success.',
    description: 'Description for toolkit section',
  },
  downloadToolkit: {
    id: 'GenericMessage.DownloadToolkit',
    defaultMessage: 'download toolkit',
    description: 'Button text for download toolkit section',
  },
  or: {
    id: 'Separator.Or',
    defaultMessage: 'or',
    description: 'The message displayed between two potential options.',
  },
  dismiss: {
    id: 'GenericMessages.Dismiss',
    defaultMessage: 'dismiss',
    description: 'Button text to dismiss a panel',
  },
  copyLink: {
    id: 'GenericMessages.CopyLink',
    defaultMessage: 'copy link',
    description: 'Button text to copy a link',
  },
  approachingMilestone: {
    id: 'GenericMessage.ApproachingMilestone',
    defaultMessage: 'Approaching a milestone! At',
    description: 'Button text for download toolkit section',
  },
  users: {
    id: 'GenericMessage.Users',
    defaultMessage: 'Users',
    description: 'Label text for users section',
  },
  redemptionCodes: {
    id: 'GenericMessage.RedemptionCodes',
    defaultMessage: 'Redemption codes',
    description: 'Heading text for the activation reward code management feature section',
  },
  settingUpdated: {
    id: 'GenericMessage.SettingUpdated',
    defaultMessage: 'Setting updated',
    description: 'Status text displayed when a setting has been updated.',
  },
  recommended: {
    id: 'GenericMessage.Recommended',
    defaultMessage: 'Recommended',
    description: 'Generic recommended text.',
  },
  customData: {
    id: 'GenericMessage.CustomData',
    defaultMessage: 'Custom Data',
    description: 'Heading text for custom data elements',
  },
  keys: {
    id: 'GenericMessage.Keys',
    defaultMessage: '{count, plural, one {Key} other {Keys}}',
    description: 'Heading text for keys elements',
  },
  showKeys: {
    id: 'GenericMessage.ShowKeys',
    defaultMessage: 'show keys',
    description: 'Button text to display keys',
  },
  hideKeys: {
    id: 'GenericMessage.HideKeys',
    defaultMessage: 'hide keys',
    description: 'Button text to hide keys',
  },
  download: {
    id: 'GenericMessage.Download',
    defaultMessage: 'Download',
    description: 'Label text to download',
  },
  markAllAsRead: {
    id: 'GenericMessage.MarkAllAsRead',
    defaultMessage: 'mark all as read',
    description: 'Button text for mark all as read',
  },
  markAsRead: {
    id: 'GenericMessage.MarkAsRead',
    defaultMessage: 'Mark as read',
    description: 'Label text for mark as read',
  },
  markAsUnread: {
    id: 'GenericMessage.MarkAsUnread',
    defaultMessage: 'Mark as unread',
    description: 'Label text for mark as unread',
  },
  readStatusUpdated: {
    id: 'GenericMessage.ReadStatusUpdated',
    defaultMessage: 'Read status updated',
    description: 'Label text for read status updated',
  },
  readAllStatusUpdated: {
    id: 'GenericMessage.ReadAllStatusUpdated',
    defaultMessage:
      '{number, plural, one {# donation marked as read} other {# donations marked as read}}',
    description: 'Label text for read status updated',
  },
  read: {
    id: 'GenericMessage.Read',
    defaultMessage: 'Read',
    description: 'Label text for read status',
  },
  unread: {
    id: 'GenericMessage.Unread',
    defaultMessage: 'Unread',
    description: 'Label text for unread status',
  },
  dateDescending: {
    id: 'GenericMessage.DateDescending',
    defaultMessage: 'date desc',
    description: 'Label text for date descending',
  },
  dateAscending: {
    id: 'GenericMessage.DateAscending',
    defaultMessage: 'date asc',
    description: 'Label text for date ascending',
  },
  sortBy: {
    id: 'GenericMessage.SortBy',
    defaultMessage: 'Sort by',
    description: 'Description for sort by select option',
  },
  sortAmountAsc: {
    id: 'GenericMessage.SortAmountAsc',
    defaultMessage: 'amount asc',
    description: 'Description for amount ascending select option',
  },
  sortAmountdesc: {
    id: 'GenericMessage.SortAmountdesc',
    defaultMessage: 'amount desc',
    description: 'Description for amount descending select option',
  },
  grandTotal: {
    id: 'GenericMessage.GrandTotal',
    defaultMessage: 'Grand Total',
    description: 'Label text for the Grand Total of a campaign',
  },
  view: {
    id: 'GenericMessage.View',
    defaultMessage: 'View',
    description: 'Label text for the view input',
  },
  loading: {
    id: 'PageLoading.Loading',
    defaultMessage: 'Loading',
    description: 'The label displayed when a page is loading.',
  },
  optional: {
    id: 'GenericMessage.Optional',
    defaultMessage: '(optional)',
    description: 'The label displayed when an is optional.',
  },
  viewAllLink: {
    id: 'GenericMessage.ViewAll',
    defaultMessage: 'view all',
    description: 'label text for the view all link',
  },
  fitness: {
    id: 'GenericMessage.Fitness',
    defaultMessage: 'Fitness',
    description: 'label text for the fitness element',
  },
})

export const giftAidMessages = defineMessages({
  taxRelief: {
    id: 'GiftAid.taxRelief',
    defaultMessage:
      'By ticking the below, you confirm that you are a UK taxpayer and understand that if you pay less income / capital gains tax than the amount of gift aid claimed on all the donations you make to charity in the tax year in which they are received, it is your responsibility to pay any difference.',
    description: 'Displays for charities with a presence in the UK and have gift aide enabled.',
  },
  learnMore: {
    id: 'GiftAid.Modal.LearnMore',
    defaultMessage: 'Learn More',
    description: 'Button that opens modal of more info about gift aid',
  },
  givingFund: {
    id: 'DonateFlow.Summary.GivingFund',
    defaultMessage: 'Gift Aid your donation.',
    description: 'Label for Gift Aid checkbox.',
  },
  canadaCreditClaimDescriptionOne: {
    id: 'GiftAid.CanadaCreditClaimDescriptionOne',
    defaultMessage:
      '<a>The</a> <b>Charitable Donations Tax Credit (CDTC)</b> <c>is available to any Canadian tax payer who makes a donation to a qualifying donee.</c>',
    description: 'P1 description for canada credit claim.',
  },
  canadaCreditClaimDescriptionTwo: {
    id: 'GiftAid.CanadaCreditClaimDescriptionTwo',
    defaultMessage:
      'The CDTC can be up to 33 percent of the amount you donated at the federal level. You may also be entitled to an additional amount reaching up to 24 percent of your donation depending on your province of residence.',
    description: 'P2 description for canada credit claim.',
  },
  canadaCreditClaim: {
    id: 'GiftAid.CanadaCreditClaim',
    defaultMessage: 'Add detail to claim Canadian Charitable Donations Tax Credit',
    description: 'Label for canada credit checkbox.',
  },
  canadaCreditClaimRequired: {
    id: 'GiftAid.CanadaCreditClaimRequired',
    defaultMessage:
      'Shipping address is required to claim Canadian Charitable Donations Tax Credit',
    description: 'Label for canada credit checkbox.',
  },
  generalClaimDescription: {
    id: 'GiftAid.GeneralClaimDescription',
    defaultMessage:
      'If you would like to provide further detail to the charity for this donation please fill out the information below.',
    description: 'Description for general request shipping information.',
  },
  generalClaim: {
    id: 'GiftAid.GeneralClaim',
    defaultMessage: 'I would like to provide a physical address on this donation',
    description: 'Label for general checkbox.',
  },
  generalClaimRequired: {
    id: 'GiftAid.GeneralClaimRequired',
    defaultMessage: 'Shipping Address is required',
    description: 'Label for general checkbox.',
  },
  taxpayer: {
    id: 'GiftAid.Modal.WhenYouDonateYou',
    defaultMessage:
      'Information on how to assure that your Gift Aid declaration is compliant in line with HMRC guidance can be viewed on the HMRC website here:',
    description: 'Describes gift aid pt.2',
  },
  giftAid: {
    id: 'GiftAid.Modal.GiftAidIsAnIncomeTaxRelief',
    defaultMessage:
      'By agreeing to Gift Aid, you are also agreeing to Tiltify passing your personal details to the charity to allow them to claim Gift Aid',
    description: 'Describes gift aid pt.1',
  },
  shippingRequired: {
    id: 'GiftAid.ShippingRequired',
    defaultMessage: 'Gift Aid requires a UK address.',
    description: 'Label for gift aid address section',
  },
  qualifications: {
    id: 'GiftAid.Qualifications',
    defaultMessage: 'To get tax relief your charity must be:',
    description: 'Label for gift aid qualifications',
  },
  based: {
    id: 'GiftAid.Based',
    defaultMessage: 'based in the UK, EU, Iceland, Liechtenstein or Norway',
    description: 'Label for gift aid requirement',
  },
  establishedForCharity: {
    id: 'GiftAid.EstablishedForCharity',
    defaultMessage: 'established for charitable purposes only',
    description: 'Label for gift aid requirement',
  },
  registeredWith: {
    id: 'GiftAid.RegisteredWith',
    defaultMessage:
      'registered with the Charity Commission or another regulator (if this applies to your organisation)',
    description: 'Label for gift aid requirement',
  },
  runBy: {
    id: 'GiftAid.RunBy',
    defaultMessage: 'run by ‘fit and proper persons’',
    description: 'Label for gift aid requirement',
  },
  recognizedBy: {
    id: 'GiftAid.RecognizedBy',
    defaultMessage: 'recognized by HM Revenue and Customs (HMRC)',
    description: 'Label for gift aid requirement',
  },
  purpose: {
    id: 'GiftAid.Purpose',
    defaultMessage:
      'Gift Aid allows UK based charities and other eligible organizations to reclaim tax on a donation made by a UK taxpayer, which means donations are worth 25% more to the charity at no extra cost to the donor.',
    description: 'Label for gift aid requirement',
  },
  singapore: {
    id: 'GiftAid.Singapore',
    defaultMessage: 'Singapore',
    description: 'Label for singapore requirement',
  },
  canada: {
    id: 'GiftAid.Canada',
    defaultMessage: 'Canadian Charitable Donations Tax Credit',
    description: 'Label for canada requirement',
  },
  general: {
    id: 'GiftAid.General',
    defaultMessage: 'General Request',
    description: 'Label for general requirement',
  },
})

export const incentiveMessages = defineMessages({
  notSelected: {
    id: 'DonateFlow.Incentive.NotSelected',
    defaultMessage: 'Not Selected',
    description: 'Text for when an incentive has not been selected.',
  },
  change: {
    id: 'DonateFlow.Summary.Change',
    defaultMessage: 'change',
    description: 'Text for button to change an incentive.',
  },
  remove: {
    id: 'DonateFlow.Summary.Remove',
    defaultMessage: 'remove',
    description: 'Text for button to remove an incentive.',
  },
  productScheduleHeading: {
    id: 'Frontend.HomePage.ProductScheduleHeading',
    defaultMessage: 'Schedules',
    description: 'Heading for Schedules card description',
  },
})

export const givingFundMessages = defineMessages({
  p1: {
    id: 'GivingFund.Paragraph1',
    defaultMessage:
      '<a>Your donation will be made to PayPal Giving Fund, a 501(c)(3) charity, and is subject to PayPal Giving Fund’s policies</a> <b>and terms of service</b> <c>for donors.</c>',
    description: 'Giving fund paragraph 1',
  },
  p2: {
    id: 'GivingFund.Paragraph2',
    defaultMessage:
      'Donations are subject to a 5% platform fee, and PayPal Giving Fund makes grants to benefitting charities minus those fees and payment processing fees of 1.99% + $0.49.',
    description: 'Giving fund paragraph 2',
  },
  p3: {
    id: 'GivingFund.Paragraph3',
    defaultMessage:
      '<a>The charity you recommend will typically receive the funds within </a><b>15-45 days</b><c> of your original donation, which is typically tax deductible.</c>',
    description: 'Giving fund paragraph 3',
  },
  p4: {
    id: 'GivingFund.Paragraph4',
    defaultMessage:
      '<a>In the rare event PPGF</a> <b>can’t fund your chosen charity</b><c>, it will (whenever possible) ask you to recommend another, and grant the funds to a similar charity if you don’t respond.</c>',
    description: 'Giving fund paragraph 4',
  },
})

export const searchMessages = defineMessages({
  causeSearchPlaceHolder: {
    id: 'Frontend.HomePage.CauseSearchPlaceHolder',
    defaultMessage: 'type 3 letters to start searching',
    description: 'Place holder text for cause search',
  },
  heroSearchHeading: {
    id: 'Frontend.HomePage.HeroSearchHeading',
    defaultMessage: 'Causes',
    description: 'Hero search heading hero',
  },
  heroSearchSeeMoreHeading: {
    id: 'Frontend.HomePage.HeroSearchSeeMoreHeading',
    defaultMessage: 'view all causes',
    description: 'Hero search see more link',
  },
  noTeams: {
    id: 'Frontend.Explore.NoTeams',
    defaultMessage: 'No teams found',
    description: 'Label for empty team result',
  },
  noUsers: {
    id: 'Frontend.Explore.NoUsers',
    defaultMessage: 'No users found',
    description: 'Label for empty user result',
  },
  noCauses: {
    id: 'Frontend.Explore.NoCauses',
    defaultMessage: 'No causes found',
    description: 'Label for empty cause result',
  },
  noCampaigns: {
    id: 'Frontend.Explore.NoCampaigns',
    defaultMessage: 'No campaigns found',
    description: 'Label for empty campaign result',
  },
  noResults: {
    id: 'Frontend.Explore.NoResults',
    defaultMessage: 'No results found',
    description: 'heading for no results',
  },
  campaigns: {
    id: 'Frontend.Explore.Campaigns',
    defaultMessage: 'Campaigns',
    description: 'Header for trending campaign list',
  },
  causesAndEventsHeading: {
    id: 'Frontend.Explore.CausesAndEventsHeading',
    defaultMessage: 'Causes and Events',
    description: 'Header for explore cause/event list',
  },
  viewAllFundraisingOpportunities: {
    id: 'Frontend.Explore.ViewAllFundraisingOpportunities',
    defaultMessage: 'view all fundraising opportunities',
    description: 'Header for explore campaign/team list',
  },
  teams: {
    id: 'Frontend.Explore.Teams',
    defaultMessage: 'Teams',
    description: 'Header for trending teams list',
  },
  viewAllCampaigns: {
    id: 'Frontend.Explore.ViewAllCampaigns',
    defaultMessage: 'view all campaigns',
    description: 'Link text to view all campaigns',
  },
  viewAllTeams: {
    id: 'Frontend.Explore.ViewAllTeams',
    defaultMessage: 'view all teams',
    description: 'Link text to view all teams',
  },
  searchPlaceholder: {
    id: 'Frontend.Explore.SearchPlaceholder',
    defaultMessage: 'type to start searching',
    description: 'Placeholder for search input',
  },
  causesAndEvents: {
    id: 'Frontend.Explore.CausesAndEvents',
    defaultMessage: 'Causes/Events',
    description: 'Search filter for causes and events',
  },
  campaignsAndTeams: {
    id: 'Frontend.Explore.CampaignsAndTeams',
    defaultMessage: 'Campaigns/Teams',
    description: 'Search filter for campaigns and teams',
  },
  exploreCommunity: {
    id: 'Frontend.Explore.ExploreCommunity',
    defaultMessage: 'Explore our community',
    description: 'Search filter label',
  },
  getStarted: {
    id: 'Frontend.Explore.GetStarted',
    defaultMessage: 'Get Started!',
    description: 'Search input label',
  },
})

export const socialMessages = defineMessages({
  twitch: {
    id: 'Social.Twitch',
    defaultMessage: 'Twitch',
    description: 'Label for the twitch social input.',
  },
  facebook: {
    id: 'CauseAdmin.Facebook',
    defaultMessage: 'Facebook',
    description: 'Label text for Facebok input',
  },
  twitter: {
    id: 'CauseAdmin.Twitter',
    defaultMessage: 'Twitter',
    description: 'Label text for Twitter input',
  },
  website: {
    id: 'CauseAdmin.Website',
    defaultMessage: 'Website',
    description: 'Label text for Website input',
  },
  discord: {
    id: 'CauseAdmin.Discord',
    defaultMessage: 'Discord invite link',
    description: 'Label text for Discord Invite Link input',
  },
  instagram: {
    id: 'CauseAdmin.Instagram',
    defaultMessage: 'Instagram',
    description: 'Label text for Instagram input',
  },
  snapchat: {
    id: 'CauseAdmin.Snapchat',
    defaultMessage: 'Snapchat',
    description: 'Label text for Snapchat input',
  },
  youtube: {
    id: 'CauseAdmin.YouTube',
    defaultMessage: 'YouTube',
    description: 'Label text for YouTube input',
  },
  tiktok: {
    id: 'CauseAdmin.Tiktok',
    defaultMessage: 'TikTok',
    description: 'Label text for TikTok input',
  },
  discordName: {
    id: 'CauseAdmin.DiscordName',
    defaultMessage: 'Discord',
    description: 'Label text for Discord input',
  },
})

export const messagingMessages = defineMessages({
  emailFrequencySectionHeading: {
    id: 'MessagingMessages.EmailFrequencySectionHeading',
    defaultMessage: 'Email Preferences',
    description: 'Heading for the email frequecy section.',
  },
  emailFrequencySectionDescription: {
    id: 'MessagingMessages.EmailFrequencySectionDescription',
    defaultMessage:
      'Update your Messaging Center Preferences to stay better informed of what is happening for your charity via email',
    description: 'Heading for the email frequecy section.',
  },
  messageCenterPreferencesSectionHeading: {
    id: 'MessagingMessages.MessageCenterPreferencesSectionHeading',
    defaultMessage: 'Messaging Center Preferences',
    description: 'Heading for the email frequecy section.',
  },
  messageCenterPreferencesSectionDescription: {
    id: 'MessagingMessages.MessageCenterPreferencesSectionDescription',
    defaultMessage:
      'Update your Messaging Center Preferences to stay better informed of what is happening for your charity from within the Tiltify Admin Dashboard',
    description: 'Heading for the email frequecy section.',
  },
  allMessagesHeading: {
    id: 'MessagingMessages.AllMessagesHeading',
    defaultMessage: 'All messages',
    description: 'Heading for the All messages section.',
  },
  allMessagesDescription: {
    id: 'MessagingMessages.AllMessagesDescription',
    defaultMessage:
      'Set the frequency of how often you receive all the messages below. This will override the frequencies selected below.',
    description: 'Description for the All messages section.',
  },
  newMessages: {
    id: 'MessagingMessages.NewMessages',
    defaultMessage: 'New Messages',
    description: 'Heading for the New Messages dropdown',
  },
  viewAll: {
    id: 'MessagingMessages.ViewAll',
    defaultMessage: 'View All',
    description: 'label text for the View All link',
  },
  emailFrequencyHeading: {
    id: 'MessagingMessages.EmailFrequencyHeading',
    defaultMessage: 'Email frequency',
    description: 'Heading for the email frequency section.',
  },
  emailFrequencyDescription: {
    id: 'MessagingMessages.EmailFrequencyDescription',
    defaultMessage: 'How often do you want to receive emails',
    description: 'Descriptiong for the email frequency section',
  },
  campaignsCreatedHeading: {
    id: 'MessagingMessages.CampaignsCreatedHeading',
    defaultMessage: 'No. of campaigns created',
    description: 'Heading for the No. of campaigns created section.',
  },
  campaignsCreatedDescription: {
    id: 'MessagingMessages.CampaignsCreatedDescription',
    defaultMessage:
      'Set the frequency of how often you receive the number of campaigns that have been created for your events',
    description: 'Description for the No. of campaigns created section.',
  },
  campaignsPublishedHeading: {
    id: 'MessagingMessages.CampaignsPublishedHeading',
    defaultMessage: 'No. of campaigns published',
    description: 'Heading for the No. of campaigns published section.',
  },
  campaignsPublishedDescription: {
    id: 'MessagingMessages.CampaignsPublishedDescription',
    defaultMessage:
      'Set the frequency of how often you receive the number of campaigns that have been published for your events',
    description: 'Description for the No. of campaigns published section.',
  },
  never: {
    id: 'MessagingMessages.Never',
    defaultMessage: 'never',
    description: 'Label for the never select option',
  },
  daily: {
    id: 'MessagingMessages.Daily',
    defaultMessage: 'daily',
    description: 'Label for the daily select option',
  },
  weekly: {
    id: 'MessagingMessages.Weekly',
    defaultMessage: 'weekly',
    description: 'Label for the weekly select option',
  },
  monthly: {
    id: 'MessagingMessages.Monthly',
    defaultMessage: 'monthly',
    description: 'Label for the monthly select option',
  },
  noMessages: {
    id: 'MessagingMessages.noMessages',
    defaultMessage: 'No Messages',
    description: 'Label for no messages',
  },
})

export const validationMessages = defineMessages({
  required: {
    id: 'Validation.Required.Required',
    defaultMessage: 'Required',
    description: 'The text displayed when a required input field has no value.',
  },
  emailInvalid: {
    id: 'Validation.Email.Invalid',
    defaultMessage: 'Email is invalid.',
    description: 'The text displayed when the email address entered is not formatted correctly.',
  },
  urlInvalid: {
    id: 'Validation.Url.Invalid',
    defaultMessage: 'Url is invalid, make sure https is included.',
    description: 'The text displayed when the url entered is not formatted correctly.',
  },
  uuidInvalid: {
    id: 'Validation.Uuid.Invalid',
    defaultMessage: 'UUID is invalid.',
    description: 'The text displayed when the UUID entered is not formatted correctly.',
  },
  minimum: {
    id: 'Validation.Minimum.Minimum',
    defaultMessage: 'Must be at least {min}',
    description: 'The text displayed when the minimum amount has not been entered.',
  },
  range: {
    id: 'Validation.Range',
    defaultMessage: 'Must be between {min} and {max}',
    description: 'Text displayed when the value is not between a given range',
  },
  maxLength: {
    id: 'Validation.MaxLength',
    defaultMessage: 'Must be less than {max} characters',
    description: 'Text displayed when the value is not less than a given length',
  },
  minLength: {
    id: 'Validation.MinLength.MinLength',
    defaultMessage: 'Must be at least {min} characters',
    description: 'The text displayed when the minimum length has not been entered.',
  },
  upperCase: {
    id: 'Validation.UpperCase',
    defaultMessage: 'A capital (uppercase) letter',
    description: 'The text displayed when an uppercase letter has not been entered.',
  },
  lowerCase: {
    id: 'Validation.LowerCase',
    defaultMessage: 'A lowercase letter',
    description: 'The text displayed when an lowercase letter has not been entered.',
  },
  symbol: {
    id: 'Validation.Symbol',
    defaultMessage: 'A special character [!@#$%^&*]',
    description: 'The text displayed when an symbol has not been entered.',
  },
  number: {
    id: 'Validation.Number',
    defaultMessage: 'A number',
    description: 'The text displayed when an number has not been entered.',
  },
  passwordConfirmation: {
    id: 'Validation.PasswordConfirmation',
    defaultMessage: 'Passwords do not match.',
    description: 'The text displayed when a password does not match the password confirmation',
  },
  twoSelected: {
    id: 'GlobalRewards.TwoSelected',
    defaultMessage: 'Must at least have 2 options selected (or none)',
    description: 'Error message when required to select 2 or more options ',
  },
  slugTaken: {
    id: 'CauseAdmin.SlugTaken',
    defaultMessage: 'Slug is already being used',
    description: 'Error message when slug has been taken',
  },
  mustContainLetterNumber: {
    id: 'Validation.MustContainLetterNumber',
    defaultMessage: 'Must include letters and at least 1 number',
    description: 'Error message when slug has been taken',
  },
  causePassword: {
    id: 'Validation.causePassword',
    defaultMessage:
      'Must include upper and lowercase letters, a special character, and at least 1 number',
    description: 'Error message when cause password is not secure enough',
  },
  oneYearValidation: {
    id: 'Validation.OneYearValidation',
    defaultMessage: 'Dates must be within one year.',
    description: 'Validation error when date range exceeds one year',
  },
  startDateFirstValidation: {
    id: 'Validation.StartDateFirstValidation',
    defaultMessage: 'Must be before the end date.',
    description: 'Validation error when start date is after end date',
  },
})

export const optionMessages = defineMessages({
  styles: {
    id: 'GlobalRewards.Styles',
    defaultMessage: 'Styles',
    description: 'Label for styles input',
  },
  selectFit: {
    id: 'GlobalRewards.SelectFit',
    defaultMessage: 'Select Fit',
    description: 'Label for selecting a fit option',
  },
  unisex: {
    id: 'GlobalRewards.Unisex',
    defaultMessage: 'Unisex',
    description: 'Label for unisex style',
  },
  mens: {
    id: 'GlobalRewards.Mens',
    defaultMessage: 'Mens',
    description: 'Label for mens style',
  },
  womens: {
    id: 'GlobalRewards.Womens',
    defaultMessage: 'Womens',
    description: 'Label for womens style',
  },
  addSize: {
    id: 'GlobalRewards.AddSize',
    defaultMessage: 'add size',
    description: 'Text for add size button',
  },
  addAllSizes: {
    id: 'GlobalRewards.AddAllSizes',
    defaultMessage: 'add all sizes',
    description: 'Text for add all sizes button',
  },
  selectAllSizes: {
    id: 'GlobalRewards.SelectAllSizes',
    defaultMessage: 'Select all sizes',
    description: 'Text for select all sizes button',
  },
  xs: {
    id: 'GlobalRewards.xs',
    defaultMessage: 'XS',
    description: 'Label for extra small size',
  },
  small: {
    id: 'GlobalRewards.Small',
    defaultMessage: 'Small',
    description: 'Label for small size',
  },
  medium: {
    id: 'GlobalRewards.Medium',
    defaultMessage: 'Medium',
    description: 'Label for medium size',
  },
  large: {
    id: 'GlobalRewards.Large',
    defaultMessage: 'Large',
    description: 'Label for large size',
  },
  xl: {
    id: 'GlobalRewards.XL',
    defaultMessage: 'XL',
    description: 'Label for xl size',
  },
  xxl: {
    id: 'GlobalRewards.XXL',
    defaultMessage: 'XXL',
    description: 'Label for xxl size',
  },
  twoxl: {
    id: 'GlobalRewards.2XL',
    defaultMessage: '2XL',
    description: 'Label for 2xl size',
  },
  threexl: {
    id: 'GlobalRewards.3XL',
    defaultMessage: '3XL',
    description: 'Label for 3xl size',
  },
  fourxl: {
    id: 'GlobalRewards.4XL',
    defaultMessage: '4XL',
    description: 'Label for 4xl size',
  },
  fivexl: {
    id: 'GlobalRewards.5XL',
    defaultMessage: '5XL',
    description: 'Label for 5xl size',
  },
  womensSmall: {
    id: 'GlobalRewards.WomensSmall',
    defaultMessage: 'Womens Small',
    description: 'Label for small size',
  },
  womensMedium: {
    id: 'GlobalRewards.WomensMedium',
    defaultMessage: 'Womens Medium',
    description: 'Label for medium size',
  },
  womensLarge: {
    id: 'GlobalRewards.WomensLarge',
    defaultMessage: 'Womens Large',
    description: 'Label for large size',
  },
  womensXl: {
    id: 'GlobalRewards.WomensXL',
    defaultMessage: 'Womens XL',
    description: 'Label for xl size',
  },
  womensXxl: {
    id: 'GlobalRewards.WomensXXL',
    defaultMessage: 'Womens XXL',
    description: 'Label for xxl size',
  },
  womensThreexl: {
    id: 'GlobalRewards.Womens3XL',
    defaultMessage: 'Womens 3XL',
    description: 'Label for 3xl size',
  },
  womensFourxl: {
    id: 'GlobalRewards.Womens4XL',
    defaultMessage: 'Womens 4XL',
    description: 'Label for 4xl size',
  },
  womensFivexl: {
    id: 'GlobalRewards.Womens5XL',
    defaultMessage: 'Womens 5XL',
    description: 'Label for 5xl size',
  },
  mensSmall: {
    id: 'GlobalRewards.MensSmall',
    defaultMessage: 'Mens Small',
    description: 'Label for small size',
  },
  mensMedium: {
    id: 'GlobalRewards.MensMedium',
    defaultMessage: 'Mens Medium',
    description: 'Label for medium size',
  },
  mensLarge: {
    id: 'GlobalRewards.MensLarge',
    defaultMessage: 'Mens Large',
    description: 'Label for large size',
  },
  mensXl: {
    id: 'GlobalRewards.MensXL',
    defaultMessage: 'Mens XL',
    description: 'Label for xl size',
  },
  mensXxl: {
    id: 'GlobalRewards.MensXXL',
    defaultMessage: 'Mens XXL',
    description: 'Label for xxl size',
  },
  mensThreexl: {
    id: 'GlobalRewards.Mens3XL',
    defaultMessage: 'Mens 3XL',
    description: 'Label for 3xl size',
  },
  mensFourxl: {
    id: 'GlobalRewards.Mens4XL',
    defaultMessage: 'Mens 4XL',
    description: 'Label for 4xl size',
  },
  mensFivexl: {
    id: 'GlobalRewards.Mens5XL',
    defaultMessage: 'Mens 5XL',
    description: 'Label for 5xl size',
  },
  anotherFit: {
    id: 'GlobalRewards.AnotherFit',
    defaultMessage: '+ another Fit',
    description: 'Label for another fit button',
  },
  deleteFit: {
    id: 'GlobalRewards.DeleteFit',
    defaultMessage: 'delete fit',
    description: 'Label for delete fit button',
  },
  addColor: {
    id: 'GlobalRewards.AddColor',
    defaultMessage: 'add color',
    description: 'Text for add color button',
  },
  addAllColors: {
    id: 'GlobalRewards.AddAllColors',
    defaultMessage: 'add all colors',
    description: 'Text for add all colors button',
  },
  colorBlack: {
    id: 'GlobalRewards.ColorBlack',
    defaultMessage: 'Black',
    description: 'Label for Black shirt color',
  },
  colorWhite: {
    id: 'GlobalRewards.ColorWhite',
    defaultMessage: 'White',
    description: 'Label for White shirt color',
  },
  colorGrey: {
    id: 'GlobalRewards.ColorGrey',
    defaultMessage: 'Grey',
    description: 'Label for Grey shirt color',
  },
  colorTan: {
    id: 'GlobalRewards.ColorTan',
    defaultMessage: 'Tan',
    description: 'Label for Tan shirt color',
  },
  colorPink: {
    id: 'GlobalRewards.ColorPink',
    defaultMessage: 'Pink',
    description: 'Label for Pink shirt color',
  },
  colorYellow: {
    id: 'GlobalRewards.ColorYellow',
    defaultMessage: 'Yellow',
    description: 'Label for Yellow shirt color',
  },
  colorRed: {
    id: 'GlobalRewards.ColorRed',
    defaultMessage: 'Red',
    description: 'Label for Red shirt color',
  },
  colorOrange: {
    id: 'GlobalRewards.ColorOrange',
    defaultMessage: 'Orange',
    description: 'Label for Orange shirt color',
  },
  colorBlue: {
    id: 'GlobalRewards.ColorBlue',
    defaultMessage: 'Blue',
    description: 'Label for Blue shirt color',
  },
  colorGreen: {
    id: 'GlobalRewards.ColorGreen',
    defaultMessage: 'Green',
    description: 'Label for Green shirt color',
  },
  colorPurple: {
    id: 'GlobalRewards.ColorPurple',
    defaultMessage: 'Purple',
    description: 'Label for Purple shirt color',
  },
  colorBrown: {
    id: 'GlobalRewards.ColorBrown',
    defaultMessage: 'Brown',
    description: 'Label for Brown shirt color',
  },
})

export const supportMessages = defineMessages({
  backToHome: {
    id: 'SupportMessages.BackToHome',
    defaultMessage: 'back to support home',
    description: 'The button text to return to the home support page',
  },
  havingTrouble: {
    id: 'SupportMessages.HavingTrouble',
    defaultMessage: 'Having trouble finding an answer?',
    description: 'The label text if a user cannot find an answer',
  },
  noArticles: {
    id: 'SupportMessages.NoArticles',
    defaultMessage: 'No articles',
    description: 'The label text if no articles can be found',
  },
  otherArticles: {
    id: 'SupportMessages.OtherArticles',
    defaultMessage: 'Other related articles',
    description: 'The label text if there are related articles',
  },
  searchForArticle: {
    id: 'SupportMessages.SearchForArticle',
    defaultMessage: 'Search for an article',
    description: 'The placeholder text to search for an article',
  },
  sideSearchPlaceholder: {
    id: 'SupportMessages.SideSearchPlaceholder',
    defaultMessage: 'e.g. how do I add a payment method',
    description: 'The placeholder text to search for an article',
  },
  yourSearch: {
    id: 'SupportMessages.YourSearch',
    defaultMessage: 'Your search',
    description: 'The label text for your search term',
  },
  supportArticles: {
    id: 'SupportMessages.SupportArticles',
    defaultMessage: 'Support Articles',
    description: 'The heading text for the list of support articles',
  },
})

export const uppercaseMessages = defineMessages({
  nowLive: {
    id: 'Uppercase.NowLive',
    defaultMessage: 'NOW LIVE',
    description: 'Uppercase label',
  },
  live: {
    id: 'Uppercase.Live',
    defaultMessage: 'Live',
    description: 'Uppercase live label',
  },
  unpublished: {
    id: 'Uppercase.Unpublished',
    defaultMessage: 'UNPUBLISHED',
    description: 'Uppercase label',
  },
  published: {
    id: 'Uppercase.Published',
    defaultMessage: 'PUBLISHED',
    description: 'Uppercase published label',
  },
  retired: {
    id: 'Uppercase.Retired',
    defaultMessage: 'RETIRED',
    description: 'Uppercase label',
  },
  raised: {
    id: 'Uppercase.Raised',
    defaultMessage: 'RAISED',
    description: 'Uppercase label',
  },
  next: {
    id: 'Uppercase.Next',
    defaultMessage: 'NEXT',
    description: 'Uppercase label',
  },
  promoted: {
    id: 'Uppercase.Promoted',
    defaultMessage: 'PROMOTED',
    description: 'Uppercase label',
  },
})

export const donationPanelMessages = defineMessages({
  scrollToTop: {
    id: 'DonationPanelMessages.ScrollToTop',
    defaultMessage: 'Scroll to top',
    description: 'Label for scroll to top button',
  },
  unseenDonations: {
    id: 'DonationPanelMessages.UnseenDonations',
    defaultMessage: 'New donations!',
    description: 'Label for new donations button',
  },
  secondsAgo: {
    id: 'DonationPanelMessages.SecondsAgo',
    defaultMessage: '{seconds, plural, one {# second ago} other {# seconds ago}}',
    description: 'ending text for timer',
  },
  mins: {
    id: 'DonationPanelMessages.Mins',
    defaultMessage: '{mins, plural, one {# min ago} other {# mins ago}}',
    description: 'abbreviation for multiple minutes',
  },
  hoursAgo: {
    id: 'DonationPanelMessages.HoursAgo',
    defaultMessage: '{hours, plural, one {# hour ago} other {# hours ago}}',
    description: 'ending hour text for timer',
  },
  daysAgo: {
    id: 'DonationPanelMessages.DaysAgo',
    defaultMessage: '{days, plural, one {# day ago} other {# days ago}}',
    description: 'ending days text for timer',
  },
  monthsAgo: {
    id: 'DonationPanelMessages.MonthsAgo',
    defaultMessage: '{months, plural, one {# month ago} other {# months ago}}',
    description: 'ending months text for timer',
  },
  yearsAgo: {
    id: 'DonationPanelMessages.YearsAgo',
    defaultMessage: '{years, plural, one {# year ago} other {# years ago}}',
    description: 'ending years text for timer',
  },
  showDonationsOver: {
    id: 'DonationPanelMessages.ShowDonationsOver',
    defaultMessage: 'Show donations over',
    description: 'Label for donation amount filter',
  },
  highlightDonationsOver: {
    id: 'DonationPanelMessages.HighlightDonationsOver',
    defaultMessage: 'Highlight donations over',
    description: 'Label for donation amount highlighter',
  },
  searchForADonation: {
    id: 'DonationPanelMessages.SearchForADonation',
    defaultMessage: 'search for a donation by name',
    description: 'Label for search for a donation',
  },
  name: {
    id: 'DonationPanelMessages.Name',
    defaultMessage: 'Name',
    description: 'Column header for name',
  },
  amount: {
    id: 'DonationPanelMessages.Amount',
    defaultMessage: 'Amount',
    description: 'Column header for amount',
  },
  time: {
    id: 'DonationPanelMessages.Time',
    defaultMessage: 'Time',
    description: 'Column header for time',
  },
  comment: {
    id: 'DonationPanelMessages.Comment',
    defaultMessage: 'Comment',
    description: 'Column header for comment',
  },
  moderate: {
    id: 'DonationPanelMessages.Moderate',
    defaultMessage: 'Moderate',
    description: 'Column header for moderate',
  },
  moderated: {
    id: 'DonationPanelMessages.Moderated',
    defaultMessage: 'Moderated',
    description: 'Text when item has been moderated',
  },
  incentives: {
    id: 'DonationPanelMessages.Incentives',
    defaultMessage: 'Incentives',
    description: 'Column header for incentives',
  },
  completedAt: {
    id: 'DonationPanelMessages.CompletedAt',
    defaultMessage: 'Completed At',
    description: 'Column header for completed at',
  },
  donationModerated: {
    id: 'DonationPanelMessages.DonationModerated',
    defaultMessage: 'Donation moderated',
    description: 'Notification text for when a donation has been moderated',
  },
  slowMode: {
    id: 'DonationPanelMessages.SlowMode',
    defaultMessage: 'Slow Mode',
    description: 'Label for slow mode toggle button',
  },
  slowModeTitle: {
    id: 'DonationPanelMessages.SlowModeTitle',
    defaultMessage: 'toggles slow mode on. Slow mode is set at 2 seconds',
    description: 'Title for what slow mode does',
  },
  minDonation: {
    id: 'DonationPanelMessages.MinDonation',
    defaultMessage: 'Min donation',
    description: 'Label text for min donation',
  },
})

export const causeFEShowMessages = defineMessages({
  sponsors: {
    id: 'CauseFEShow.Sponsors',
    defaultMessage: 'Sponsors',
    description: 'Heading for the Sponsors section',
  },
  currentFundraisers: {
    id: 'CauseFEShow.CurrentFundraisers',
    defaultMessage: 'Current fundraisers',
    description: 'Heading for the current fundraiser section',
  },
  viewAllFundraisers: {
    id: 'CauseFEShow.ViewAllFundraisers',
    defaultMessage: 'view all {amount} fundraisers',
    description: 'Button text for the view all fundraiser section',
  },
  currentFundraisingEvents: {
    id: 'CauseFEShow.CurrentFundraisingEvents',
    defaultMessage: 'Current fundraising events',
    description: 'Heading for the current fundraising event section',
  },
  viewAllCurrentFundraisingEvents: {
    id: 'CauseFEShow.ViewAllFundraisingEvents',
    defaultMessage: 'view all current fundraising events',
    description: 'Button text for the view all current fundraising event section',
  },
  leaderboardHeading: {
    id: 'CauseFEShow.LeaderboardHeading',
    defaultMessage: 'Our top fundraisers',
    description: 'Leaderboard section heading',
  },
  viewAllLeaderboards: {
    id: 'CauseFEShow.ViewAllLeaderboards',
    defaultMessage: 'view all leaderboards',
    description: 'Button text for the view all leaderboards section',
  },
  fundraiserIncentives: {
    id: 'CauseFEShow.FundraiserIncentives',
    defaultMessage: 'Fundraiser incentives',
    description: 'Heading for the fundraiser incentives section',
  },
  findOutMore: {
    id: 'CauseFEShow.FindOutMore',
    defaultMessage: 'find out more',
    description: 'Button text for find out more',
  },
  register: {
    id: 'CauseFEShow.Register',
    defaultMessage: 'Register',
    description: 'Button text for register',
  },
  registrationClosed: {
    id: 'CauseFEShow.RegistrationClosed',
    defaultMessage: 'Registration Closed',
    description: 'Button text for registration closed',
  },
  aboutName: {
    id: 'CauseFEShow.AboutCause',
    defaultMessage: 'About {name}',
    description: 'Heading for the about cause section',
  },
  featuredVideo: {
    id: 'CauseFEShow.FeaturedVideo',
    defaultMessage: 'Featured Video',
    description: 'Alt text for the featured video',
  },
  featuredImage: {
    id: 'CauseFEShow.FeaturedImage',
    defaultMessage: 'Featured Image',
    description: 'Alt text for the featured image',
  },
  supportUs: {
    id: 'CauseFEShow.SupportUs',
    defaultMessage: 'Ready to support us?',
    description: 'Heading text for the support section',
  },
})

export const addWidgetMessages = defineMessages({
  addWidget: {
    id: 'AddWidget.AddWidget',
    defaultMessage: 'Add Widget',
    description: "Text to add a widget in the editor's sidebar",
  },
  addNewWidget: {
    id: 'AddWidget.AddNewWidget',
    defaultMessage: 'Add new widget',
    description: "Heading text for widget selector's full page modal",
  },
  allCategory: {
    id: 'AddWidget.AllCategory',
    defaultMessage: 'All',
    description: "Text for the 'All' category",
  },
  alertsCategory: {
    id: 'AddWidget.AlertsCategory',
    defaultMessage: 'Alerts',
    description: "Text for the 'Alerts' category",
  },
  incentivesCategory: {
    id: 'AddWidget.IncentivesCategory',
    defaultMessage: 'Incentives',
    description: "Text for the 'Incentives' category",
  },
  allInOneCategory: {
    id: 'AddWidget.AllInOneCategory',
    defaultMessage: 'All-in-one',
    description: "Text for the 'All-in-one' category",
  },
  causeAssetsCategory: {
    id: 'AddWidget.CauseAssetsCategory',
    defaultMessage: 'Cause Assets',
    description: "Text for the 'Cause Assets' category",
  },
  yourProgressCategory: {
    id: 'AddWidget.YourProgressCategory',
    defaultMessage: 'Your Progress',
    description: "Text for the 'Your Progress' category",
  },
  customCategory: {
    id: 'AddWidget.CustomCategory',
    defaultMessage: 'Custom',
    description: "Text for the 'Custom' category",
  },
  alertBoxTitle: {
    id: 'AddWidget.AlertBoxTitle',
    defaultMessage: 'Alert Box',
    description: 'Alert box title text',
  },
  alertBoxDescription: {
    id: 'AddWidget.AlertBoxDescription',
    defaultMessage: 'Real time donation notifications for your fundraiser.',
    description: 'Alert box description text',
  },
  eventListTitle: {
    id: 'AddWidget.EventListTitle',
    defaultMessage: 'Event List',
    description: 'Event list title text',
  },
  eventListDescription: {
    id: 'AddWidget.EventListDescription',
    defaultMessage: 'A list of recent donations during your fundraiser.',
    description: 'Event list description text',
  },
  rewardListTitle: {
    id: 'AddWidget.RewardListTitle',
    defaultMessage: 'Reward List',
    description: 'Reward list title text',
  },
  rewardListDescription: {
    id: 'AddWidget.RewardListDescription',
    defaultMessage: 'Easily customizable list of active rewards.',
    description: 'Reward list description text',
  },
  singleRewardTitle: {
    id: 'AddWidget.SingleRewardTitle',
    defaultMessage: 'Single Reward',
    description: 'Single reward title text',
  },
  singleRewardDescription: {
    id: 'AddWidget.SingleRewardDescription',
    defaultMessage: 'Highlight specific rewards your donors will love!',
    description: 'Single reward description text',
  },
  pollTitle: {
    id: 'AddWidget.PollTitle',
    defaultMessage: 'Poll',
    description: 'Poll title text',
  },
  pollDescription: {
    id: 'AddWidget.PollDescription',
    defaultMessage: 'Display the results of any active poll.',
    description: 'Poll description text',
  },
  headToHeadPollTitle: {
    id: 'AddWidget.HeadToHeadPollTitle',
    defaultMessage: 'Head to Head Poll',
    description: 'Head to head poll title text',
  },
  headToHeadPollDescription: {
    id: 'AddWidget.HeadToHeadPollDescription',
    defaultMessage: 'Show a head to head showdown of any poll that has two options.',
    description: 'Head to Head Poll description text',
  },
  classicOverlayTitle: {
    id: 'AddWidget.ClassicOverlayTitle',
    defaultMessage: 'Classic Overlay',
    description: 'Classic overlay title text',
  },
  classicOverlayDescription: {
    id: 'AddWidget.ClassicOverlayDescription',
    defaultMessage: "Tiltify's classic progress bar!",
    description: 'Classic overlay description text',
  },
  betaOverlayTitle: {
    id: 'AddWidget.BetaOverlayTitle',
    defaultMessage: 'Beta Overlay',
    description: 'Beta overlay title text',
  },
  betaOverlayDescription: {
    id: 'AddWidget.BetaOverlayDescription',
    defaultMessage: 'A progress bar that highlights recent donors.',
    description: 'Beta overlay description text',
  },
  compactOverlayTitle: {
    id: 'AddWidget.CompactOverlayTitle',
    defaultMessage: 'Compact Overlay',
    description: 'Compact overlay title text',
  },
  compactOverlayDescription: {
    id: 'AddWidget.CompactOverlayDescription',
    defaultMessage: 'An advanced progress bar designed by Twaz.',
    description: 'Compact overlay description text',
  },
  bonfireTitle: {
    id: 'AddWidget.BonfireTitle',
    defaultMessage: 'Bonfire Merch Counter',
    description: 'Bonfire title text',
  },
  bonfireDescription: {
    id: 'AddWidget.BonfireDescription',
    defaultMessage: 'Display merch counter for your Bonfire campaign',
    description: 'Bonfire description text',
  },
  causeNameTitle: {
    id: 'AddWidget.CauseNameTitle',
    defaultMessage: 'Cause Name',
    description: 'Cause name title text',
  },
  causeNameDescription: {
    id: 'AddWidget.CauseNameDescription',
    defaultMessage: "Text name of the cause you're supporting.",
    description: 'Cause name description text',
  },
  causeLogoTitle: {
    id: 'AddWidget.CauseLogoTitle',
    defaultMessage: 'Cause Logo',
    description: 'Cause logo title text',
  },
  causeLogoDescription: {
    id: 'AddWidget.CauseLogoDescription',
    defaultMessage: "Image of your cause's logo.",
    description: 'Cause logo description text',
  },
  progressBarTitle: {
    id: 'AddWidget.ProgressBarTitle',
    defaultMessage: 'Progress Bar',
    description: 'Progress bar title text',
  },
  progressBarDescription: {
    id: 'AddWidget.ProgressBarDescription',
    defaultMessage: 'The progress bar you know and love from the Tiltify campaign page.',
    description: 'Progress bar description text',
  },
  customProgressBarTitle: {
    id: 'AddWidget.CustomProgressBarTitle',
    defaultMessage: 'Custom Progress Bar',
    description: 'Custom progress bar title text',
  },
  customProgressBarDescription: {
    id: 'AddWidget.CustomProgressBarDescription',
    defaultMessage: "A highly customizable progress bar, match it to your content's branding.",
    description: 'Custom progress bar description text',
  },
  targetTitle: {
    id: 'AddWidget.TargetTitle',
    defaultMessage: 'Target',
    description: 'Target title text',
  },
  targetDescription: {
    id: 'AddWidget.TargetDescription',
    defaultMessage: 'Show a chosen active target.',
    description: 'Target description text',
  },
  throwbackOneTitle: {
    id: 'AddWidget.ThrowbackOneTitle',
    defaultMessage: 'Throwback Progress Bar One',
    description: 'Throwback progress bar one title text',
  },
  throwbackOneDescription: {
    id: 'AddWidget.ThrowbackOneDescription',
    defaultMessage: 'Watch as our mascot Tilty blasts off with your fundraising goal!',
    description: 'Throwback progress bar one description text',
  },
  throwbackTwoTitle: {
    id: 'AddWidget.ThrowbackTwoTitle',
    defaultMessage: 'Throwback Progress Bar Two',
    description: 'Throwback progress bar two title text',
  },
  throwbackTwoDescription: {
    id: 'AddWidget.ThrowbackTwoDescription',
    defaultMessage: "Tiltify's mascot Tilty blasts off into space as your fundraiser progresses.",
    description: 'Throwback progress bar two description text',
  },
  throwbackMilestoneTitle: {
    id: 'AddWidget.ThrowbackMilestoneTitle',
    defaultMessage: 'Throwback Milestone Tracker',
    description: 'Throwback milestone tracker title text',
  },
  throwbackMilestoneDescription: {
    id: 'AddWidget.ThrowbackMilestoneDescription',
    defaultMessage:
      'Our mascot Tilty cheering you on as he is depicted over a beautiful cityscape.',
    description: 'Throwback milestone tracker description text',
  },
  dynamicTextTitle: {
    id: 'AddWidget.DynamicTextTitle',
    defaultMessage: 'Dynamic Text',
    description: 'Dynamic text title text',
  },
  dynamicTextDescription: {
    id: 'AddWidget.DynamicTextDescription',
    defaultMessage:
      'Highly customizable text that can be used to highlight your total, goal, team total, fundraising event total, and more!',
    description: 'Dynamic text description text',
  },
  qrCodeTitle: {
    id: 'AddWidget.QRCodeTitle',
    defaultMessage: 'QR Code',
    description: 'QR code title text',
  },
  qrCodeDescription: {
    id: 'AddWidget.QRCodeDescription',
    defaultMessage:
      'A QR code that people can scan to donate.  This can also be downloaded from your campaign dashboard.',
    description: 'QR code description text',
  },
  llsAlertTitle: {
    id: 'AddWidget.LLSAlertTitle',
    defaultMessage: 'LLSAlert',
    description: 'LLS Alert title text',
  },
  llsAlertDescription: {
    id: 'AddWidget.LLSAlertDescription',
    defaultMessage: 'Description for LLS Alert',
    description: 'LLS Alert description text',
  },
  llsOverlayTitle: {
    id: 'AddWidget.LLSOverlayTitle',
    defaultMessage: 'LLS Overlay',
    description: 'LLS Overlay title text',
  },
  llsOverlayDescription: {
    id: 'AddWidget.LLSOverlayDescription',
    defaultMessage: 'Description for LLS Overlay',
    description: 'LLS Overlay description text',
  },
  causeMyClauseTitle: {
    id: 'AddWidget.CauseMyClauseTitle',
    defaultMessage: 'Claus my Cause',
    description: 'Cause my Clause title text',
  },
  causeMyClauseDescription: {
    id: 'AddWidget.CauseMyClauseDescription',
    defaultMessage: "Show that you're supporting Claus my Cause with this custom-styled overlay!",
    description: 'Cause my Clause description text',
  },
  unitedWayAlertTitle: {
    id: 'AddWidget.UnitedWayAlertTitle',
    defaultMessage: 'United Way Alert',
    description: 'United way alert title text',
  },
  unitedWayAlertDescription: {
    id: 'AddWidget.UnitedWayAlertDescription',
    defaultMessage:
      "United Way's custom donation alert, just for you and your community to advance the common good",
    description: 'United Way Alert description text',
  },
  unitedWayOverlayTitle: {
    id: 'AddWidget.UnitedWayOverlayTitle',
    defaultMessage: 'United Way Progress Bar',
    description: 'United way Overlay title text',
  },
  unitedWayOverlayDescription: {
    id: 'AddWidget.UnitedWayOverlayDescription',
    defaultMessage:
      'A powerful widget that will highlight recent donations & the impact your community will have around the world',
    description: 'United Way Overlay description text',
  },
  trevorProjectOverlayTitle: {
    id: 'AddWidget.TrevorProjectOverlayTitle',
    defaultMessage: 'Trevor Project Overlay',
    description: 'Trevor project overlay title text',
  },
  trevorProjectOverlayDescription: {
    id: 'AddWidget.TrevorProjectOverlayDescription',
    defaultMessage:
      "Show that you're supporting The Trevor Project with this custom-styled overlay!",
    description: 'United Way Overlay description text',
  },
  schfOverlayTitle: {
    id: 'AddWidget.SCHFOverlayTitle',
    defaultMessage: 'SCHF Overlay',
    description: 'SCHF overlay title text',
  },
  schfOverlayDescription: {
    id: 'AddWidget.SCHFOverlayDescription',
    defaultMessage:
      "Show that you're supporting Sydney Children's Hospitals Foundation with this custom-styled overlay!",
    description: "Sydney Children's Hospitals Foundation description text",
  },
  ahaOverlayTitle: {
    id: 'AddWidget.AHAOverlayTitle',
    defaultMessage: 'AHA Overlay',
    description: 'AHA Overlay title text',
  },
  ahaOverlayDescription: {
    id: 'AddWidget.AHAOverlayDescription',
    defaultMessage:
      "Show that you're supporting the American Heart Association with this custom-styled overlay!",
    description: 'AHA Overlay description text',
  },
  hsusOverlayTitle: {
    id: 'AddWidget.HSUSOverlayTitle',
    defaultMessage: 'HSUS Overlay',
    description: 'HSUS Overlay title text',
  },
  hsusOverlayDescription: {
    id: 'AddWidget.HSUSOverlayDescription',
    defaultMessage:
      "Show that you're supporting The Humane Society of the United States with this custom-styled overlay!",
    description: 'HSUS Overlay description text',
  },
  acsOverlayTitle: {
    id: 'AddWidget.ACSOverlayTitle',
    defaultMessage: 'ACS Overlay',
    description: 'ACS Overlay title text',
  },
  acsOverlayDescription: {
    id: 'AddWidget.ACSOverlayDescription',
    defaultMessage:
      "Show that you're supporting the American Cancer Society with this custom-styled overlay!",
    description: 'ACS Overlay description text',
  },
  halloweenOverlayTitle: {
    id: 'AddWidget.HalloweenOverlayTitle',
    defaultMessage: 'Halloween Overlay',
    description: 'Halloween overlay title',
  },
  halloweenOverlayDescription: {
    id: 'AddWidget.HalloweenOverlayDescription',
    defaultMessage:
      "Enter the spooky season with this Halloween overlay! It's perfect for your Halloween fundraiser.",
    description: 'Halloween Overlay description text',
  },
  hsusRescueOverlayTitle: {
    id: 'AddWidget.HSUSRescueOverlayTitle',
    defaultMessage: 'HSUS Overlay',
    description: 'HSUS Rescue Overlay title text',
  },
  hsusRescueOverlayDescription: {
    id: 'AddWidget.HSUSRescueOverlayDescription',
    defaultMessage:
      'Let your viewers know you are supporting The Humane Society of the United States with this custom overlay.',
    description: 'HSUS Rescue Overlay description text',
  },
  leaderboardOverlayTitle: {
    id: 'AddWidget.LeaderboardOverlayTitle',
    defaultMessage: 'Donor Leaderboard',
    description: 'Leaderboard Overlay title text',
  },
  leaderboardOverlayDescription: {
    id: 'AddWidget.LeaderboardOverlayDescription',
    defaultMessage: 'A leaderboard of your top donors!',
    description: 'Leaderboard Overlay description text',
  },
  afspOverlayTitle: {
    id: 'AddWidget.AFSPOverlayTitle',
    defaultMessage: 'AFSP Overlay',
    description: 'AFSP Overlay title text',
  },
  afspOverlayDescription: {
    id: 'AddWidget.AFSPOverlayDescription',
    defaultMessage:
      'This custom overlay is for the American Foundation for Suicide Prevention! Use this to show your support!',
    description: 'AFSP Overlay description text',
  },
  redCrossOverlayTitle: {
    id: 'AddWidget.RedCrossOverlayTitle',
    defaultMessage: 'American Red Cross Overlay',
    description: 'Red Cross Overlay title text',
  },
  redCrossOverlayDescription: {
    id: 'AddWidget.RedCrossOverlayDescription',
    defaultMessage:
      'Let your viewers know you are supporting the American Red Cross with this custom overlay.',
    description: 'Red Cross Overlay description text',
  },
  movemberOverlayTitle: {
    id: 'AddWidget.MovemberOverlayTitle',
    defaultMessage: 'Movember Overlay',
    description: 'Movember Overlay title text',
  },
  movemberOverlayDescription: {
    id: 'AddWidget.MovemberOverlayDescription',
    defaultMessage: "Show that you're supporting Movember with this custom-styled overlay!",
    description: 'Movember Overlay description text',
  },
  jingleJamProgressBarTitle: {
    id: 'AddWidget.JingleJamProgressBarTitle',
    defaultMessage: 'Jingle Jam Progress Bar',
    description: 'Jingle Jam Progress Bar title text',
  },
  jingleJamAlertTitle: {
    id: 'AddWidget.JingleJamAlertTitle',
    defaultMessage: 'Jingle Jam Alert',
    description: 'Jingle Jam Alert title text',
  },
  jingleJamProgressBarDescription: {
    id: 'AddWidget.JingleJamProgressBarDescription',
    defaultMessage:
      'Let your viewers know you are supporting Jingle Jam with this custom progress bar.',
    description: 'Jingle Jam progress bar description text',
  },
  jingleJamAlertDescription: {
    id: 'AddWidget.JingleJamAlertDescription',
    defaultMessage: 'Let your viewers know you are supporting Jingle Jam with this custom alert.',
    description: 'Jingle Jam alert description text',
  },
  redEvergreenOverlayTitle: {
    id: 'AddWidget.RedEvergreenOverlayTitle',
    defaultMessage: '(RED) Overlay',
    description: '(RED) Overlay title text',
  },
  redEvergreenOverlayDescription: {
    id: 'AddWidget.RedEvergreenOverlayDescription',
    defaultMessage: 'Let your viewers know you are supporting (RED) with this custom overlay.',
    description: '(RED) Overlay description text',
  },
  redEventOverlayTitle: {
    id: 'AddWidget.RedEventOverlayTitle',
    defaultMessage: '(RED) Creator Cup Overlay',
    description: '(RED) Creator Cup Overlay title text',
  },
  redEventOverlayDescription: {
    id: 'AddWidget.RedEventOverlayDescription',
    defaultMessage:
      "Show that you're supporting the (RED) Creator Cup with this custom-styled overlay!",
    description: '(RED) Creator Cup Overlay description text',
  },
  pbtfOverlayTitle: {
    id: 'AddWidget.PBTFOverlayTitle',
    defaultMessage: 'PBTF Overlay',
    description: 'PBTF Overlay title text',
  },
  pbtfOverlayDescription: {
    id: 'AddWidget.PBTFOverlayDescription',
    defaultMessage:
      "Show that you're supporting the Pediatric Brain Tumor Foundation with this custom-styled overlay!",
    description: 'PBTF Overlay description text',
  },
  thankmasOverlayTitle: {
    id: 'AddWidget.ThankmasOverlayTitle',
    defaultMessage: 'Thankmas Overlay',
    description: 'Thankmas Overlay title text',
  },
  thankmasOverlayDescription: {
    id: 'AddWidget.ThankmasOverlayDescription',
    defaultMessage: 'Let your viewers know you are supporting Thankmas with this custom overlay.',
    description: 'Thankmas Overlay description text',
  },
  bestFriendsOverlayTitle: {
    id: 'AddWidget.BestFriendsOverlayTitle',
    defaultMessage: 'Best Friends Overlay',
    description: 'Best Friends Overlay title text',
  },
  bestFriendsOverlayDescription: {
    id: 'AddWidget.BestFriendsOverlayDescription',
    defaultMessage:
      'Let your viewers know you are supporting Best Friends Animal Society with this custom overlay.',
    description: 'Best Friends Overlay description text',
  },
  efaOverlayTitle: {
    id: 'AddWidget.EFAOverlayTitle',
    defaultMessage: 'EFA Overlay',
    description: 'EFA Overlay title text',
  },
  efaOverlayDescription: {
    id: 'AddWidget.EFAOverlayDescription',
    defaultMessage:
      'Let your viewers know you are supporting the Epilepsy Foundation of America with this custom overlay.',
    description: 'EFA Overlay description text',
  },
  su2cOverlayTitle: {
    id: 'AddWidget.SU2COverlayTitle',
    defaultMessage: 'SU2C Overlay',
    description: 'SU2C Overlay title text',
  },
  su2cOverlayDescription: {
    id: 'AddWidget.SU2COverlayDescription',
    defaultMessage:
      "Show that you're supporting Stand Up to Cancer with this custom-styled overlay!",
    description: 'SU2C Overlay description text',
  },
  mindOverlayTitle: {
    id: 'AddWidget.MindOverlayTitle',
    defaultMessage: 'Mind Overlay',
    description: 'Mind Overlay title text',
  },
  mindOverlayDescription: {
    id: 'AddWidget.MindOverlayDescription',
    defaultMessage: 'This custom overlay is for Mind! Use this to show your support!',
    description: 'Mind Overlay description text',
  },
  ignOverlayTitle: {
    id: 'AddWidget.IGNOverlayTitle',
    defaultMessage: 'IGN Overlay',
    description: 'IGN Overlay title text',
  },
  ignOverlayDescription: {
    id: 'AddWidget.IGNOverlayDescription',
    defaultMessage: 'This custom overlay is for IGN! Use this to show your support!',
    description: 'IGN Overlay description text',
  },
  ignAlertTitle: {
    id: 'AddWidget.IGNAlertTitle',
    defaultMessage: 'IGN Alert',
    description: 'IGN Overlay title text',
  },
  ignAlertDescription: {
    id: 'AddWidget.IGNAlertDescription',
    defaultMessage:
      "IGN's custom donation alert, just for you and your community to advance the common good",
    description: 'IGN Overlay description text',
  },
  jdrfAlertTitle: {
    id: 'AddWidget.JDRFAlertTitle',
    defaultMessage: 'JDRF Alert',
    description: 'JDRF Alert title text',
  },
  jdrfAlertDescription: {
    id: 'AddWidget.JDRFAlertDescription',
    defaultMessage:
      "JDRF's custom donation alert, just for you and your community to advance the common good",
    description: 'JDRF Alert description text',
  },
  wwpOverlayTitle: {
    id: 'AddWidget.WWPOverlayTitle',
    defaultMessage: 'WWP Overlay',
    description: 'WWP Overlay title text',
  },
  wwpOverlayDescription: {
    id: 'AddWidget.WWPOverlayDescription',
    defaultMessage:
      'This custom overlay is for Wounded Warrior Project! Use this to show your support!',
    description: 'WWP Overlay description text',
  },
  makeAWishOverlayTitle: {
    id: 'AddWidget.MakeAWishOverlayTitle',
    defaultMessage: 'Make-A-Wish Overlay',
    description: 'Make a Wish Overlay title text',
  },
  makeAWishOverlayDescription: {
    id: 'AddWidget.MakeAWishOverlayDescription',
    defaultMessage: 'This custom overlay is for Make-A-Wish! Use this to show your support!',
    description: 'Make a Wish Overlay description text',
  },
  p4aOverlayTitle: {
    id: 'AddWidget.P4AOverlayTitle',
    defaultMessage: 'P4A Overlay',
    description: 'P4A Overlay title text',
  },
  p4aOverlayDescription: {
    id: 'AddWidget.P4AOverlayDescription',
    defaultMessage:
      "Show that you're supporting Project for Awesome with this custom-styled overlay!",
    description: 'P4A Overlay description text',
  },
  crfOverlayTitle: {
    id: 'AddWidget.CRFOverlayTitle',
    defaultMessage: 'CRF Overlay',
    description: 'CRF Overlay title text',
  },
  crfOverlayDescription: {
    id: 'AddWidget.CRFOverlayDescription',
    defaultMessage:
      "Show that you're supporting the Critical Role Foundation with this custom-styled overlay!",
    description: 'CRF Overlay description text',
  },
  crukAlertTitle: {
    id: 'AddWidget.CRUKAlertTitle',
    defaultMessage: 'Cancer Research UK Alert',
    description: 'CRUK Overlay title text',
  },
  crukAlertDescription: {
    id: 'AddWidget.CRUKAlertDescription',
    defaultMessage: "Show that you're supporting Cancer Research UK with this custom-styled alert!",
    description: 'CRF Overlay description text',
  },
  crukProgressBarTitle: {
    id: 'AddWidget.CRUKProgressBarTitle',
    defaultMessage: 'Cancer Research UK Progress Bar',
    description: 'CRUK Progress Bar title text',
  },
  crukProgressBarDescription: {
    id: 'AddWidget.CRUKProgressBarDescription',
    defaultMessage:
      "Show that you're supporting Cancer Research UK with this custom-styled progress bar!",
    description: 'CRF Overlay description text',
  },
  crukTopDonorTitle: {
    id: 'AddWidget.CRUKTopDonorTitle',
    defaultMessage: 'Cancer Research UK Top/Last Donor',
    description: 'CRUK top/last donor title text',
  },
  crukTopDonorDescription: {
    id: 'AddWidget.CRUKTopDonorDescription',
    defaultMessage:
      "Show that you're supporting Cancer Research UK with this custom-styled top/last donor widget!",
    description: 'CRF Overlay description text',
  },
  ufpOverlayTitle: {
    id: 'AddWidget.UFPOverlayTitle',
    defaultMessage: 'Unified for Pride Overlay',
    description: 'Unified for Pride Overlay title text',
  },
  ufpOverlayDescription: {
    id: 'AddWidget.UFPOverlayDescription',
    defaultMessage: 'This custom overlay is for Unified for Pride! Use this to show your support!',
    description: 'Unified for Pride Overlay description text',
  },
  matchListTitle: {
    id: 'AddWidget.MatchListTitle',
    defaultMessage: 'Donation Matches',
    description: 'Donation matches title text',
  },
  matchListDescription: {
    id: 'AddWidget.MatchListDescription',
    defaultMessage: 'Shows all currently active donation matches',
    description: 'Match list description text',
  },
  auctionItemListTitle: {
    id: 'AddWidget.AuctionItemListTitle',
    defaultMessage: 'Auction Items',
    description: 'Auction Items title text',
  },
  auctionItemListDescription: {
    id: 'AddWidget.AuctionItemListDescription',
    defaultMessage: 'Shows all auction items',
    description: 'Auction item list description text',
  },
  auctionItemAlertTitle: {
    id: 'AddWidget.AuctionItemAlertTitle',
    defaultMessage: 'Auction Item Alert',
    description: 'Auction Item Alert title text',
  },
  auctionItemAlertDescription: {
    id: 'AddWidget.AuctionItemAlertDescription',
    defaultMessage: 'Keep track of the current winning bidder of an auction item',
    description: 'Match Alert description text',
  },
  auctionHouseTotalsTitle: {
    id: 'AddWidget.AuctionHouseTotalsTitle',
    defaultMessage: 'Auction House Totals',
    description: 'Auction Items title text',
  },
  auctionHouseTotalsDescription: {
    id: 'AddWidget.AuctionHouseTotalsDescription',
    defaultMessage: 'Shows totals for an auction house',
    description: 'Match list description text',
  },
})

export const permissionMessages = defineMessages({
  causeAdmin: {
    id: 'CauseAdmin.CauseAdmin',
    defaultMessage: 'cause admin',
    description: 'Label for cause admin role',
  },
  causeManager: {
    id: 'CauseAdmin.CauseManager',
    defaultMessage: 'cause manager',
    description: 'Label for cause manager role',
  },
  causeFinance: {
    id: 'CauseAdmin.CauseFinance',
    defaultMessage: 'cause finance',
    description: 'Label for cause finance role',
  },
  causeReporting: {
    id: 'CauseAdmin.CauseReporting',
    defaultMessage: 'cause reporting',
    description: 'Label for cause reporting role',
  },
  causeDonation: {
    id: 'CauseAdmin.CauseDonation',
    defaultMessage: 'cause donation',
    description: 'Label for cause donation role',
  },
  feManager: {
    id: 'CauseAdmin.CauseFEManager',
    defaultMessage: 'fundraising event manager',
    description: 'Label for fundraising event manager role',
  },
  feAdmin: {
    id: 'CauseAdmin.CauseFEAdmin',
    defaultMessage: 'fundraising event admin',
    description: 'Label for fundraising event admin role',
  },
})
