import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Avatar } from '../Avatar'
import { StyledStreamEmpty } from './styles'

const StyledStream = styled.div`
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  padding-top: 56.25%;
`

const StyledStreamImage = styled.img<{ borderRadius: number }>`
  background-size: 100%;
  width: 100%;
  display: block;
  height: 100%;
  border-radius: ${({ borderRadius }) => `${borderRadius}px ${borderRadius}px 0px 0px`};
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
`

const StyledMutedAvatar = styled(Avatar)`
  opacity: 0.3;
  ${({ borderRadius }) =>
    (borderRadius || borderRadius === 0) && `border-radius: ${borderRadius}px;`}
`

const AvatarWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const NewStreamCard = ({
  campaign,
  loading,
  overrideDefault,
  borderRadius,
  ...rest
}: any) => {
  const campaignImage = campaign?.cardImage?.src
  const defaultAvatar =
    campaign?.avatar?.src || campaign?.team?.avatar?.src || campaign?.user?.avatar?.src

  if (loading) {
    return (
      <StyledStream>
        <StyledStreamEmpty>
          <Skeleton height={150} />
        </StyledStreamEmpty>
      </StyledStream>
    )
  }

  return (
    <StyledStream {...rest}>
      {campaignImage && (
        <StyledStreamImage borderRadius={borderRadius} src={campaignImage} alt='Stream' />
      )}
      {!campaignImage && !overrideDefault && (
        <>
          <AvatarWrapper>
            <StyledMutedAvatar
              borderRadius={borderRadius}
              height={80}
              width={80}
              src={defaultAvatar}
              alt='User Avatar Background'
              shape='square'
            />
          </AvatarWrapper>
        </>
      )}
    </StyledStream>
  )
}
