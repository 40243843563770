import styled from 'styled-components/macro'
import { Button } from '@tiltify/ui'
import { rem } from 'polished'

export const MaxWidthWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
`

export const StyledButton = styled(Button)`
  padding: 1rem 2rem;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;

  span {
    font-weight: bold;
  }
`

export const StyledHeading = styled.h2`
  color: ${({ theme }) => theme.headingBlue};
  font-size: ${rem(40)};
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
  margin-bottom: ${rem(5)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(34)};
    line-height: 24px;
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const LightBlueButton = styled(StyledButton)`
  background: ${({ theme }) => theme.lightBlue};
  color: #fff;
  margin-right: ${rem(36)};
  text-decoration: none;
  transition: background-color 0.5s ease;
  @media (max-width: 552px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  && :hover {
    background: #008dba;
  }
`

export const RedButton = styled(StyledButton)`
  background: ${({ theme }) => theme.red};
  border-color: ${({ theme }) => theme.red};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  && :hover {
    background: #c83021;
  }
`

export const AccentBar = styled.div`
  background: ${({ theme }) => theme.lightGrey};
  height: 6px;
  width: 5%;
  margin: 0 auto;
  margin-bottom: ${rem(34)};

  @media (max-width: 552px) {
    width: 10%;
  }
`

export const FullLeaderboardButton = styled(Button)`
  color: ${({ theme }) => theme.headingBlue};
  font-size: ${rem(20)};
  line-height: 28px;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Freight Sans pro;
  transition: color 0.3s ease-in-out;

  && :hover {
    color: ${({ theme }) => theme.lightBlue};
  }
  svg {
    height: 14px;
    margin-left: 2px;
    margin-top: 2px;
  }
`
