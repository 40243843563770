import { useEffect, useState } from 'react'
import { getContrast, readableColor } from 'polished'
import { useTheme } from 'styled-components'

const calculateContrast = (backgroundColor: any, foregroundColor: any) => {
  if (backgroundColor && foregroundColor) return getContrast(backgroundColor, foregroundColor)
}

export const useReadableColor = (
  backgroundColor: any,
  foregroundColor: any,
  contrastRatio = 4.5
) => {
  const [ratio, setRatio] = useState(calculateContrast(backgroundColor, foregroundColor) || 0)
  const theme = useTheme()

  useEffect(() => {
    if (backgroundColor && foregroundColor)
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      setRatio(calculateContrast(backgroundColor, foregroundColor))
  }, [backgroundColor, foregroundColor])

  if (ratio >= contrastRatio) return foregroundColor

  if (backgroundColor) {
    return readableColor(backgroundColor, theme.lightTextColor, theme.darkTextColor)
  }

  return theme.darkTextColor
}

export const tiltifyReadableColor = (
  backgroundColor: any,
  foregroundColor: any,
  theme: any,
  contrastRatio = 4.5
) => {
  const ratio = calculateContrast(backgroundColor, foregroundColor) || 0
  if (ratio >= contrastRatio) return foregroundColor

  if (backgroundColor) {
    return readableColor(backgroundColor, theme.lightTextColor, theme.darkTextColor)
  }

  return theme.darkTextColor
}
