import { useState } from 'react'
import styled from 'styled-components/macro'

import { Grid, CampaignCard, PageLoading, PaginatedControls } from '@tiltify/ui'
import { useOffsetPagination } from '@tiltify/ui/hooks/useOffsetPagination'
import { useDebounce } from '@tiltify/ui/hooks/useDebounce'
import { get_campaigns_by_cause_slug } from 'gql/cause'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledGrid = styled(Grid)`
  width: 100%;
  max-width: ${({ theme }) => theme.contentMaxWidth};
  padding: 0 1rem;
`

const ControlWrapper = styled.div`
  margin-top: 1rem;
`

export const CauseCampaigns = ({
  cause,
  value,
}: {
  cause: TiltifyPublicCause
  value: string | null
}): JSX.Element => {
  const [queryVariables] = useState({
    causeId: cause.id,
    causeSlug: cause.slug,
  })
  const delayedQuery = useDebounce(value || '', 500)

  const { data, loading, canGoNext, canGoPrev, goToFirstPage, goToNext, goToPrev } =
    useOffsetPagination(
      get_campaigns_by_cause_slug,
      delayedQuery,
      'cause.publishedCampaigns',
      12,
      queryVariables
    )

  if (loading) return <PageLoading />
  return (
    <Wrapper>
      <StyledGrid itemWidth='270px'>
        {data?.cause?.publishedCampaigns?.edges?.map(
          (edge: { node: TiltifyCampaign }, index: number) => (
            <CampaignCard key={`campaign-${index}`} campaign={edge.node} />
          )
        )}
      </StyledGrid>
      <ControlWrapper>
        {(canGoNext || canGoPrev) && (
          <PaginatedControls
            firstPage={goToFirstPage}
            prevPage={goToPrev}
            nextPage={goToNext}
            canGoPrev={canGoNext}
            canGoNext={canGoPrev}
          />
        )}
      </ControlWrapper>
    </Wrapper>
  )
}
