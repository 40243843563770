import { rem } from 'polished'
import styled from 'styled-components'

import { Board } from './Board'

const Container = styled.div`
  display: flex;
  flex-direction row;
  justify-content: space-between;
  margin-bottom: ${rem(136)};
  max-width: ${({ theme }) => theme.contentMaxWidth};
  margin: 0 auto;

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }

  @media (max-width: 641px) {
    margin-bottom: 0;
  }
`

export const LeaderboardWrapper = ({
  currency,
  leaderboards,
  hideTeams,
  showDonors,
  rowStyle,
  boardStyle,
  headingColor,
  subHeadingStyles,
  textColor,
  maxAmountOfDonators,
  localLinks,
  nameTextColor,
  topThreeStyle,
  fontFamily,
}: any) => {
  const numberOfBoards = Object.values(leaderboards)
    .filter((board) => board !== undefined)
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    .filter((board) => board.length > 0).length

  const individualCampaigns =
    leaderboards && leaderboards.individual && leaderboards.individual.length > 0
  const teamCampaigns =
    leaderboards && !hideTeams && leaderboards.team && leaderboards.team.length > 0
  const donorCampaigns =
    leaderboards && showDonors && leaderboards.donors && leaderboards.donors.length > 0
  const weeklyCampaigns = leaderboards && leaderboards.weekly && leaderboards.weekly.length > 0

  if (!individualCampaigns && !teamCampaigns && !donorCampaigns && !weeklyCampaigns) {
    return null
  }

  return (
    <Container>
      {individualCampaigns && (
        <Board
          header='Individual'
          currency={currency}
          campaigns={leaderboards.individual}
          rowStyle={rowStyle}
          boardStyle={boardStyle}
          numberOfBoards={numberOfBoards}
          headingColor={headingColor}
          subHeadingStyles={subHeadingStyles}
          textColor={textColor}
          nameTextColor={nameTextColor}
          maxAmountOfDonators={maxAmountOfDonators}
          localLinks={localLinks}
          topThreeStyle={topThreeStyle}
          fontFamily={fontFamily}
        />
      )}
      {teamCampaigns && (
        <Board
          header='Team'
          currency={currency}
          campaigns={leaderboards.team}
          rowStyle={rowStyle}
          boardStyle={boardStyle}
          numberOfBoards={numberOfBoards}
          headingColor={headingColor}
          subHeadingStyles={subHeadingStyles}
          textColor={textColor}
          nameTextColor={nameTextColor}
          maxAmountOfDonators={maxAmountOfDonators}
          localLinks={localLinks}
          topThreeStyle={topThreeStyle}
          fontFamily={fontFamily}
        />
      )}
      {weeklyCampaigns && (
        <Board
          header='Weekly'
          currency={currency}
          campaigns={leaderboards.weekly}
          rowStyle={rowStyle}
          boardStyle={boardStyle}
          numberOfBoards={numberOfBoards}
          headingColor={headingColor}
          subHeadingStyles={subHeadingStyles}
          textColor={textColor}
          nameTextColor={nameTextColor}
          maxAmountOfDonators={maxAmountOfDonators}
          localLinks={localLinks}
          topThreeStyle={topThreeStyle}
          fontFamily={fontFamily}
        />
      )}
      {donorCampaigns && (
        <Board
          header='Donors'
          currency={currency}
          campaigns={leaderboards.donors}
          rowStyle={rowStyle}
          boardStyle={boardStyle}
          numberOfBoards={numberOfBoards}
          headingColor={headingColor}
          textColor={textColor}
          nameTextColor={nameTextColor}
          maxAmountOfDonators={maxAmountOfDonators}
          localLinks={localLinks}
          topThreeStyle={topThreeStyle}
          fontFamily={fontFamily}
        />
      )}
    </Container>
  )
}

LeaderboardWrapper.defaultProps = {
  localLinks: false,
}
