export const RightArrow = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className}
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.85 0L8.48 2.36L13.63 7.51H0V10.83H13.63L8.48 15.98L10.85 18.34L20 9.17L10.85 0Z'
        fill='#009ED0'
      />
    </svg>
  )
}
