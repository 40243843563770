import styled from 'styled-components'

const StyledAvatarWrapper = styled.div<{ shape: string; height: number; width: number }>`
  ${({ shape }) => {
    if (shape === 'square') {
      return 'border-radius: 8px;'
    }
    return 'border-radius: 50%;'
  }}

  border: 0px solid transparent;
  height: ${({ height }) => height}px;
  overflow: hidden;
  position: relative;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  margin: auto;
`

const StyledAvatar = styled.img`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`

export const Avatar = ({ src, alt, height, width, shape, ...rest }: any) => {
  return (
    <StyledAvatarWrapper height={height} width={width} shape={shape} {...rest}>
      <StyledAvatar src={src} alt={alt} height={height} width={width} />
    </StyledAvatarWrapper>
  )
}

Avatar.defaultProps = {
  height: 50,
  width: 50,
  shape: 'circle',
}
