import { readableColor, rem } from 'polished'
import styled from 'styled-components'

import { Avatar } from '../../../Avatar'
import { Currency } from '../../../Currency'
import { TiltifyLink } from '../../../TiltifyLink'

const TopThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 250px;
  margin-bottom: ${rem(40)};
  width: 100%;
`

const TopThreeHeader = styled.h3<{ headingColor: string }>`
  font-size: ${rem(16)};
  font-weight: bold;
  color: ${({ theme, headingColor }) => headingColor || readableColor(theme.backgroundColor)};
  margin-bottom: ${rem(2)};
  margin-top: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SecondThirdPlaceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const UserAvatar = styled(Avatar)`
  margin-bottom: ${rem(4)};
`

const PlaceIcon = styled.div<{ borderColor: string }>`
  position: relative;
  top: 15px;
  left: -30px;
  background: #ffffff;
  border: ${({ borderColor }) => `3px solid ${borderColor}`};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem(16)};
  font-weight: bold;
  color: #535b62;
  z-index: 99;
`

const Place = styled(({ ...rest }) => <TiltifyLink {...rest} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme, textColor }) => textColor || readableColor(theme.backgroundColor)};
`

const OtherPlaces = styled(Place)`
  width: 50%;
`

export const Triangle = ({ c, textColor, localLinks, headingColor, currency }: any) => {
  return (
    <TopThreeWrapper>
      {c[0] && (
        <Place textColor={textColor} url={c[0].url} localLink={localLinks}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <PlaceIcon textColor={textColor} borderColor='#FFDA00'>
            1
          </PlaceIcon>
          <UserAvatar
            height={55}
            width={55}
            shape='square'
            src={c[0].avatar?.src}
            alt='First place avatar'
          />
          <TopThreeHeader headingColor={headingColor}>{c[0].name}</TopThreeHeader>
          <Currency currency={currency || 'USD'} value={c[0].amountRaised} />
        </Place>
      )}
      <SecondThirdPlaceWrapper>
        {c[1] && (
          <OtherPlaces textColor={textColor} url={c[1].url} localLink={localLinks}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <PlaceIcon textColor={textColor} borderColor='#9F9F9F'>
              2
            </PlaceIcon>
            <UserAvatar
              height={55}
              width={55}
              shape='square'
              src={c[1].avatar?.src}
              alt='Second place avatar'
            />
            <TopThreeHeader headingColor={headingColor}>{c[1].name}</TopThreeHeader>
            <Currency currency={currency || 'USD'} value={c[1].amountRaised} />
          </OtherPlaces>
        )}
        {c[2] && (
          <OtherPlaces textColor={textColor} url={c[2].url} localLink={localLinks}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <PlaceIcon textColor={textColor} borderColor='#A18D1C'>
              3
            </PlaceIcon>
            <UserAvatar
              height={55}
              width={55}
              shape='square'
              src={c[2].avatar?.src}
              alt='Third place avatar'
            />
            <TopThreeHeader headingColor={headingColor}>{c[2].name}</TopThreeHeader>
            <Currency currency={currency || 'USD'} value={c[2].amountRaised} />
          </OtherPlaces>
        )}
      </SecondThirdPlaceWrapper>
    </TopThreeWrapper>
  )
}
