/* eslint-disable */

import { createContext, useContext, useState, useEffect } from 'react'

import '@formatjs/intl-relativetimeformat/polyfill'
import { IntlProvider } from 'react-intl'

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  // require('@formatjs/intl-relativetimeformat/dist/include-aliases') // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  require('@formatjs/intl-relativetimeformat/locale-data/en') // Add locale data for de
}

const LOCAL_STORAGE_KEY = '@@app/intlWrapper/locale'
const IntlContext = createContext<any>({})

let localStorageLocale: string | null = 'en'

try {
  if (window.localStorage) localStorageLocale = window.localStorage.getItem(LOCAL_STORAGE_KEY)
} catch (e) {
  /* ignore */
}

const fetchData = new Promise(async (resolve, reject) => {
  if (process.env.NODE_ENV !== 'test') {
    try {
      const pendingProm = fetch('https://locale.tiltify.com/translations.json')
      const response = await pendingProm
      const data = await response.json()
      resolve(data)
    } catch (e) {
      reject(e)
    }
  }
})

const IntlContextProvider = ({
  children,
  intlOverride,
  useDefaults,
}: {
  children: React.ReactNode | React.ReactNode[]
  intlOverride?: string
  useDefaults?: boolean
}) => {
  const [allMessages, setAllMessages] = useState<Record<string, Record<string, unknown>>>({})
  const [messages, setMessages] = useState({})
  const [defaultLocale, setDefaultLocale] = useState(intlOverride || localStorageLocale || 'en')

  // load messages
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      if (process.env.REACT_APP_SKIP_EXTERNAL_TRANSLATIONS || useDefaults) {
        return
      }
      // @ts-expect-error response
      fetchData.then((res) => setAllMessages(res)).catch(() => {})
    }
  }, [])

  useEffect(() => {
    if (intlOverride) {
      setDefaultLocale(intlOverride)
    }
  }, [intlOverride])

  // select user locale
  useEffect(() => {
    if (allMessages) {
      const navigatorLanguage = (
        (navigator.languages && navigator.languages[0]) ||
        navigator.language
      ).split('-')[0]

      if (intlOverride) {
        setDefaultLocale(intlOverride)
      } else if (localStorageLocale && allMessages[localStorageLocale]) {
        setDefaultLocale(localStorageLocale)
      } else if (navigatorLanguage && allMessages[navigatorLanguage]) {
        setDefaultLocale(navigatorLanguage)
      }
    }
  }, [allMessages])

  // update messages when locale is set or allmessages loads
  useEffect(() => {
    if (allMessages) {
      setMessages(allMessages[defaultLocale])
    }
  }, [defaultLocale, allMessages])

  const changeLocale = (value: string) => {
    if (window.localStorage) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, value)
    }
    if (value && allMessages[value]) {
      setDefaultLocale(value)
    }
  }

  return (
    <IntlContext.Provider value={{ locale: defaultLocale, setLocale: changeLocale }}>
      <IntlProvider locale={defaultLocale} messages={messages} key={defaultLocale}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

IntlContextProvider.defaultProps = {
  useDefaults: false,
}

const useIntlContext = () => {
  const context = useContext(IntlContext)
  if (context === undefined) {
    throw new Error('useIntlContext must be used within a IntlContextProvider')
  }
  return context
}

export { IntlContextProvider, useIntlContext }
