import { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'

import { Routes } from './Routes'
import { GlobalStyle } from './GlobalStyles'
import { AuthenticationProvider } from '@tiltify/ui/contexts/AuthenticationContext'
import { IntlContextProvider } from '@tiltify/ui/contexts/IntlContext'
import { ToggleThemeProvider } from '@tiltify/ui/contexts/ThemeContext'
import theme from './theme'
import { FullWindowLoading } from '@tiltify/ui'
import { RouteContextProvider } from './contexts/RouteContext'
import { useGA } from '@tiltify/ui/hooks/useGA'
import { setupPublicClient } from '@tiltify/ui/utils'

const container = document.getElementById('root')
const root = createRoot(container!)

const Index = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  const { initGA } = useGA()

  useEffect(() => {
    initGA(process.env.REACT_APP_TILTIFY_GA)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setupPublicClient()
      .then((res: ApolloClient<NormalizedCacheObject>) => setClient(res))
      .catch(() => {})
  }, [])

  if (!client) return <FullWindowLoading />

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <RouteContextProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </RouteContextProvider>
      </AuthenticationProvider>
    </ApolloProvider>
  )
}

root.render(
  <>
    <ToggleThemeProvider>
      {/* @ts-expect-error ignore */}
      <ThemeProvider theme={theme}>
        <IntlContextProvider>
          <Index />
        </IntlContextProvider>
      </ThemeProvider>
    </ToggleThemeProvider>
    <GlobalStyle />
  </>
)
