import styled from 'styled-components'
import { readableColor } from 'polished'

const Svg = styled.svg`
  display: block;
  width: 80px;
  height: 80px;

  circle {
    stroke: ${({ theme }) => readableColor(theme.backgroundColor || '#fff')};
  }
  .bg {
    opacity: 0.05;
  }
`

export const Loading = ({ ...props }) => (
  <Svg {...props} viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' focusable='false'>
    <path stroke='none' fill='none' d='M0 0h100v100H0z' />
    <circle
      cx='50'
      cy='50'
      r='40'
      fill='none'
      strokeWidth='10'
      strokeLinecap='round'
      className='bg'
    />
    <circle cx='50' cy='50' r='40' fill='none' strokeWidth='6' strokeLinecap='round'>
      <animate
        attributeName='stroke-dashoffset'
        dur='3s'
        repeatCount='indefinite'
        from='0'
        to='502'
      />
      <animate
        attributeName='stroke-dasharray'
        dur='3s'
        repeatCount='indefinite'
        values='150.6 100.4;1 250;150.6 100.4'
      />
    </circle>
  </Svg>
)
