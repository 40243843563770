import styled from 'styled-components'
import { rem } from 'polished'

const Wrapper = styled.div<{ backgroundColor: string; poweredByText: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: ${rem(18)};
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0 2.5rem 0;
  flex-direction: column;
  svg {
    margin-bottom: 1.5rem;
  }

  path {
    fill: ${({ poweredByText }) => poweredByText};
  }
`
export const CustomPoweredBy = ({ poweredByText, backgroundColor, color, icon, ...rest }: any) => {
  return (
    <Wrapper
      poweredByText={poweredByText}
      backgroundColor={backgroundColor}
      color={color}
      {...rest}
    >
      {icon}
      Powered by Tiltify
    </Wrapper>
  )
}

CustomPoweredBy.defaultProps = {
  poweredByText: '#FCF3FF',
  backgroundColor: '#15191d',
  color: '#fff',
}
