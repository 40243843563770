import { Link } from 'react-router-dom'

import { baseTiltifyUrl, subdomainExists } from '../../utils/tiltifyUrl'

export const TiltifyLink = ({ children, url, localLink, ...rest }: any) => {
  const exists = subdomainExists()

  if (!url) {
    return <span {...rest}>{children}</span>
  }

  if (localLink && !exists) {
    return (
      <Link {...rest} to={url}>
        {children}
      </Link>
    )
  }

  return (
    <a {...rest} href={`${baseTiltifyUrl()}${url}`}>
      {children}
    </a>
  )
}

TiltifyLink.defaultProps = {
  localLink: false,
}
