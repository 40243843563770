import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { RightArrow } from 'assets/RightArrow'
import { StyledHeading, AccentBar, MaxWidthWrapper, FullLeaderboardButton } from '../styles'
import { Grid, CampaignCard } from '@tiltify/ui'
import { useRouteContext } from 'contexts/RouteContext'

const StyledGrid = styled(Grid)`
  min-height: 200px;
  padding-bottom: 1rem;

  @media (max-width: calc(${({ theme }) => theme.contentMaxWidth} - 32px)) {
    grid-template-columns: repeat(2, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: repeat(auto-fit, minmax(${({ itemWidth }) => itemWidth}, 1fr));
  }
`

export const TrendingCampaigns = (): JSX.Element | null => {
  const [campaigns, setCampaigns] = useState<{ data: TiltifyCampaign[] }>({ data: [] })
  const { cause } = useRouteContext()

  const setData = (edges: { node: TiltifyCampaign }[]) => {
    setCampaigns({
      data: edges.slice(0, 4).map((edge: { node: TiltifyCampaign }) => edge.node),
    })
  }

  useEffect(() => {
    if (cause?.publishedCampaigns?.edges) {
      const { edges } = cause.publishedCampaigns
      setData(edges)
    }
    // eslint-disable-next-line
  }, [cause])

  if (campaigns.data.length === 0) return null

  return (
    <MaxWidthWrapper>
      <StyledHeading>Current fundraisers</StyledHeading>
      <AccentBar />
      <div>
        <StyledGrid itemWidth='260px'>
          {campaigns.data.map((campaign: TiltifyCampaign) => (
            <CampaignCard
              key={`campaign-${campaign.publicId}`}
              campaign={campaign}
              hideCause
              style={{ border: 'solid 1px #BEC3C6' }}
            />
          ))}
        </StyledGrid>
      </div>

      <FullLeaderboardButton as={Link} to='/campaigns'>
        View More Fundraisers <RightArrow />
      </FullLeaderboardButton>
    </MaxWidthWrapper>
  )
}
