/**
 *
 * @param {string} url
 * example urls
 * https://tiltify.com/pages/terms-of-service
 * https://stjude.tiltify.com
 *
 */
export const baseTiltifyUrl = () => {
  return process.env.REACT_APP_TILTIFY_URL || 'http://tiltifydev.com:5001'
}

export const getSubdomainUrl = (url: any, subdomain: any) => {
  if (!url) return
  const newUrl = url.split('//')
  newUrl[1] = `${subdomain}.${newUrl[1]}`

  return newUrl.join('//')
}

export const tiltifyUrl = (url: any) => {
  let origin = window.location.origin
  const regex = RegExp('^https://([a-z.]+)?tiltify.com([a-zA-Z0-9@+/-]*)', 'g')
  const afterRegex = regex.exec(url)
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  if (afterRegex[1]) {
    // subdomains
    const http = origin?.split('/')[0].concat('//')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    origin = origin?.replace(`${http}`, `${http}${afterRegex[1]}`)
  }
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  origin = origin?.concat(afterRegex[2]) // pathname
  return origin
}

export const subdomainExists = () => {
  const domain = window.location.host.toLowerCase().split('.')[1]
    ? window.location.host.split('.')[0]
    : null
  return domain && (domain === 'savethechildren' || domain === 'stjude' || domain === 't4c')
}

export const getSubdomainSlug = () => {
  const domain = window.location.host.toLowerCase().split('.')[1]
    ? window.location.host.split('.')[0]
    : null

  let subdomainSlug = null

  if (domain) {
    if (domain === 'savethechildren') subdomainSlug = 'save-the-children'
    if (domain === 'stjude') subdomainSlug = 'st-jude-children-s-research-hospital'
    if (domain === 't4c') subdomainSlug = 't4c'
  }

  return subdomainSlug
}

export const getCampaignUrl = (campaign: any, local = false, ignoreStatus = false) => {
  if (!campaign) return null

  let vanity = ''
  let slug = ''
  let preview = ''

  if (campaign?.team && campaign?.team?.slug) {
    vanity = `+${campaign.team.slug}`
    slug = campaign.currentSlug
  } else if (campaign?.user && campaign?.user?.slug) {
    vanity = `@${campaign.user.slug}`
    slug = campaign.slug
  }

  if (!vanity) return null

  if (!ignoreStatus && (campaign?.status === 'active' || campaign?.status === 'unpublished')) {
    preview = '/preview'
  }

  if (local) return `/${vanity}/${slug}${preview}`
  return `${baseTiltifyUrl()}/${vanity}/${slug}${preview}`
}
