import styled from 'styled-components'
import { readableColor, rem } from 'polished'

import { LeaderboardWrapper } from './LeaderboardWrapper'

const Wrapper = styled.div<{ leaderboardBackground: string; childrenOnTop: boolean }>`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${({ leaderboardBackground }) =>
    leaderboardBackground && leaderboardBackground};
  ${({ childrenOnTop }) => (childrenOnTop ? `padding-bottom: ${rem(84)};` : '')}
`

const HeadingWrapper = styled.div<{ childrenOnTop: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  ${({ childrenOnTop, theme }) =>
    childrenOnTop &&
    `
      max-width: ${theme.contentMaxWidth};
      margin: auto;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: ${theme.breakpoints[1]}) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }`};
`

const StyledHeading = styled.h2<{
  childrenOnTop: boolean
  headingColor: string
  headingStyles: any
}>`
  ${({ childrenOnTop }) => (!childrenOnTop ? `margin-bottom: ${rem(9)};` : '')}
  font-size: ${rem(28)};
  font-weight: bold;
  text-align: center;
  color: ${({ theme, headingColor }) => headingColor || readableColor(theme.backgroundColor)};

  ${({ headingStyles }) => headingStyles}
`

const AccentBar = styled.div<{ accentColor: string; accentStyles: any }>`
  background-color: ${({ accentColor }) => accentColor};
  height: 4px;
  width: 100%;
  max-width: 100px;
  margin-bottom: ${rem(36)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-bottom: ${rem(20)};
    width: 30%;
  }

  ${({ accentStyles }) => accentStyles}
`

export const NewLeaderboard = ({
  leaderboards,
  sectionHeading,
  currency,
  hideTeams,
  showDonors,
  rowStyle,
  boardStyle,
  headingStyles,
  accentColor,
  accentStyles,
  headingColor,
  subHeadingStyles,
  textColor,
  maxAmountOfDonators,
  children,
  leaderboardBackground,
  localLinks,
  hideAccent,
  nameTextColor,
  topThreeStyle,
  childrenOnTop,
  fontFamily,
}: any) => {
  const individualCampaigns =
    leaderboards && leaderboards.individual && leaderboards.individual.length > 0
  const teamCampaigns =
    leaderboards && !hideTeams && leaderboards.team && leaderboards.team.length > 0
  const donorCampaigns =
    leaderboards && showDonors && leaderboards.donors && leaderboards.donors.length > 0
  const weeklyCampaigns = leaderboards && leaderboards.weekly && leaderboards.weekly.length > 0

  if (
    !leaderboards ||
    (!individualCampaigns && !teamCampaigns && !donorCampaigns && !weeklyCampaigns)
  ) {
    return null
  }

  return (
    <Wrapper leaderboardBackground={leaderboardBackground} childrenOnTop={childrenOnTop}>
      <HeadingWrapper childrenOnTop={childrenOnTop}>
        <StyledHeading
          headingColor={headingColor}
          headingStyles={headingStyles}
          childrenOnTop={childrenOnTop}
        >
          {sectionHeading}
        </StyledHeading>
        {childrenOnTop && children}
        {!hideAccent && <AccentBar accentColor={accentColor} accentStyles={accentStyles} />}
      </HeadingWrapper>
      <LeaderboardWrapper
        currency={currency}
        leaderboards={leaderboards}
        hideTeams={hideTeams}
        showDonors={showDonors}
        rowStyle={rowStyle}
        boardStyle={boardStyle}
        headingColor={headingColor}
        textColor={textColor}
        maxAmountOfDonators={maxAmountOfDonators}
        localLinks={localLinks}
        subHeadingStyles={subHeadingStyles}
        nameTextColor={nameTextColor}
        topThreeStyle={topThreeStyle}
        fontFamily={fontFamily}
      />
      {!childrenOnTop && children}
    </Wrapper>
  )
}

NewLeaderboard.defaultProps = {
  sectionHeading: 'Leaderboards',
  accentColor: '#ED1B2E',
  maxAmountOfDonators: 12,
  localLinks: false,
  hideAccent: false,
  childrenOnTop: false,
}
