import styled from 'styled-components'
import { PageLoading } from './PageLoading'

const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.backgroundColor};
`

export const FullWindowLoading = ({ ...props }) => {
  return (
    <LoadingWrapper {...props}>
      <PageLoading />
    </LoadingWrapper>
  )
}
