import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { Loading } from './Loading'

const StyledPageLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledLoading = styled(Loading)`
  margin-bottom: 0.875rem;
`

const StyledSpan = styled.span`
  color: ${({ theme }) => readableColor(theme.backgroundColor)};
`

export const PageLoading = ({ label, ...props }: any) => (
  <StyledPageLoading>
    <StyledLoading {...props} />
    <StyledSpan>
      {label || (
        <FormattedMessage
          id='PageLoading.Loading'
          defaultMessage='Loading'
          description='The label displayed when a page is loading.'
        >
          {/* @ts-expect-error TS(2322): Type '(formattedText: ReactNode[]) => ReactNode[]'... Remove this comment to see the full error message */}
          {(formattedText) => formattedText}
        </FormattedMessage>
      )}
    </StyledSpan>
  </StyledPageLoading>
)
