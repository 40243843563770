import { gql } from '@apollo/client'

export const get_cause_leaderboards = gql`
  query get_cause_leaderboards($slug: String!) {
    cause(slug: $slug) {
      userLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      teamLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

export const get_fe_leaderboards = gql`
  query get_fe_leaderboards($publicId: String!) {
    fundraisingEvent(publicId: $publicId) {
      donorLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      userLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      teamLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      userFitnessDistanceLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                unit
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      userFitnessTimeLeaderboard(range: "default") {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                unit
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
      weeklyLeaderboard {
        entries(first: 250) {
          edges {
            node {
              id
              name
              heat
              url
              amount {
                value
                currency
              }
              avatar {
                src
                alt
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
