import styled from 'styled-components/macro'
import { rem } from 'polished'

import ToolKitImage from 'assets/ToolKitImage.jpg'
import { MaxWidthWrapper, RedButton } from 'components/styles'

const Wrapper = styled(MaxWidthWrapper)`
  margin-top: ${rem(64)};
  margin-bottom: ${rem(64)};
  max-width: 100%;
  background-color: ${({ theme }) => theme.lightBlue};
  padding: 0;
  display: flex;

  @media (max-width: 1150px) {
    height: auto;
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    height: auto;
    margin-bottom: ${rem(32)};
  }
`

const Image = styled.img`
  height: 100%;
  max-height: 320px;

  @media (max-width: 1150px) {
    width: 100%;
    max-height: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  margin-left: ${rem(160)};
  flex: 1;
  color: ${({ theme }) => theme.whiteText};
  font-family: 'Freight Sans pro';

  @media (max-width: 1300px) {
    margin-left: ${rem(30)};
  }

  @media (max-width: 1150px) {
    margin-bottom: ${rem(64)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    text-align: center;
    margin-left: 0;
  }
`

const StyledHeading = styled.h2`
  font-size: ${rem(30)};
  line-height: 36px;
  margin-top: ${rem(36)};
  margin-bottom: ${rem(8)};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(24)};
  }
`

const StyledDescription = styled.p`
  font-size: ${rem(20)};
  margin-bottom: ${rem(30)};
  line-height: 28px;
  max-width: 75%;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: ${rem(18)};
    line-height: 20px;
    margin: 0 auto;
    margin-bottom: ${rem(15)};
    max-width: 90%;
    text-align: center;
  }
`

const StyledRedButton = styled(RedButton)`
  font-size: ${rem(14)};
  background: ${({ theme }) => theme.headingBlue};
  color: #fff;
  transition: background-color 0.5s ease;
  display: inline-block;

  && :hover {
    background: #162550;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    margin-bottom: ${rem(10)};
  }
`

export const ToolKit = (): JSX.Element => {
  return (
    <Wrapper>
      <ContentWrapper>
        <StyledHeading>Download our fundraiser toolkit</StyledHeading>
        <StyledDescription>
          Whether you’re organizing a community event or hosting a livestream fundraiser, we provide
          all the tools you need to make your campaign a huge success!
        </StyledDescription>
        <StyledRedButton
          as='a'
          href='https://humanesociety.org/streamkit'
          target='_blank'
          rel='noopenner noreferrer'
        >
          Download the Toolkit
        </StyledRedButton>
      </ContentWrapper>
      <Image src={ToolKitImage} alt='' />
    </Wrapper>
  )
}
